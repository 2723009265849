export const calculateSum = (items, selector) => {
    let sum = 0;
    const sanitizedSelector = selector || (o => o);
    items.forEach(item => {
        sum += sanitizedSelector(item) || 0;
    });
    return sum;
};

export const arrayDictionarify = (array, keySelector, valueSelector) => {
    const dictionary = {};
    array.forEach(item => {
        const key =  keySelector(item);
        const value = valueSelector
            ? valueSelector(item)
            : item;
        if(!dictionary[key]) {
            dictionary[key] = [];
        }
        dictionary[key].push(value);
    });
    return dictionary;
};

export const distinct = array => array.filter(distinctFilter);

// https://stackoverflow.com/a/14438954
const distinctFilter = (value, index, self) => self.indexOf(value) === index;
