const defaultColors = {
    darkBlue: '#051C39',
    darkBlueOpacity: '#051c3980',

    icon: '#ef4b3c',

    danger: '#ef4b3c',

    asterisk: '#C02525',
    attentionForeground: '#ffffff',
    attentionBackground: '#e94d3e',
    textColor: '#04172f',
    textColorLight: '#6f6f6f',
    textColorDark: '#303030',
    textColorDisabled: '#bfbfbf',

    darkGrey: '#696969',
    mediumDarkGrey: '#999999',
    mediumGrey: '#e4e4e4',
    mediumLightGrey: '#efefef',
    lightGrey: '#f5f5f5',
    lightestGrey: '#f9f9f9',

    lightRed: '#FDEEEC',
    red: '#E94D3E',
    lightGreen: '#E8F8F7',
    green: '#00B7AF',
    lightYellow: '#fff3b8',
    yellow: '#8c6517',

    white: '#ffffff',
    whiteHover: '#f0f0f0',

    black: '#000000',

    tooltipGrey: '#404040',

    info: '#00B7AF',
    infoBackground: '#E8F8F7',
    error: '#E94D3E',
    errorBackground: '#FDEDED',

    yes: '#009900',
    no: '#e4011c',

    transparent: 'transparent',
    inherit: 'inherit',
};

export const storageColors = {
    ...defaultColors,
    // pacific-green
    primaryColor: '#00b7af',
    primaryHoverColor: '#019e93',
    primaryActiveColor: '#00847f',
    primaryDisabledColor: '#cbefed',

    // atlantic-blue
    secondaryColor: '#051c39',
    secondaryHoverColor: '#030c19',
    secondaryBorderColor: '#1f334d',

    lightBackgroundColor: '#e8f8f7',
    intermediateBackgroundColor: '#cbefed',

    dialogTitleGradentStart: '#0c3c6b',
    dialogTitleGradentEnd: '#051c39',

    lightButtonColor: '#efefef',
    lightButtonHoverColor: '#e4e4e4',

    lightGreyBorder: '#00000026',

    areaLabelTextColor: defaultColors.white,
    areaLabelBackgroundColor: defaultColors.darkBlue,
    priceLabelTextColor: defaultColors.white,
    priceLabelBackgroundColor: '#00b7af',

    footerBackgroundColor: defaultColors.darkBlue,

    icon: '#00b7af'

};

const beige = '#fdf0ed';
const orange = '#eb6022';

export const officeColors = {
    ...storageColors,

    beige,

    textColor: '#2d1206',

    primaryColor: orange,
    primaryHoverColor: '#c64c16',
    primaryActiveColor: '#cd4f17',
    primaryDisabledColor: '#f5e2da',

    secondaryColor: '#622604',
    secondaryHoverColor: '#c64c16',
    secondaryBorderColor: '#1f334d',

    lightBackgroundColor: '#f5ebe6',
    intermediateBackgroundColor: '#f3cfbf',

    dialogTitleGradentStart: '#b56132',
    dialogTitleGradentEnd: '#622604',

    lightButtonColor: beige,
    lightButtonHoverColor: '#c64c16',

    functionButtonColor: beige,
    functionButtonHoverColor: '#f6d9cc',

    darkButtonColor: '#622604',
    darkButtonHoverColor: '#c64c16',

    attentionForeground: '#2d1206',
    attentionBackground: '#ffe600',
    asterisk: '#f05b4e',

    areaLabelTextColor: orange,
    areaLabelBackgroundColor: beige,
    priceLabelTextColor: orange,
    priceLabelBackgroundColor: beige,

    footerBackgroundColor: '#622604',

    danger: '#ef4b3c',
    icon: '#ef4b3c'
};
