import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { useForm, useField } from 'react-final-form';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';
import { formatQuantity } from 'helpers/StringHelper';
import { formatStorageGroupPrice, formatStorageGroupArea } from './FormatHelper';
import { getStorageGroupTitle, getStorageTitlesForStorageGroup } from 'helpers/StorageSiteHelper';
import rentalTypes from 'enums/rentalTypes';
import storageGroupStatuses from 'enums/storageGroupStatuses';
import storageStatuses from 'enums/storageStatuses';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import StorageGroupEditor from './StorageGroupEditor';
import IconEdit from 'common/IconEdit';
import IconDelete from 'common/IconDelete';

const useStyles = makeStyles(({ theme, colors, styles }) => ({
    hidden: {
        display: 'none'
    },
    dialogContent: {
        paddingRight: theme.spacing(2),
        margin: theme.spacing(4, 0)
    },
    storageContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: theme.spacing(1.875),
        border: `1px solid ${colors.lightGreyBorder ?? colors.mediumGrey}`,
        borderRadius: `${styles.cardBorderRadius}px`,
        boxShadow: styles.boxShadow,
        padding: theme.spacing(1.875),
        alignItems: 'center',
        '&:not(:last-child)': {
            marginBottom: theme.spacing(2)
        },
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        }
    },
    storageGroupHeader: {
        gridColumn: '1 / -1',
        display: 'flex',
        gap: theme.spacing(1.875),
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    buttons: {
        minWidth: 'max-content'
    },
    arrow: {
        textAlign: 'right'
    },
    arrowButton: {
        color: colors.icon,
        margin: 0,
        padding: 0
    },
    actionButton: {
        color: colors.icon,
        borderColor: colors.icon
    }
}));

const StorageGroup = ({ storageSiteFormData, storageSiteExists = false, showExclusiveVatSuffix = false, category, fieldNamePrefix, editable, onSave, onRemove }) => {
    const classes = useStyles();
    const form = useForm();

    const { appContext } = useAppContext();

    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;
    const getValue = suffix => useField(getName(suffix)).input.value;

    const [dialogOpen, setDialogOpen] = useState(false);

    // the current storage group to show in the list
    const storageGroupFormValues = { ...getValue() };

    // the storage group being edited in the editor
    const editorValues = { ...getValue('editor') };

    // validation is performed on the editor field
    const editorField = useField(getName('editor'));
    // the .meta.valid property contains whether the editor is valid or not, given that validation rules are set up correctly
    const editorIsValid = editorField && editorField.meta.valid;

    const showRemoveButtonInDialog = !useMediaQuery(theme => theme.breakpoints.up('md')) && !storageGroupFormValues.isDraft;

    const handleDialogOpen = () => {
        // we're opening the editor; copy the current storage group to the editor and operate on the copy
        form.mutators.changeValue({ name: getName('editor'), value: storageGroupFormValues });
        setDialogOpen(true);
    };

    const handleDialogOk = () => {
        // the user clicked OK in the editor; copy the values of the edited storage group back to the current storage group, and save if not in draft mode
        const sanitizedValues = { ...editorValues };
        if (sanitizedValues.storagesAreRectangular) {
            sanitizedValues.storageArea = sanitizedValues.storageWidth * sanitizedValues.storageLength;
        } else {
            sanitizedValues.storageWidth = undefined;
            sanitizedValues.storageLength = undefined;
        }

        form.mutators.changeValue({ name: fieldNamePrefix, value: { ...sanitizedValues, isDraft: false, editor: undefined } });
        if(onSave) {
            onSave();
        }
        setDialogOpen(false);
    };

    const handleDialogRemove = () => {
        onRemove();
        setDialogOpen(false);
    };

    const handleDialogCancel = () => {
        // the user canceled the editor; go back without updating the current storage group
        if (storageGroupFormValues.isDraft) {
            // canceled on create new storage group; remove that storage group
            onRemove();
        }
        setDialogOpen(false);
    };

    const quantity = storageGroupFormValues.enumerateStorages
        ? storageGroupFormValues.storages?.filter(storage => storage.status !== storageStatuses.deleted.key).length
        : storageGroupFormValues.quantity;

    const formattedQuantity = storageGroupFormValues.rentalType === rentalTypes.fixedArea.key
        ? formatQuantity(quantity)
        : '';

    const getFormattedStatus = () => {
        return storageGroupStatuses[storageGroupFormValues.status].title;
    };

    if(storageGroupFormValues.isDraft && !dialogOpen) {
        // if creating a new storage group, show the editor immediately
        setDialogOpen(true);
    }

    const title = storageGroupFormValues.rentalType === rentalTypes.customArea.key
        ? formatStorageGroupArea(storageGroupFormValues, appContext)
        : getStorageGroupTitle(storageGroupFormValues, appContext, { forceShowSize: true });

    const storageTitles = storageGroupFormValues.rentalType === rentalTypes.customArea.key
        ? undefined
        : getStorageTitlesForStorageGroup(storageGroupFormValues, appContext);

    return (
        <>
            <Box className={clsx(classes.storageContainer, {
                [classes.hidden]: storageGroupFormValues.isDraft
            })}>
                <Box className={classes.storageGroupHeader}>
                    <Typography variant="h4">{title}</Typography>
                    {
                        editable &&
                        (
                            <Box className={classes.buttons}>
                                <IconButton
                                    onClick={handleDialogOpen}
                                >
                                    <IconEdit fill={appContext.colors.primaryColor} />
                                </IconButton>
                                <IconButton
                                    onClick={onRemove}
                                >
                                    <IconDelete fill={appContext.colors.primaryColor} />
                                </IconButton>
                            </Box>
                        )
                    }
                </Box>
                <Box>
                    {formattedQuantity}
                </Box>
                <Box>
                    {storageTitles}
                </Box>
                <Box>
                    {getFormattedStatus()}
                </Box>
                <Box>
                    {formatStorageGroupPrice(storageGroupFormValues, showExclusiveVatSuffix, appContext)}
                </Box>
            </Box>
            {
                editable &&
                (
                    <Dialog fullWidth maxWidth="md" open={dialogOpen} onClose={handleDialogCancel} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title" disableTypography>
                            <Typography variant="h5">
                                {category.addDialogTitle}
                            </Typography>
                        </DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <StorageGroupEditor
                                fieldNamePrefix={getName('editor')}
                                storageSiteFormData={storageSiteFormData}
                                category={category}
                                storageSiteExists={storageSiteExists}
                            />
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleDialogCancel}
                            >
                                {strings.cancel}
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!editorIsValid}
                                onClick={handleDialogOk}
                            >
                                {storageSiteExists ? strings.save : strings.add}
                            </Button>
                            {
                                showRemoveButtonInDialog &&
                                (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleDialogRemove}
                                    >
                                        {strings.remove}
                                    </Button>
                                )
                            }
                        </DialogActions>
                    </Dialog>
                )
            }
        </>
    );
};

StorageGroup.propTypes = {
    storageSiteFormData: PropTypes.object.isRequired,
    storageSiteExists: PropTypes.bool,
    category: PropTypes.object.isRequired,
    fieldNamePrefix: PropTypes.string.isRequired,
    showExclusiveVatSuffix: PropTypes.bool,
    editable: PropTypes.bool,
    onSave: PropTypes.func,
    onRemove: PropTypes.func
};

export default StorageGroup;
