import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { formatPurchaseItemDescription } from 'helpers/PurchaseItemHelper';

import Amount from 'common/Amount';
import RoundingCorrection from './RoundingCorrection';

const useStyles = makeStyles(() => ({
    caption: {
        '& td': {
            paddingBottom: '0.5em'
        }
    },
    mainTitle: {
        fontWeight: 'bold',
        fontSize: '16px'
    },
    right: {
        textAlign: 'right'
    }
}));

const ReceiptSpecification = ({ purchase, isOwner }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    return (
        <>
            <tr className={classes.caption}>
                <td className={classes.mainTitle}>{strings.paymentDocuments.specification}</td>
            </tr>
            {
                purchase.purchaseItems
                    .filter(purchaseItem => !isOwner || purchaseItem.commissionRate < 1)
                    .map(purchaseItem =>
                    (
                        <tr key={purchaseItem.id}>
                            <td colSpan={2}>
                                {formatPurchaseItemDescription(purchaseItem, appContext)}
                            </td>
                            <td colSpan={2} className={classes.right}>
                                <Amount value={purchaseItem.amount + purchaseItem.vat} currency={purchaseItem.currency} booking={purchase.booking} displayPriceRounding={false} />
                            </td>
                        </tr>
                    ))
            }
            <RoundingCorrection purchase={purchase} />
        </>
    );
};

ReceiptSpecification.propTypes = {
    purchase: PropTypes.object.isRequired,
    isOwner: PropTypes.bool
};

export default ReceiptSpecification;
