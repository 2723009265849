import React from 'react';
import { useForm } from 'react-final-form';
import strings from 'localization/strings';
import { getDiscountFromFormData } from 'logic/editStorageSiteLogic';
import { toCamelCase } from 'helpers/StringHelper';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ShortDate from 'common/ShortDate';
import Percentage from 'common/Percentage';

const DisplayStep4b = () => {
    const formValues = useForm().getState().values;
    const values = formValues.steps[4];

    const getAdditionalText = discount => {
        const atoms = [];
        if(discount.numberOfMonths) {
            atoms.push(`${toCamelCase(strings.discountNumberOfMonths, { keepSpaces: true })}: ${discount.numberOfMonths}`);
        }
        if(discount.minimumBookingLength) {
            atoms.push(`${toCamelCase(strings.minimumBookingLength, { keepSpaces: true })}: ${discount.minimumBookingLength} ${strings.daysLower}`);
        }
        if(atoms.length === 0) {
            return undefined;
        }

        return ', ' + atoms.join(', ');
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                {
                    values.discounts.length === 0 &&
                    (
                        <Typography variant="body1">{strings.noDiscounts}</Typography>
                    )
                }
                {
                    values.discounts.length > 0 &&
                    (
                        <List>
                            {
                                values.discounts.map(getDiscountFromFormData).map((discount, i) => (
                                    <ListItem key={i} disableGutters>
                                        <ListItemText>
                                            {
                                                (discount.startDate || discount.endDate) &&
                                                (
                                                    <><ShortDate value={discount.startDate}/> &ndash; <ShortDate value={discount.endDate}/>{': '}</>
                                                )
                                            }
                                            <Percentage value={discount.percentage}/>{getAdditionalText(discount)}
                                        </ListItemText>
                                    </ListItem>
                                ))
                            }
                        </List>
                    )
                }
            </Grid>
        </Grid>
    );
};

export default DisplayStep4b;
