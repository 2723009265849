import React from 'react';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { questionAndAnswerItemsOwner } from 'helpers/QuestionAndAnswersHelper';
import strings from 'localization/strings';
import routes from 'routes';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Text from 'common/Text';
import MarginBox from 'common/MarginBox';
import OwnerSalesInfo from './OwnerSalesInfo';
import PageTitle from 'common/PageTitle';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import EarnMoneyWidget from 'components/EarnMoneyWidget';
import ExpandableTitleDescriptionList from 'components/expandableTitleDescriptionList/ExpandableTitleDescriptionList';
import VideoPlayer from 'common/VideoPlayer';
import Container from 'common/Container';
import VisibleFor from 'common/VisibleFor';
import { Helmet } from 'react-helmet-async';

import MoneyBag from 'assets/images/moneyBag.svg';
import searchBackground from 'assets/images/search-form-background.jpg';

const useStyles = makeStyles(({ theme, colors }) => ({
    makeMoneyContainer: {
        display: 'flex',
        backgroundColor: colors.white,
        padding: theme.spacing(12.75, 20),
        flexWrap: 'wrap',
        gap: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: theme.spacing(8, 2),
            gap: theme.spacing(7),
        }
    },
    makeMoney: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'start'
    },
    makeMoneyButton: {
        padding: theme.spacing(1.5, 5)
    },
    makeMoneyVideo: {
        display: 'flex',
        flex: '1',
        '& video': {
            width: '100%',
            height: '100%'
        }
    },
    ownerSalesInfoContainer: {
        display: 'flex',
        gap: theme.spacing(12.5),
        flexDirection: 'column',
        padding: theme.spacing(12.5, 20),
        backgroundColor: colors.white,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(8, 2),
            gap: theme.spacing(3),
        }
    },
    addYourFirstStorageContainer: {
        display: 'flex',
        gap: theme.spacing(5),
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(12.5, 0),
        color: colors.white,
        backgroundColor: colors.secondaryColor,
        textAlign: 'center',
        '& h4': {
            margin: theme.spacing(0, 0, 6, 0),
            color: colors.white
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(8, 2)
        }
    },
    questionsContainer: {
        display: 'flex',
        gap: theme.spacing(6),
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(12.5, 20),
        backgroundColor: colors.white,
        '& h4': {
            marginBottom: theme.spacing(6),
            textAlign: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(8, 2)
        }
    },
    wannaKnowMoreContainer: {
        display: 'flex',
        gap: theme.spacing(4),
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(12.5, 2),
        backgroundColor: colors.secondaryColor,
        '& h4': {
            margin: theme.spacing(0),
            textAlign: 'center',
            color: colors.white,
        }
    }
}),
({ colors }) => ({
    storage: {
        earnMoneyContainer: {
            background: colors.secondaryColor
        }
    },
    office: {
        earnMoneyContainer: {
            background: colors.primaryColor
        }
    }
}));

const RentOutStoragePage = () => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    return (
        <>
            <PageTitle>{strings.headerTitleRentOut}</PageTitle>
            <Helmet defer={false}>
                <meta name="description" content={strings.metaDescriptionRentOut}/>
            </Helmet>
            <Box className={classes.makeMoneyContainer}>
                <Box className={classes.makeMoney}>
                    <MarginBox bottom={4}>
                        <Typography variant="h1" gutterBottom>{strings.earnMoneyTitle}</Typography>
                        <Typography variant="h2">{strings.earnMoneySubtitle}</Typography>
                    </MarginBox>
                    <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={routes.account.createStorageSiteIndex}
                        className={classes.makeMoneyButton}
                    >
                        {strings.rentOutStorage}
                    </Button>
                    <Text html={strings.rentOutStorageInformation}/>
                </Box>
                <VisibleFor branch="storage">
                    <Box className={classes.makeMoneyVideo}>
                        <VideoPlayer src="https://storage365.blob.core.windows.net/website-content/storage/se/s365-slideshow.mp4" autoPlay muted loop />
                    </Box>
                </VisibleFor>
            </Box>
            <Box className={classes.earnMoneyContainer}>
                <Container>
                    <EarnMoneyWidget showDocuments />
                </Container>
            </Box>
            <Box className={classes.ownerSalesInfoContainer}>
                <OwnerSalesInfo title={strings.headerDescriptionRentOut1} text={strings.howItWorksText7} imgSource={MoneyBag} />
                <VisibleFor branch="storage">
                    <OwnerSalesInfo reverse title={strings.headerDescriptionRentOut2} text={strings.howItWorksText8} imgSource={searchBackground} />
                </VisibleFor>
                <OwnerSalesInfo title={strings.headerDescriptionRentOut3} text={strings.howItWorksText9} imgSource={appContext.images.forOwners} />
            </Box>
            <Box className={classes.addYourFirstStorageContainer}>
                <Box>
                    <Typography variant="h4" gutterBottom>{strings.createFirstStorageSite}</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        component={Link}
                        to={routes.account.createStorageSiteIndex}
                    >
                        {strings.createStorageSite}
                    </Button>
                </Box>
            </Box>
            <Box className={classes.questionsContainer}>
                <Container>
                    <Typography variant="h4" gutterBottom>{strings.headerTitleQuestionsAndAnswers}</Typography>
                    <ExpandableTitleDescriptionList items={questionAndAnswerItemsOwner}/>
                </Container>
            </Box>
            <Box className={classes.wannaKnowMoreContainer}>
                <Typography variant="h4" gutterBottom>{strings.callUsForMoreInformation}</Typography>
            </Box>
        </>
    );
};

export default RentOutStoragePage;
