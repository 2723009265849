const geographicalLocationTypes = {
    region: {},
    municipality: {},
    city: {},
    suburb: {}
};

Object.keys(geographicalLocationTypes).forEach(key => {
    geographicalLocationTypes[key].key = key;
});

export default geographicalLocationTypes;
