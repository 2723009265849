import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { useForm } from 'react-final-form';
import { makeStyles } from 'styles/util';
import { getPriceInfo } from 'helpers/PriceCalculator';
import paymentRecurrences from 'enums/paymentRecurrences';
import strings from 'localization/strings';

import Box from '@material-ui/core/Box';
import Amount from 'common/Amount';
import { FieldArray } from 'react-final-form-arrays';
import { TextField } from 'mui-rff';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import QuantitySelector from './QuantitySelector';

const useStyles = makeStyles(({ theme, colors, fonts }) => ({
    additionalService: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2)
    },
    name: {
        flex: '1 0 auto'
    },
    buttonContainer: {
        flex: '0 0 auto'
    },
    selectedContainer: {
        flex: '0 0 auto',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1)
    },
    selectedIcon: {
        color: colors.primaryColor
    },
    bold: {
        fontFamily: fonts.bold
    }
}));

const AdditionalServicesSelector = ({ fieldNamePrefix }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    const form = useForm();

    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;

    const getAdditionalServiceLabel = additionalService => {
        const paymentRecurrence = paymentRecurrences[additionalService.paymentRecurrence];
        const additionalServicePriceInfo = getPriceInfo(additionalService.price, appContext, additionalService);
        return <span><span className={classes.bold}>{additionalService.description}:</span> <Amount value={additionalServicePriceInfo.displayPrice} currency={additionalServicePriceInfo.currency}/> {paymentRecurrence.key === paymentRecurrences.perMonth.key ? paymentRecurrence.title.toLowerCase() : ''}</span>;
    };

    const handleToggle = (fields, index) => {
        form.change(`${getName()}[${index}].selected`, !fields.value[index].selected);
    };

    const handleQuantityChange = (fields, index, newValue, oldValue) => {
        if(newValue > oldValue) {
            if(newValue === 1) {
                form.change(`${getName()}[${index}].selected`, true);
            } else {
                fields.insert(
                    index + newValue - 1,
                    {
                        ...fields.value[index],
                        isPrimaryRow: false,
                        selected: true,
                        quantity: 0,
                        data: undefined
                    }
                );
            }
        } else {
            if(newValue === 0) {
                form.change(`${getName()}[${index}].selected`, false);
            } else {
                fields.remove(index + newValue);
            }
        }
    };

    return (
        <Box>
            <FieldArray name={getName()}>
                {({ fields }) => (
                    <>
                        {
                            fields.map((name, index) => {
                                const additionalService = fields.value[index];
                                return (
                                    <Box key={index}>
                                        {
                                            additionalService.isPrimaryRow &&
                                            (
                                                <Box className={classes.additionalService}>
                                                    <Box className={classes.name}>
                                                        {getAdditionalServiceLabel(additionalService)}
                                                    </Box>
                                                    {
                                                        additionalService.selected && !additionalService.configuration.allowMultiple &&
                                                        (
                                                            <Box className={classes.selectedContainer}>
                                                                <CheckCircleIcon className={classes.selectedIcon} />
                                                                <Box>{strings.additionalServiceSelected}</Box>
                                                            </Box>
                                                        )
                                                    }
                                                    <Box className={classes.buttonContainer}>
                                                        {
                                                            additionalService.configuration.allowMultiple &&
                                                            (
                                                                <QuantitySelector
                                                                    name={`${name}.quantity`}
                                                                    onChange={(newValue, oldValue) => handleQuantityChange(fields, index, newValue, oldValue)}
                                                                />
                                                            )
                                                        }
                                                        {
                                                            !additionalService.configuration.allowMultiple &&
                                                            (
                                                                <IconButton
                                                                    onClick={() => handleToggle(fields, index)}
                                                                >
                                                                    {additionalService.selected ? <DeleteOutlineOutlinedIcon/> : <AddIcon/>}
                                                                </IconButton>
                                                            )
                                                        }
                                                    </Box>
                                                </Box>
                                            )
                                        }
                                        {
                                            additionalService.selected && additionalService.configuration?.showDataTextbox &&
                                            (
                                                <TextField
                                                    name={`${name}.data`}
                                                    variant="outlined"
                                                    {...additionalService.configuration?.dataTextboxProps}
                                                />
                                            )
                                        }
                                    </Box>
                                );
                            })
                        }
                    </>
                )}
            </FieldArray>
        </Box>
    );
};

AdditionalServicesSelector.propTypes = {
    fieldNamePrefix: PropTypes.string.isRequired,
    additionalServices: PropTypes.array.isRequired,
};

export default AdditionalServicesSelector;
