import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import goodsTypes from 'enums/goodsTypes';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ShortDate from 'common/ShortDate';
import ReceiptParties from './ReceiptParties';
import ReceiptSpecification from './ReceiptSpecification';
import VatSpecification from './VatSpecification';
import VisibleFor from 'common/VisibleFor';

const useStyles = makeStyles(({ theme }) => ({
    objectTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
        margin: theme.spacing(2, 0)
    },
    tableSummary: {
        width: '100%',
        margin: theme.spacing(2, 0),
        borderCollapse: 'collapse',
        '& td': {
            verticalAlign: 'top'
        }
    },
    caption: {
        '& td': {
            paddingBottom: '0.5em'
        }
    },
    body: {
        '& td': {
            paddingBottom: '1em'
        }
    },
    itemTitle: {
        fontWeight: 'bold'
    },
    right: {
        textAlign: 'right'
    },

}));

const SubscriptionReceiptBody = ({ purchase, isOwner = false }) => {
    const classes = useStyles();
    const booking = purchase.booking;

    return (
        <>
            <Typography variant="h2" className={classes.objectTitle}>{strings.paymentDocuments.renting} | {booking.storageGroup.storageSite.title}</Typography>

            <VisibleFor branch="storage">
                <Box>
                    <strong>{strings.paymentDocuments.storageSubject}:</strong> {booking.description}
                </Box>
            </VisibleFor>
            {
                booking.goodsType &&
                (
                    <Box>
                        <strong>{strings.vehicleType}:</strong> {goodsTypes[booking.goodsType].title}
                    </Box>
                )
            }
            {
                booking.registrationNumber &&
                (
                    <Box>
                        <strong>{strings.registrationNumber}:</strong> {booking.registrationNumber}
                    </Box>
                )
            }


            <hr />

            <table className={classes.tableSummary}>
                <tbody>
                    <ReceiptParties purchase={purchase} isOwner={isOwner} />
                    <tr className={classes.caption}>
                        <td className={classes.itemTitle}>{strings.paymentDocuments.paymentDate}</td>
                        <td className={classes.itemTitle}>{strings.paymentDocuments.bookingNumber}</td>
                        <td className={classes.itemTitle}>{strings.fromUppercase}</td>
                        <td className={classes.itemTitle + ' ' + classes.right}>{strings.toUppercase}</td>
                    </tr>
                    <tr className={classes.body}>
                        <td>
                            <ShortDate value={purchase.paidTime ?? new Date() /* better safe than sorry */} />
                        </td>
                        <td>{booking.id}</td>
                        <td>
                            <ShortDate value={booking.periodStartDate} />
                        </td>
                        <td className={classes.right}>
                            <ShortDate value={booking.periodEndDate} />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={4}><hr /></td>
                    </tr>

                    <ReceiptSpecification purchase={purchase} isOwner={isOwner} />
                    <VatSpecification purchase={purchase} isOwner={isOwner} />
                </tbody>
            </table>
        </>
    );
};

SubscriptionReceiptBody.propTypes = {
    purchase: PropTypes.object.isRequired,
    isOwner: PropTypes.bool
};

export default SubscriptionReceiptBody;
