import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';
import { required } from 'form/validation';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import ImageCapturer from './ImageCapturer';
import Form from 'form/Form';
import { TextField, showErrorOnBlur } from 'mui-rff';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CachedIcon from '@material-ui/icons/Cached';

const useStyles = makeStyles(({ theme }) => ({
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        alignItems: 'center',
        paddingBottom: 0
    },
    capturedImageContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        alignItems: 'center'
    },
    deleteImageButton: {
        padding: theme.spacing(1),
        minWidth: 'auto'
    },
    capturedImage: {
        maxWidth: '100%'
    }
}));

const EditRoundReportNoteDialog = ({ open, roundReportNote, onOk, onCancel }) => {
    const classes = useStyles();

    const [imageData, setImageData] = useState(undefined);
    const [forceCaptureImage, setForceCaptureImage] = useState(undefined);
    const [initialValues, setInitialValues] = useState(undefined);

    const imageDataOrUrl = imageData ?? (forceCaptureImage ? undefined : roundReportNote.imageUrl);

    useEffect(() => {
        if(open) {
            setInitialValues({
                text: roundReportNote.text
            });
        }
    }, [open]);

    const handleCapture = values => {
        setImageData(values.imageData);
        setForceCaptureImage(false);
    };

    const handleDeleteImage = () => {
        setImageData(undefined);
        setForceCaptureImage(true);
    };

    const handleOk = values => {
        onOk({
            text: values.text,
            imageData
        });
    };

    const handleCancel = () => {
        onCancel();
    };

    return (
        <Dialog fullWidth open={open} onClose={handleCancel}>
            <Form
                initialValues={initialValues}
                onSubmit={handleOk}
            >
                {({ handleSubmit, invalid }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle disableTypography>
                            <Typography variant="h5">
                                {roundReportNote.new ? strings.pages.roundReport.addNote : strings.pages.roundReport.editNote}
                            </Typography>
                        </DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            {
                                !imageDataOrUrl &&
                                (
                                    <ImageCapturer
                                        onCapture={handleCapture}
                                    />
                                )
                            }
                            {
                                imageDataOrUrl &&
                                (
                                    <Box className={classes.capturedImageContainer}>
                                        <img
                                            className={classes.capturedImage}
                                            src={imageDataOrUrl}
                                        />
                                        <Button
                                            className={classes.deleteImageButton}
                                            variant="contained"
                                            color="primary"
                                            onClick={handleDeleteImage}
                                        >
                                            <CachedIcon/>
                                        </Button>
                                    </Box>
                                )
                            }
                            <TextField
                                name="text"
                                label={strings.pages.roundReport.noteText}
                                variant="outlined"
                                showError={showErrorOnBlur}
                                required
                                fieldProps={{ validate: required }}
                                maxLength={1024}
                                multiline
                                rows={3}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={invalid || !imageDataOrUrl}
                            >
                                {strings.ok}
                            </Button>
                            <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                onClick={handleCancel}
                            >
                                {strings.cancel}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Form>
        </Dialog>
    );
};

EditRoundReportNoteDialog.propTypes = {
    open: PropTypes.bool,
    roundReportNote: PropTypes.object.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default EditRoundReportNoteDialog;
