import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from 'styles/util';
import { fetchConversation, updateConversationCompleted } from 'actions/account/conversationsSection';
import { createMessage } from 'actions/messages';
import { fetchPredefinedTexts } from 'actions/predefinedTexts';
import { handleResponse } from 'actions/actionHelpers';
import { convertToNumber } from 'helpers/StringHelper';
import routes from 'routes';
import strings from 'localization/strings';

import PageTitle from 'common/PageTitle';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ConversationMessage from './ConversationMessage';
import MessageEditor from './MessageEditor';
import LoadingWrapper from 'form/LoadingWrapper';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles(({ theme, colors }) => ({
    title: {
        paddingBottom: theme.spacing(2)
    },
    newMessageForm: {
        paddingBottom: theme.spacing(2)
    },
    messagesContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        overflowY: 'auto',
        maxHeight: '50vh',
        gap: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        marginBottom: theme.spacing(4),
        paddingRight: theme.spacing(0.5),
        '&::-webkit-scrollbar': {
            width: '4px',
            color: colors.lightGreyBorder ?? colors.mediumGrey
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent'
        },
        '&::-webkit-scrollbar-thumb': {
            background: colors.lightGreyBorder ?? colors.mediumGrey,
            borderRadius: '4px'
        }
    },
    header: {
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'start',
        justifyContent: 'space-between'
    }
}));

const ConversationDetails = () => {
    const classes = useStyles();
    const params = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const ref = useRef(null);
    const [conversation, setConversation] = useState(undefined);
    const [predefinedTexts, setPredefinedTexts] = useState(undefined);
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const conversationId = convertToNumber(params.conversationId);
    const authenticationContext = useSelector(state => state.authentication.context);
    const { user, selectedActor } = authenticationContext;
    let recipientActor;

    const [conversationCompleted, setConversationCompleted] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        dispatch(fetchConversation(conversationId))
            .then(handleResponse(
                response => {
                    setConversation(response.payload);
                    setConversationCompleted(response.payload.completed);
                    setIsLoading(false);
                },
                () => {
                    setIsLoading(false);
                }
            ));
    }, [conversationId]);

    useEffect(() => {
        dispatch(fetchPredefinedTexts('ownerActorConversations'))
            .then(handleResponse(
                response => {
                    setPredefinedTexts(response.payload);
                }
            ));
    }, []);

    if(conversation) {
        recipientActor = selectedActor.id === conversation.recipientActor.id
            ? conversation.senderActor
            : conversation.recipientActor;
    }

    const handleMessageSubmit = values => {
        setIsSaving(true);
        const createMessageRequest = {
            writerActorId: selectedActor.id,
            writerUserId: user.id,
            conversationId: conversation.id,
            text: values.messageText,
            storageSiteId: conversation.storageSite.id,
            recipientActorId: recipientActor.id,
            writeAsAdmin: values.writeAsAdmin
        };

        dispatch(createMessage(createMessageRequest))
            .then(handleResponse(
                () => {
                    history.push(routes.account.conversations);
                },
                () => setIsSaving(false)
            ));
    };
    useEffect(() => {
        if (ref.current) {
            ref.current.scrollTop = ref.current.scrollHeight;
        }
    }, [ref.current, conversation?.messages.length]);

    const title = recipientActor?.name ?? '';

    const handleConversationCompletedChange = event => {
        const value = event.target.checked;
        setConversationCompleted(value);
        dispatch(updateConversationCompleted(conversation.id, value));
    };

    return (
        <>
            <PageTitle>{title}</PageTitle>
            <Box className={classes.header}>
                <Box>
                    <Typography variant="h2">{title}</Typography>
                    { conversation && <Typography>{conversation.storageSite.title}</Typography> }
                </Box>
                {
                    conversation && authenticationContext.isSuperAdmin &&
                    (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="conversationCompleted"
                                    checked={conversationCompleted}
                                    onChange={handleConversationCompletedChange}
                                />
                            }
                            label={strings.conversationCompleted}
                        />
                    )
                }
            </Box>
            <LoadingWrapper
                isLoading={isLoading}
                isSaving={isSaving}
            >
                {
                    conversation && (
                        <>
                            <Box
                                className={classes.messagesContainer}
                                ref={ref}
                            >
                                {[...conversation.messages].reverse().map(message => (
                                    <ConversationMessage key={message.id} message={message} actor={selectedActor} />
                                ))}
                            </Box>
                            <Box className={classes.newMessageForm}>
                                <MessageEditor
                                    actor={selectedActor}
                                    predefinedTexts={predefinedTexts}
                                    onSubmit={handleMessageSubmit}
                                />
                            </Box>
                        </>
                    )
                }
            </LoadingWrapper>
        </>
    );
};

export default ConversationDetails;
