import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

const PredefinedTextSelector = ({ predefinedTexts, label, onSelect }) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelect = predefinedText => {
        setOpen(false);
        onSelect(predefinedText);
    };

    if(predefinedTexts.length === 0) {
        return null;
    }

    return (
        <Box>
            <ButtonGroup
                variant="outlined"
                color="secondary"
                ref={anchorRef}
            >
                <Button
                    onClick={handleToggle}
                    size="small"
                >
                    {label}
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                transition
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener
                                onClickAway={handleClose}
                            >
                                <MenuList>
                                    {
                                        predefinedTexts.map(predefinedText =>
                                            (
                                                <MenuItem
                                                    key={predefinedText.key}
                                                    onClick={() => handleSelect(predefinedText)}
                                                >
                                                    {predefinedText.label}
                                                </MenuItem>
                                            )
                                        )
                                    }
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
};

PredefinedTextSelector.propTypes = {
    predefinedTexts: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired
};

export default PredefinedTextSelector;

export const insertPredefinedText = ({ containerRef, fieldName, form, predefinedText}) => {
    const domElement = containerRef.current.querySelector(`[name='${fieldName}']`); // ugly
    let text = form.getState().values[fieldName];
    const selectionStart = Math.min(domElement.selectionStart, domElement.selectionEnd);
    const selectionEnd = Math.min(domElement.selectionStart, domElement.selectionEnd);
    text = text.substring(0, selectionStart) + predefinedText.text + text.substring(selectionEnd);
    form.change(fieldName, text);
};
