import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { handleResponse } from 'actions/actionHelpers';
import { getActor, updateActor } from 'actions/admin/actors';
import { getCompanies } from 'actions/admin/companies';
import { convertToNumber } from 'helpers/StringHelper';
import { logIn } from 'actions/authentication';
import routes from 'routes';
import { getBusinessOrPrivateName } from 'helpers/ActorHelper';
import contactTypes from 'enums/contactTypes';

import Button from '@material-ui/core/Button';
import EditActorForm from './EditActorForm';
import Loader from 'common/Loader';
import AdminPage from '../../presentational/AdminPage';
import AdminContentWrapper from '../../presentational/AdminContentWrapper';
import AdminActionWrapper from '../../presentational/AdminActionWrapper';

const handledContactTypes = [contactTypes.mainEmail.key, contactTypes.checkInEmail.key, contactTypes.mainMobileTelephone.key, contactTypes.checkInMobileTelephone.key];

const Actor = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const [actor, setActor] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [companies, setCompanies] = useState(undefined);

    useEffect(() => {
        dispatch(getActor(params.actorId))
            .then(handleResponse(
                response => {
                    setActor(response.payload);
                    dispatch(getCompanies())
                        .then(handleResponse(
                            response2 => {
                                setCompanies(response2.payload);
                                setIsLoading(false);
                            },
                            () => setIsLoading(false)
                        ));
                },
                () => setIsLoading(false)
            ));
    }, []);

    const handleSubmit = (formValues) => {
        setIsSubmitting(true);
        const updateActorRequest = {
            name: formValues.name,
            organizationType: formValues.organizationType,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            contacts: [
                ...actor.contacts.filter(o => !handledContactTypes.includes(o.type)),
                { type: contactTypes.mainEmail.key, value: formValues.email },
                { type: contactTypes.checkInEmail.key, value: formValues.checkInEmail },
                { type: contactTypes.mainMobileTelephone.key, value: formValues.mobileTelephone },
                { type: contactTypes.checkInMobileTelephone.key, value: formValues.checkInMobileTelephone }
            ],
            sendSms: formValues.sendSms,
            commissionRate: convertToNumber(formValues.commissionRate) / 100,
            organizationNumber: formValues.organizationNumber,
            vatIdentificationNumber: formValues.vatIdentificationNumber,
            isSuperAdmin: formValues.isSuperAdmin,
            isBlocked: formValues.isBlocked,
            backofficeNote: formValues.backofficeNote,
            parentCompanyId: formValues.parentCompanyId === 0 ? undefined : formValues.parentCompanyId,
            isCertifiedPartner: formValues.isCertifiedPartner,
            isOwner: formValues.isOwner
        };
        dispatch(updateActor(actor.id, updateActorRequest))
            .then(handleResponse(
                () => {
                    history.push(routes.admin.actors);
                    setIsSubmitting(false);
                    return { snackbar: { message: 'Actor was successfully saved.' } };
                },
                () => setIsSubmitting(false)
            ));
    };

    const handleCancel = () => {
        history.push(routes.admin.actors);
    };

    const handleLogInClick = () => {
        dispatch(logIn({
            impersonationActorId: actor.id
        }))
            .then(handleResponse(
                () => history.push(routes.account.overview)
            ));
    };

    const actorName = actor
        ? getBusinessOrPrivateName(actor)
        : undefined;

    const title = 'Actor: ' + (actorName ?? 'Loading...');

    return (
        <AdminPage title={title}>
            {
                isLoading && <Loader />
            }
            {
                actor && companies && (
                    <AdminContentWrapper type="detail">

                        <EditActorForm
                            actor={actor}
                            isSubmitting={isSubmitting}
                            companies={companies}
                            onSubmit={handleSubmit}
                            onCancel={handleCancel}
                        />

                        <AdminActionWrapper>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleLogInClick}
                            >
                                Log in as {actorName}
                            </Button>
                        </AdminActionWrapper>

                    </AdminContentWrapper>
                )
            }
        </AdminPage>
    );
};

export default Actor;
