import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import storageGroupCategories from 'enums/storageGroupCategories';
import organizationTypes from 'enums/organizationTypes';
import tenantAvailabilities from 'enums/tenantAvailabilities';
import storageSiteAccessAllowances from 'enums/storageSiteAccessAllowances';

import Box from '@material-ui/core/Box';
import Information from './Information';
import Text from 'common/Text';

const useStyles = makeStyles(({ theme }) => ({
    dimensionsAndFactsWrapper: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    }
}));

const DimensionsAndFacts = ({ storageSite, items, ...rest }) => {
    const classes = useStyles();
    const getFacts = () => {
        const facts = [];

        facts.push({ title: strings.minimumBookingLength, value: strings.formatString(storageSite.minimumBookingLength === 1 ? strings.xDay : strings.xDays, storageSite.minimumBookingLength) });
        facts.push({ title: strings.typeOfOwner, value: organizationTypes[storageSite.ownerType].title });
        facts.push({ title: strings.access, value: tenantAvailabilities[storageSite.guestAvailability].key === tenantAvailabilities.customDescription.key
                ? <Text html={storageSite.guestAvailabilityCustomDescription} />
                : tenantAvailabilities[storageSite.guestAvailability].title });

        const useAccessAllowances = storageSite.storageGroups.filter(o => storageGroupCategories[o.category].useAccessAllowances).length > 0;
        if(useAccessAllowances) {
            if(storageSite.visitAllowance) {
                facts.push({ title: strings.visitAllowance, value: storageSiteAccessAllowances[storageSite.visitAllowance].title });
            }
            if(storageSite.letOutAllowance) {
                facts.push({ title: strings.letOutAllowance, value: storageSiteAccessAllowances[storageSite.letOutAllowance].title });
            }
        }

        return facts;
    };

    return (
        <Box className={classes.dimensionsAndFactsWrapper} {...rest}>
            <Information title={strings.dimensions} titleVariant="h3" items={items} />
            <Information title={strings.facts} titleVariant="h3" items={getFacts()} />
        </Box>
    );
};

DimensionsAndFacts.propTypes = {
    storageSite: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired
};

export default DimensionsAndFacts;
