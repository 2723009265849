import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';

export const ROUNDREPORTS_FETCH = 'ROUNDREPORTS_FETCH';
export const ROUNDREPORTS_COMPLETE = 'ROUNDREPORTS_COMPLETE';
export const ROUNDREPORTS_UPDATE_STORAGE_ITEM = 'ROUNDREPORTS_UPDATE_STORAGE_ITEM';
export const ROUNDREPORTS_DELETE_STORAGE_ITEM = 'ROUNDREPORTS_DELETE_STORAGE_ITEM';
export const ROUNDREPORTS_CREATE_NOTE = 'ROUNDREPORTS_CREATE_NOTE';
export const ROUNDREPORTS_UPDATE_NOTE = 'ROUNDREPORTS_UPDATE_NOTE';
export const ROUNDREPORTS_DELETE_NOTE = 'ROUNDREPORTS_DELETE_NOTE';

export const fetchRoundReport = (roundReportId, token) => ({
    [RSAA]: {
        types: createRsaaTypes(ROUNDREPORTS_FETCH),
        endpoint: `/api/roundReports/${roundReportId}/${token}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const completeRoundReport = (roundReportId, token) => ({
    [RSAA]: {
        types: createRsaaTypes(ROUNDREPORTS_COMPLETE),
        endpoint: `/api/roundReports/${roundReportId}/${token}/complete`,
        headers: createHeaders(false),
        method: 'PUT'
    }
});

export const updateRoundReportStorageItem = (roundReportId, token, storageId, roundReportStorageItem) => ({
    [RSAA]: {
        types: createRsaaTypes(ROUNDREPORTS_UPDATE_STORAGE_ITEM),
        endpoint: `/api/roundReports/${roundReportId}/${token}/storageItems/${storageId}`,
        headers: createHeaders(true),
        method: 'PUT',
        body: JSON.stringify(roundReportStorageItem)
    }
});

export const deleteRoundReportStorageItem = (roundReportId, token, storageId) => ({
    [RSAA]: {
        types: createRsaaTypes(ROUNDREPORTS_DELETE_STORAGE_ITEM),
        endpoint: `/api/roundReports/${roundReportId}/${token}/storageItems/${storageId}`,
        headers: createHeaders(false),
        method: 'DELETE'
    }
});

export const createRoundReportNote = (roundReportId, token, roundReportNote) => ({
    [RSAA]: {
        types: createRsaaTypes(ROUNDREPORTS_CREATE_NOTE),
        endpoint: `/api/roundReports/${roundReportId}/${token}/notes`,
        headers: createHeaders(true),
        method: 'POST',
        body: JSON.stringify(roundReportNote)
    }
});

export const updateRoundReportNote = (roundReportId, token, roundReportNoteId, roundReportNote) => ({
    [RSAA]: {
        types: createRsaaTypes(ROUNDREPORTS_UPDATE_NOTE),
        endpoint: `/api/roundReports/${roundReportId}/${token}/notes/${roundReportNoteId}`,
        headers: createHeaders(true),
        method: 'PUT',
        body: JSON.stringify(roundReportNote)
    }
});

export const deleteRoundReportNote = (roundReportId, token, roundReportNoteId) => ({
    [RSAA]: {
        types: createRsaaTypes(ROUNDREPORTS_DELETE_NOTE),
        endpoint: `/api/roundReports/${roundReportId}/${token}/notes/${roundReportNoteId}`,
        headers: createHeaders(false),
        method: 'DELETE'
    }
});
