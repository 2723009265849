import strings from 'localization/strings';

const messageTypes = {
    email: {},
    sms: {}
};

Object.keys(messageTypes).forEach(key => {
    messageTypes[key].key = key;
});

strings.addLoadCallback(() => {
    Object.keys(messageTypes).forEach(key => {
        messageTypes[key].title = strings.messageTypes[key];
    });
});

export default messageTypes;
