import React, { useState, useEffect } from 'react';
import { useAppContext } from 'context/AppContext';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { fetchRoundReport, completeRoundReport } from 'actions/roundReports';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { handleResponse } from 'actions/actionHelpers';

import PageTitle from 'common/PageTitle';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Loader from 'common/Loader';
import RoundReportStorageItem from './RoundReportStorageItem';
import RoundReportNote from './RoundReportNote';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import MarginBox from 'common/MarginBox';
import Alert from '@material-ui/lab/Alert';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import CachedIcon from '@material-ui/icons/Cached';

const useStyles = makeStyles(({ theme, colors }) => ({
    wrapper: {
        maxWidth: '600px',
        margin: '0 auto',
        padding: theme.spacing(4, 2),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4)
    },
    logo: {
        textAlign: 'center',
        '& img': {
            width: '200px'
        }
    },
    title: {
        marginBottom: theme.spacing(2)
    },
    storageItemsContainer: {
        border: `1px solid ${colors.mediumGrey}`,
        marginBottom: theme.spacing(4)
    },
    notesContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        marginBottom: theme.spacing(4)
    },
    noteList: {
        '&>:not(:last-child)': {
            paddingBottom: theme.spacing(2),
            borderBottom: `1px solid ${colors.mediumGrey}`,
            marginBottom: theme.spacing(2)
        }
    },
    notesCaption: {
        marginBottom: theme.spacing(1)
    },
    instructionsAccordion: {
        marginBottom: theme.spacing(2)
    },
    instructionsAccordionDetails: {
        fontSize: '85%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2)
    },
    instructionsButtonContainer: {
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center',
        '&>:first-child': {
            flex: '0 0 auto'
        },
        '&>:last-child': {
            flex: '1 1 auto'
        }
    },
    instructionsButton: {
        padding: theme.spacing(1),
        minWidth: 'auto'
    }
}));

const RoundReportPage = () => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    const [loading, setLoading] = useState(true);
    const [roundReport, setRoundReport] = useState(undefined);
    const [updatedStorageItems, setUpdatedStorageItems] = useState([]);
    const [notes, setNotes] = useState(undefined);
    const [confirmCompletedDialogOpen, setConfirmCompletedDialogOpen] = useState(false);

    const params = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchRoundReport(params.roundReportId, params.token))
            .then(handleResponse(
                response => {
                    setRoundReport(response.payload);
                    setLoading(false);
                },
                () => {
                    setLoading(false);
                }
            ));
    }, []);

    useEffect(() => {
        if(roundReport) {
            setUpdatedStorageItems(roundReport.storageItems);
            setNotes(roundReport.notes);
        }
    }, [roundReport]);

    const handleChange = (item, updatedItem) => {
        const storageItems = roundReport.storageItems.filter(o => o.storage.id !== item.storage.id);
        storageItems.push({ ...item, ...updatedItem });
        setUpdatedStorageItems(storageItems);
    };

    const handleAddNote = () => {
        setNotes([...notes, { text: undefined, imageData: undefined, new: true }]);
    };

    const handleNoteChange = (note, index) => {
        const updatedNotes = [...notes];
        updatedNotes.splice(index, 1, note);
        setNotes(updatedNotes);
    };

    const handleNoteRemove = index => {
        const updatedNotes = [...notes];
        updatedNotes.splice(index, 1);
        setNotes(updatedNotes);
    };

    const handleCompleted = () => {
        setConfirmCompletedDialogOpen(true);
    };

    const handleConfirmCompletedDialogOk = () => {
        setConfirmCompletedDialogOpen(false);
        setLoading(true);
        dispatch(completeRoundReport(params.roundReportId, params.token))
            .then(handleResponse(
                response => {
                    setRoundReport(response.payload);
                    setLoading(false);
                },
                () => {
                    setLoading(false);
                }
            ));
    };

    const handleConfirmCompletedDialogCancel = () => {
        setConfirmCompletedDialogOpen(false);
    };

    const getHandledCount = () => updatedStorageItems.filter(o => o.occupiedInReality !== undefined).length;

    const pageTitle = strings.formatString(strings.pages.roundReport.title, roundReport?.storageSite.title);

    const isCompleted = roundReport?.completedTime !== undefined;

    const getInstructionsButton = icon => (
        <Button
            className={classes.instructionsButton}
            variant="contained"
            color="primary"
        >
            {icon}
        </Button>
    );

    return (
        <>
            <PageTitle>{loading ? strings.loadingText : pageTitle}</PageTitle>
            <Box className={classes.wrapper}>
                <Box className={classes.logo}>
                    <img src={appContext.images.topLogo}/>
                </Box>
                {loading && <Loader/>}
                {
                    !loading && roundReport &&
                    (
                        <Box>
                            <Typography variant="h3" gutterBottom className={classes.title}>{pageTitle}</Typography>

                            {
                                isCompleted &&
                                (
                                    <MarginBox bottom={2}>
                                        <Alert severity="info">
                                            {strings.pages.roundReport.isCompleted}
                                        </Alert>
                                    </MarginBox>
                                )
                            }

                            {
                                !isCompleted &&
                                (
                                    <Accordion className={classes.instructionsAccordion}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                            <Typography>{strings.instructions}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className={classes.instructionsAccordionDetails}>
                                            <Box>
                                                {strings.pages.roundReport.instructions.text1}
                                            </Box>
                                            <Box className={classes.instructionsButtonContainer}>
                                                <Box>
                                                    {getInstructionsButton(<CheckIcon/>)}
                                                </Box>
                                                <Box>
                                                    {strings.pages.roundReport.instructions.text2}
                                                </Box>
                                            </Box>
                                            <Box className={classes.instructionsButtonContainer}>
                                                <Box>
                                                    {getInstructionsButton(<CachedIcon/>)}
                                                </Box>
                                                <Box>
                                                    {strings.pages.roundReport.instructions.text3}
                                                </Box>
                                            </Box>
                                            <Box>
                                                {strings.pages.roundReport.instructions.text4}
                                            </Box>
                                            <Box>
                                                {strings.pages.roundReport.instructions.text5}
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            }

                            <Box className={classes.storageItemsContainer}>
                                {
                                    roundReport.storageItems.map(storageItem => (
                                        <RoundReportStorageItem
                                            key={storageItem.storage.id}
                                            roundReport={roundReport}
                                            roundReportStorageItem={storageItem}
                                            onChange={updatedStorageItem => handleChange(storageItem, updatedStorageItem)}
                                        />
                                    ))
                                }
                            </Box>
                            <Box className={classes.notesContainer}>
                                {
                                    notes?.length > 0 &&
                                    (
                                        <Typography variant="h4" className={classes.notesCaption}>{strings.pages.roundReport.notes}</Typography>
                                    )
                                }
                                <Box className={classes.noteList}>
                                    {
                                        notes?.map((note, index) => (
                                            <RoundReportNote
                                                key={note.id ?? 0}
                                                roundReport={roundReport}
                                                roundReportNote={note}
                                                onChange={updatedNote => handleNoteChange(updatedNote, index)}
                                                onRemove={() => handleNoteRemove(index)}
                                            />
                                        ))
                                    }
                                </Box>
                                {
                                    !isCompleted &&
                                    (
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleAddNote}
                                            fullWidth
                                        >
                                            {strings.pages.roundReport.addNote}
                                        </Button>
                                    )
                                }
                            </Box>
                            {
                                !isCompleted &&
                                (
                                    <Box>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleCompleted}
                                            fullWidth
                                            disabled={getHandledCount() < roundReport.storageItems.length}
                                        >
                                            {strings.pages.roundReport.markAsCompleted}
                                        </Button>
                                    </Box>
                                )
                            }

                            <Dialog open={confirmCompletedDialogOpen} onClose={handleConfirmCompletedDialogCancel}>
                                <DialogTitle disableTypography>
                                    <Typography variant="h5">
                                        {strings.pages.roundReport.markAsCompleted}
                                    </Typography>
                                </DialogTitle>
                                <DialogContent>
                                    <Typography variant="body1" gutterBottom>
                                        {strings.pages.roundReport.confirmMarkAsCompleted}
                                    </Typography>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleConfirmCompletedDialogOk}
                                    >
                                        {strings.ok}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleConfirmCompletedDialogCancel}
                                    >
                                        {strings.cancel}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Box>
                    )
                }
            </Box>
        </>
    );
};

export default RoundReportPage;
