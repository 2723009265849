import React from 'react';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { formatLength, convertToNumber } from 'helpers/StringHelper';
import storageSiteFacilities from 'enums/storageSiteFacilities';
import tenantAvailabilities from 'enums/tenantAvailabilities';
import storageSiteAccessAllowances from 'enums/storageSiteAccessAllowances';
import allGoodsTypes from 'enums/goodsTypes';
import strings from 'localization/strings';
import { getPriceAdjustmentExplanation } from 'helpers/StorageSiteHelper';
import { getOptionToTaxFromFormData, getStorageGroupCategoriesFromFormData, showExclusiveVatSuffix } from 'logic/editStorageSiteLogic';
import bookingTimeModes from 'enums/bookingTimeModes';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ReadOnlyCheckboxList from './ReadOnlyCheckboxList';
import GridSeparator from 'common/GridSeparator';
import AdditionalServiceList from './AdditionalServiceList';
import Box from '@material-ui/core/Box';
import Text from 'common/Text';

const useStyles = makeStyles(({ theme }) => ({
    dimensionsList: {
        margin: theme.spacing(0, 0, 2, 0),
        padding: 0,
        display: 'table',
        '& li': {
            listStyle: 'none',
            lineHeight: '1.5rem !important',
            display: 'table-row',
            '& span': {
                display: 'table-cell',
                '&:first-child': {
                    paddingRight: theme.spacing(2)
                },
                '&:last-child': {
                    textAlign: 'right'
                }
            }
        }
    }
}));

const DisplayStep3 = () => {
    const classes = useStyles();
    const formValues = useForm().getState().values;
    const values = formValues.steps[3];
    const fieldNamePrefix = 'steps[3]';

    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor } = authenticationContext;
    const { appContext } = useAppContext();

    const showGridSeparators = !useMediaQuery(theme => theme.breakpoints.up('sm'));

    const showDoorDimensions = values.doorHeight || values.doorWidth;

    const categories = getStorageGroupCategoriesFromFormData(formValues, appContext);

    const useGoodsTypes = categories
        .filter(category => category.useGoodsTypes)
        .length > 0;
    const useAccessAllowances = categories
        .filter(category => category.useAccessAllowances)
        .length > 0;

    const mdCheckboxColumnWidth = useGoodsTypes ? 3 : 4;

    const environments = values.environment
        ? [storageSiteFacilities[values.environment].title]
        : [];
    const facilities = values.facilities.map(o => storageSiteFacilities[o].title);
    const securities = values.securities.map(o => storageSiteFacilities[o].title);
    const availabilities = values.availability === tenantAvailabilities.customDescription.key
        ? [<Box><Text html={values.guestAvailabilityCustomDescription} /></Box>]
        : [tenantAvailabilities[values.availability].title];
    const goodsTypes = values.goodsTypes.map(o => allGoodsTypes[o].title);
    const visitAllowances = values.visitAllowance ? [storageSiteAccessAllowances[values.visitAllowance].title] : [];
    const letOutAllowances = values.letOutAllowance ? [storageSiteAccessAllowances[values.letOutAllowance].title] : [];
    const timeModes = values.allowedTimeModes.map(o => bookingTimeModes[o].title);

    const optionToTax = getOptionToTaxFromFormData(formValues);
    const exclusiveVatSuffixVisible = showExclusiveVatSuffix(184, categories.map(o => o.key), optionToTax);

    return (
        <Grid container spacing={2}>
            {
                appContext.facilityCategories.environment.length > 0 &&
                (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>{strings.environment}</Typography>
                            <ReadOnlyCheckboxList items={environments} emptyLabel="-"/>
                        </Grid>

                        <GridSeparator />
                    </>
                )
            }

            {
                showDoorDimensions &&
                (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                                {strings.dimensions}
                            </Typography>

                            <ul className={classes.dimensionsList}>
                                { values.doorHeight && <li><span>{strings.doorHeight}:</span><span>{formatLength(values.doorHeight, appContext, { maxNumberOfDecimals: 1 })}</span></li> }
                                { values.doorWidth && <li><span>{strings.doorWidth}:</span><span>{formatLength(values.doorWidth, appContext, { maxNumberOfDecimals: 1 })}</span></li> }
                            </ul>
                        </Grid>

                        <GridSeparator />
                    </>
                )
            }

            {
                appContext.facilityCategories.general.length > 0 &&
                (
                    <>
                        <Grid item xs={12} md={mdCheckboxColumnWidth}>
                            <Typography variant="h6" gutterBottom>{strings.facilities}</Typography>
                            <ReadOnlyCheckboxList items={facilities} emptyLabel="-"/>
                        </Grid>

                        { showGridSeparators && <GridSeparator /> }
                    </>
                )
            }

            {
                appContext.facilityCategories.security.length > 0 &&
                (
                    <>
                        <Grid item xs={12} md={mdCheckboxColumnWidth}>
                            <Typography variant="h6" gutterBottom>{strings.securities}</Typography>
                            <ReadOnlyCheckboxList items={securities} emptyLabel="-"/>
                        </Grid>

                        { showGridSeparators && <GridSeparator /> }
                    </>
                )
            }

            <Grid item xs={12} md={mdCheckboxColumnWidth}>
                <Typography variant="h6" gutterBottom>{strings.access}</Typography>
                <ReadOnlyCheckboxList items={availabilities} emptyLabel="-"/>
            </Grid>

            {
                useGoodsTypes &&
                (
                    <>
                        { showGridSeparators && <GridSeparator /> }

                        <Grid item xs={12} md={mdCheckboxColumnWidth}>
                            <Typography variant="h6" gutterBottom>{strings.vehicleTypes}</Typography>
                            <ReadOnlyCheckboxList items={goodsTypes} emptyLabel="-"/>
                        </Grid>
                    </>
                )
            }

            {
                useAccessAllowances &&
                (
                    <>
                        <GridSeparator />

                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom>{strings.visitAllowanceForEdit}</Typography>
                            <ReadOnlyCheckboxList items={visitAllowances} emptyLabel="-"/>
                        </Grid>

                        { showGridSeparators && <GridSeparator /> }

                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom>{strings.letOutAllowanceForEdit}</Typography>
                            <ReadOnlyCheckboxList items={letOutAllowances} emptyLabel="-"/>
                        </Grid>
                    </>
                )

            }
            <GridSeparator />

            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>{strings.minimumBookingLength}</Typography>
                <Typography variant="body1">
                    {strings.formatString(convertToNumber(values.minimumBookingLength) === 1 ? strings.xDay : strings.xDays, values.minimumBookingLength ?? '')}
                </Typography>
            </Grid>

            <GridSeparator topSpacing={4} />

            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>{strings.allowedBookingTimeMode}</Typography>
                <ReadOnlyCheckboxList items={timeModes} emptyLabel="-"/>
            </Grid>

            <GridSeparator />

            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>{strings.additionalServices}</Typography>
                {
                    values.additionalServices.length === 0 && <Typography variant="body1">{strings.noAdditionalServices}</Typography>
                }
                {
                    values.additionalServices.length > 0 &&
                    (
                        <AdditionalServiceList
                            fieldNamePrefix={fieldNamePrefix}
                            actor={selectedActor}
                            showExclusiveVatSuffix={exclusiveVatSuffixVisible}
                        />
                    )
                }
            </Grid>

            {
                values.serviceDescription &&
                (
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>{strings.otherAdditionalServices}</Typography>
                        <Typography variant="body1">{values.serviceDescription}</Typography>
                    </Grid>
                )
            }

            {
                values.annualPriceAdjustmentDate &&
                (
                    <>
                        <GridSeparator topSpacing={4} />

                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>{strings.priceAdjustment}</Typography>
                            {
                                getPriceAdjustmentExplanation(
                                    strings.priceAdjustmentDescriptionTemplateForEdit,
                                    convertToNumber(values.annualPriceAdjustmentPercentage) / 100,
                                    values.annualPriceAdjustmentDate,
                                    appContext
                                )
                            }
                        </Grid>
                    </>
                )
            }
        </Grid>
    );
};

export default DisplayStep3;
