import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { required, mustBeGreaterThan, mustBeNullOrGreaterThan, monthAndDay } from 'form/validation';
import { useForm, useField } from 'react-final-form';
import { useSelector } from 'react-redux';
import strings from 'localization/strings';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import storageSiteFacilities from 'enums/storageSiteFacilities';
import tenantAvailabilities from 'enums/tenantAvailabilities';
import storageSiteAccessAllowances from 'enums/storageSiteAccessAllowances';
import storageGroupCategories from 'enums/storageGroupCategories';
import allGoodsTypes from 'enums/goodsTypes';
import { getOptionToTaxFromFormData, showExclusiveVatSuffix, getStorageGroupCategoriesFromFormData } from 'logic/editStorageSiteLogic';
import { createEndAdornment } from 'helpers/FormHelper';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { TextField, Checkboxes, Radios, showErrorOnBlur } from 'mui-rff';
import GridSeparator from 'common/GridSeparator';
import AdditionalServiceList from './AdditionalServiceList';
import bookingTimeModes from 'enums/bookingTimeModes';

const useStyles = makeStyles(({ theme }) => ({
    heading: {
        paddingBottom: '0 !important'
    },
    grid: {
        marginBottom: theme.spacing(4)
    },
    additionalServiceList: {
        marginBottom: theme.spacing(2)
    },
    marginBottom: {
        marginBottom: theme.spacing(2)
    }
}));

const EditStep3 = ({ showStepNumber = true }) => {
    const classes = useStyles();
    const form = useForm();
    const showGridSeparators = !useMediaQuery(theme => theme.breakpoints.up('sm'));
    const fieldNamePrefix = 'steps[3]';

    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor } = authenticationContext;
    const { appContext } = useAppContext();

    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;
    const getValue = suffix => useField(getName(suffix)).input.value;
    const changeValue = (suffix, newValue) => form.mutators.changeValue({ name: getName(suffix), value: newValue });

    const formValues = form.getState().values;

    const environment = getValue('environment');
    const usePriceAdjustment = getValue('usePriceAdjustment');
    const availability = getValue('availability');

    const showDimensions = environment === storageSiteFacilities.indoor.key;
    const showCustomDescription = availability === tenantAvailabilities.customDescription.key;

    const categories = getStorageGroupCategoriesFromFormData(formValues, appContext);

    const hasVehicleStorageGroup = categories
        .filter(category => category.key === storageGroupCategories.vehicle.key)
        .length > 0;

    const showGoodsTypes = hasVehicleStorageGroup;
    const showAccessAllowances = hasVehicleStorageGroup;

    const mdCheckboxColumnWidth = showGoodsTypes ? 3 : 4;

    const optionToTax = getOptionToTaxFromFormData(formValues);
    const exclusiveVatSuffixVisible = showExclusiveVatSuffix(selectedActor, categories.map(o => o.key), optionToTax);

    useEffect(() => {
        if(environment === storageSiteFacilities.outdoor.key) {
            changeValue('doorHeight', undefined);
            changeValue('doorWidth', undefined);
        }
    }, [environment]);

    useEffect(() => {
        if(availability !== tenantAvailabilities.customDescription.key) {
            changeValue('guestAvailabilityCustomDescription', undefined);
        }
    }, [availability]);

    const environments = appContext.facilityCategories.environment
        .map(o => storageSiteFacilities[o])
        .map(o => ({
            label: o.title,
            value: o.key
        }));

    const facilities = appContext.facilityCategories.general
        .map(o => storageSiteFacilities[o])
        .map(o => ({
            label: o.title,
            value: o.key
        }));

    const securities = appContext.facilityCategories.security
        .map(o => storageSiteFacilities[o])
        .map(o => ({
            label: o.title,
            value: o.key
        }));

    const availabilities = [
        tenantAvailabilities.always,
        tenantAvailabilities.timeAppointed,
        tenantAvailabilities.customDescription
    ].map(o => ({
        label: o.title,
        value: o.key
    }));

    const accessAllowances = [
        storageSiteAccessAllowances.free,
        storageSiteAccessAllowances.chargeable,
        storageSiteAccessAllowances.notAllowed
    ].map(o => ({
        label: o.title,
        value: o.key
    }));

    const goodsTypes = [
        allGoodsTypes.caravan,
        allGoodsTypes.recreationalVehicle,
        allGoodsTypes.car,
        allGoodsTypes.motorcycle,
        allGoodsTypes.trailer,
        allGoodsTypes.snowmobile,
        allGoodsTypes.jetski,
        allGoodsTypes.boat
    ].map(o => ({
        label: o.title,
        value: o.key
    }));

    const allowedTimeModes = [
        bookingTimeModes.period,
        bookingTimeModes.subscription
    ].map(o => ({
        label: o.title,
        value: o.key
    }));

    const atLeastOneTimeModeValidator = value => {
        if(value.length > 0) {
            return undefined;
        }
        return strings.atLeastOneBookingTimeModeMustBeGiven;
    };

    return (
        <Box>
            <Typography variant="h5" className={classes.marginBottom}>
                { showStepNumber && '4. ' }
                {strings.propertiesAndFacilities}
            </Typography>

            <Grid container spacing={2} className={classes.grid}>
                {
                    environments.length > 0 &&
                    (
                        <>
                            <Grid item xs={12} md={mdCheckboxColumnWidth}>
                                <Radios
                                    label={strings.environment}
                                    name={getName('environment')}
                                    data={environments}
                                    showError={showErrorOnBlur}
                                    required
                                    fieldProps={{ validate: required }}
                                />
                            </Grid>

                            <GridSeparator />
                        </>
                    )
                }

                {
                    showDimensions &&
                    (
                        <>
                            <Grid item xs={12} className={classes.heading}>
                                <Typography variant="h6" gutterBottom>
                                    {strings.dimensions}
                                </Typography>
                            </Grid>

                            <Grid item xs={6} md={4}>
                                <TextField
                                    name={getName('doorHeight')}
                                    label={strings.doorHeight}
                                    variant="outlined"
                                    showError={showErrorOnBlur}
                                    helperText={strings.doorHeightHelperText}
                                    fieldProps={{ validate: mustBeNullOrGreaterThan(0) }}
                                    InputProps={createEndAdornment(strings.meterAbbreviated)}
                                />
                            </Grid>

                            <Grid item xs={6} md={4}>
                                <TextField
                                    name={getName('doorWidth')}
                                    label={strings.doorWidth}
                                    variant="outlined"
                                    showError={showErrorOnBlur}
                                    helperText={strings.doorWidthHelperText}
                                    fieldProps={{ validate: mustBeNullOrGreaterThan(0) }}
                                    InputProps={createEndAdornment(strings.meterAbbreviated)}
                                />
                            </Grid>

                            <GridSeparator />
                        </>
                    )
                }

                {
                    facilities.length > 0 &&
                    (
                        <>
                            <Grid item xs={12} md={mdCheckboxColumnWidth}>
                                <Checkboxes
                                    label={strings.facilities}
                                    name={getName('facilities')}
                                    data={facilities}
                                />
                            </Grid>

                            { showGridSeparators && <GridSeparator /> }
                        </>
                    )
                }

                {
                    securities.length > 0 &&
                    (
                        <>
                            <Grid item xs={12} md={mdCheckboxColumnWidth}>
                                <Checkboxes
                                    label={strings.securities}
                                    name={getName('securities')}
                                    data={securities}
                                />
                            </Grid>

                            { showGridSeparators && <GridSeparator /> }
                        </>
                    )
                }

                <Grid item xs={12} md={mdCheckboxColumnWidth}>
                    <Radios
                        label={strings.access}
                        name={getName('availability')}
                        data={availabilities}
                        showError={showErrorOnBlur}
                        required
                        fieldProps={{ validate: required }}
                    />
                    {
                        showCustomDescription &&
                        (
                            <Grid item xs={10}>
                                <TextField
                                    name={getName('guestAvailabilityCustomDescription')}
                                    label={strings.customDescription}
                                    variant="outlined"
                                    showError={showErrorOnBlur}
                                    required
                                    maxLength={200}
                                    multiline
                                    rows={3}
                                    fieldProps={{ validate: required }}
                                />
                            </Grid>
                        )
                    }
                </Grid>

                {
                    showGoodsTypes &&
                    (
                        <>
                            { showGridSeparators && <GridSeparator /> }

                            <Grid item xs={12} md={mdCheckboxColumnWidth}>
                                <Checkboxes
                                    label={strings.vehicleTypes}
                                    name={getName('goodsTypes')}
                                    data={goodsTypes}
                                />
                            </Grid>
                        </>
                    )
                }

                {
                    showAccessAllowances &&
                    (
                        <>
                            <GridSeparator />

                            <Grid item xs={12} md={6}>
                                <Radios
                                    label={strings.visitAllowanceForEdit}
                                    name={getName('visitAllowance')}
                                    data={accessAllowances}
                                />
                            </Grid>

                            { showGridSeparators && <GridSeparator /> }

                            <Grid item xs={12} md={6}>
                                <Radios
                                    label={strings.letOutAllowanceForEdit}
                                    name={getName('letOutAllowance')}
                                    data={accessAllowances}
                                />
                            </Grid>
                        </>
                    )

                }

                <GridSeparator />

                <Grid item xs={12}>
                    <TextField
                        name={getName('minimumBookingLength')}
                        label={strings.minimumBookingLength}
                        variant="outlined"
                        showError={showErrorOnBlur}
                        helperText={strings.minimumBookingLengthHelperText}
                        required
                        fieldProps={{ validate: mustBeGreaterThan(0) }}
                        InputProps={createEndAdornment(strings.daysLower)}
                    />
                </Grid>

                <GridSeparator />

                <Grid item xs={12}>
                    <Checkboxes
                        label={strings.allowedBookingTimeMode}
                        name={getName('allowedTimeModes')}
                        data={allowedTimeModes}
                        fieldProps={{ validate: atLeastOneTimeModeValidator }}
                        showError={showErrorOnBlur}
                    />
                </Grid>

                <GridSeparator />

                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>
                        {strings.additionalServices}
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        {strings.hintLongAdditionalServices}
                    </Typography>

                    <Box className={classes.additionalServiceList}>
                        <AdditionalServiceList
                            fieldNamePrefix={fieldNamePrefix}
                            actor={selectedActor}
                            showExclusiveVatSuffix={exclusiveVatSuffixVisible}
                            editable
                        />
                    </Box>

                    <TextField
                        name={getName('serviceDescription')}
                        label={strings.otherAdditionalServices}
                        variant="outlined"
                        multiline
                        rows={4}
                        helperText={strings.otherAdditionalServicesHelperText}
                    />
                </Grid>

                <GridSeparator />

                <Grid item xs={12}>
                    <Checkboxes
                        name={getName('usePriceAdjustment')}
                        data={
                            { label: strings.usePriceAdjustment, value: true }
                        }
                        helperText={strings.usePriceAdjustmentHelperText}
                    />
                </Grid>

                {
                    usePriceAdjustment &&
                    (
                        <>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name={getName('annualPriceAdjustmentPercentage')}
                                    label={strings.annualPriceAdjustmentPercentage}
                                    variant="outlined"
                                    showError={showErrorOnBlur}
                                    helperText={strings.annualPriceAdjustmentPercentageHelperText}
                                    required
                                    fieldProps={{ validate: required }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name={getName('annualPriceAdjustmentDate')}
                                    label={strings.annualPriceAdjustmentDate}
                                    variant="outlined"
                                    showError={showErrorOnBlur}
                                    placeholder={strings.annualPriceAdjustmentDatePlaceholder}
                                    helperText={strings.annualPriceAdjustmentDateHelperText}
                                    required
                                    fieldProps={{ validate: monthAndDay }}
                                />
                            </Grid>
                        </>
                    )
                }
            </Grid>

        </Box>
    );
};

EditStep3.propTypes = {
    showStepNumber: PropTypes.bool
};

export default EditStep3;
