import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { handleResponse } from 'actions/actionHelpers';
import { fetchOwnerBookings } from 'actions/account/ownerBookingsSection';
import strings from 'localization/strings';
import { sendMessageToTenants } from 'actions/ownerBookings';
import { getStorageSiteAndStorageTitle } from 'helpers/StorageSiteHelper';
import { distinct } from 'helpers/ArrayHelper';
import { formatTimePeriod } from 'helpers/StringHelper';
import { setSuccessResponse, setErrorResponse } from 'actions/responses';
import { makeStyles } from 'styles/util';
import { getContact, getBusinessOrPrivateName } from 'helpers/ActorHelper';
import { arrayDictionarify } from 'helpers/ArrayHelper';
import { changeValueMutator } from 'helpers/FormHelper';
import contactTypes from 'enums/contactTypes';
import bookingStatuses from 'enums/bookingStatuses';
import messageTypes from 'enums/messageTypes';
import arrayMutators from 'final-form-arrays';
import { required } from 'form/validation';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from 'form/Form';
import { FieldArray } from 'react-final-form-arrays';
import { TextField, Checkboxes, showErrorOnBlur } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Loader from 'common/Loader';

const useStyles = makeStyles(({ theme, colors }) => ({
    selectionContainer: {
        display: 'flex',
        gap: theme.spacing(2),
        justifyContent: 'space-between',
        marginBottom: theme.spacing(2)
    },
    selectionButtonContainer: {
        display: 'flex',
        gap: theme.spacing(2)
    },
    list: {
        maxHeight: '400px',
        overflow: 'auto'
    },
    information: {
        marginBottom: theme.spacing(2)
    },
    listItemSecondary: {
        color: colors.mediumDarkGrey,
        fontSize: '75%'
    },
    checkboxContainer: {
        alignSelf: 'start'
    }
}));

const SendMessageToTenantsDialog = ({ messageType, filterParams, open, onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [initialValues, setInitialValues] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor } = authenticationContext;
    const contactType = messageType === messageTypes.email.key
        ? contactTypes.mainEmail.key
        : contactTypes.mainMobileTelephone.key;

    const actionListItems = ['send', 'copyContacts']
        .map(key => ({
            key: key,
            title: strings.sendMessageDialogActions[key]
        }));

    const title = messageType === messageTypes.email.key
        ? strings.sendEmailToTenants
        : strings.sendSmsToTenants;

    const bodyHelperText = messageType === messageTypes.email.key
        ? strings.messageBodyHelperText + ' ' + strings.messageWillBeWrappedInStandardEmailTemplate
        : strings.messageBodyHelperText;

    useEffect(() => {
        if(open) {
            setIsLoading(true);
            const request = {
                ownerActorId: selectedActor.id,
                adminBookingStatus: filterParams.adminBookingStatus,
                storageSiteId: filterParams.storageSiteId,
                q: filterParams.q
            };
            dispatch(fetchOwnerBookings(request))
                .then(handleResponse(
                    response => {
                        const purchasedBookings = response.payload.items.filter(o => o.bookingStatus === bookingStatuses.purchased.key);
                        const actorInfoItems = Object.values(arrayDictionarify(purchasedBookings, o => o.tenantActor.id))
                            .map(o => ({
                                actor: o[0].tenantActor,
                                bookings: o,
                                selected: true
                            }));
                        setInitialValues({
                            actorInfoItems,
                            subject: '',
                            body: '',
                            action: 'send'
                        });
                        setIsLoading(false);
                    }));
        }
    }, [open]);

    const handleFormSubmit = values => {
        // send message
        const request = {
            messageType,
            subject: values.subject,
            body: values.body,
            tenantActorIds: values.actorInfoItems.filter(o => o.selected).map(o => o.actor.id)
        };
        setIsLoading(true);
        dispatch(sendMessageToTenants(selectedActor.id, request))
            .then(handleResponse(
                response => {
                    onClose();
                    dispatch(setSuccessResponse(strings.formatString(strings.xMessagesWereSent, response.payload.count)));
                }
            ));
    };

    const handleCopy = success => {
        if(success) {
            onClose();
            dispatch(setSuccessResponse(strings.copyToClipboardSucceeded));
        } else {
            dispatch(setErrorResponse(strings.copyToClipboardFailed));
        }
    };

    const getSemicolonSeparatedContacts = values => distinct(values.actorInfoItems
            .filter(actorInfoItem => actorInfoItem.selected)
            .map(actorInfoItem => getContact(actorInfoItem.actor, contactType))
            .filter(o => o)
        )
        .join(';');

    const getBodyRowHeight = () => messageType === messageTypes.email.key ? 10 : 3;

    const getMaxLength = () => messageType === messageTypes.email.key ? undefined : 160;

    const updateSelectedByPredicate = (form, values, predicate) => {
        for(let i = 0; i < values.actorInfoItems.length; i++) {
            form.mutators.changeValue({ name: `actorInfoItems[${i}].selected`, value: predicate(values.actorInfoItems[i].actor) });
        }
    };

    const getSelectedCount = values => values.actorInfoItems.filter(actorInfoItem => actorInfoItem.selected).length;

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <Form
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                mutators={{
                    ...arrayMutators,
                    changeValue: changeValueMutator
                }}
>
                {({ handleSubmit, values, invalid, form }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogTitle disableTypography>
                                <Typography variant="h5">
                                    {title}
                                </Typography>
                            </DialogTitle>
                            <DialogContent>
                                {
                                    isLoading && <Loader/>
                                }
                                {
                                    !isLoading &&
                                    (
                                        <>
                                            <Alert severity="info" className={classes.information}>
                                                {strings.sendMessageToTenantsDialogBody}
                                            </Alert>

                                            <FieldArray name="actorInfoItems">
                                                {({ fields }) => (
                                                    <>
                                                        {
                                                            fields.length === 0 &&
                                                            (
                                                                <Typography variant="body1" gutterBottom>
                                                                    {strings.noBookingsInThisCategory}
                                                                </Typography>
                                                            )
                                                        }
                                                        {
                                                            fields.length > 0 &&
                                                            (
                                                                <>
                                                                    <Box className={classes.selectionContainer}>
                                                                        <Typography variant="h5">
                                                                            {strings.selection} ({getSelectedCount(values)})
                                                                        </Typography>

                                                                        <Box className={classes.selectionButtonContainer}>
                                                                            <Button
                                                                                variant="outlined"
                                                                                color="primary"
                                                                                size="small"
                                                                                onClick={() => updateSelectedByPredicate(form, values, () => true)}
                                                                            >
                                                                                {strings.selectAll}
                                                                            </Button>
                                                                            {
                                                                                messageType === messageTypes.sms.key &&
                                                                                (
                                                                                    <Button
                                                                                        variant="outlined"
                                                                                        color="primary"
                                                                                        size="small"
                                                                                        onClick={() => updateSelectedByPredicate(form, values, actor => actor.sendSms)}
                                                                                    >
                                                                                        {strings.selectSendSmsOnly}
                                                                                    </Button>
                                                                                )
                                                                            }
                                                                            <Button
                                                                                variant="outlined"
                                                                                color="primary"
                                                                                size="small"
                                                                                onClick={() => updateSelectedByPredicate(form, values, () => false)}
                                                                            >
                                                                                {strings.deselectAll}
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>

                                                                    <List className={classes.list}>
                                                                        {
                                                                            fields.map((name, index) => {
                                                                                const actorInfoItem = fields.value[index];

                                                                                const primary = (
                                                                                    <Box>
                                                                                        {getBusinessOrPrivateName(actorInfoItem.actor)} &ndash; {getContact(actorInfoItem.actor, contactType)}
                                                                                    </Box>
                                                                                );
                                                                                const secondary = (
                                                                                    <Box className={classes.listItemSecondary}>
                                                                                        {
                                                                                            actorInfoItem.bookings.map(booking => (
                                                                                                <Box key={booking.id}>
                                                                                                    {getStorageSiteAndStorageTitle(booking.storage, booking.storageGroup)}, {formatTimePeriod(booking)}
                                                                                                </Box>
                                                                                            ))
                                                                                        }
                                                                                    </Box>
                                                                                );

                                                                                return (
                                                                                    <ListItem key={actorInfoItem.actor.id} dense>
                                                                                        <ListItemIcon className={classes.checkboxContainer}>
                                                                                            <Checkboxes
                                                                                                name={`${name}.selected`}
                                                                                                data={{}}
                                                                                            />
                                                                                        </ListItemIcon>
                                                                                        <ListItemText
                                                                                            primary={primary}
                                                                                            secondary={secondary}
                                                                                        />
                                                                                    </ListItem>
                                                                                );
                                                                            })
                                                                        }
                                                                    </List>
                                                                    <TextField
                                                                        select
                                                                        name="action"
                                                                        variant="outlined"
                                                                    >
                                                                        {
                                                                            actionListItems.map(o => (
                                                                                <MenuItem key={o.key} value={o.key}>
                                                                                    {o.title}
                                                                                </MenuItem>
                                                                            ))
                                                                        }
                                                                    </TextField>

                                                                    {
                                                                        values.action === 'send' &&
                                                                        (
                                                                            <>
                                                                                {
                                                                                    messageType === messageTypes.email.key &&
                                                                                    (
                                                                                        <TextField
                                                                                            name="subject"
                                                                                            label={strings.messageSubject}
                                                                                            type="text"
                                                                                            variant="outlined"
                                                                                            required
                                                                                            fieldProps={{ validate: required }}
                                                                                            showError={showErrorOnBlur}
                                                                                        />
                                                                                    )
                                                                                }
                                                                                <TextField
                                                                                    name="body"
                                                                                    label={strings.messageBody}
                                                                                    type="text"
                                                                                    variant="outlined"
                                                                                    multiline
                                                                                    rows={getBodyRowHeight()}
                                                                                    maxLength={getMaxLength()}
                                                                                    required
                                                                                    fieldProps={{ validate: required }}
                                                                                    showError={showErrorOnBlur}
                                                                                    helperText={bodyHelperText}
                                                                                />
                                                                            </>
                                                                        )
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                )}
                                            </FieldArray>
                                        </>
                                    )
                                }
                            </DialogContent>
                            <DialogActions>
                                {
                                    values.action === 'send' && !isLoading &&
                                    (
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={!values.actorInfoItems.some(o => o.selected) || invalid}
                                        >
                                            {strings.send}
                                        </Button>
                                    )
                                }

                                {
                                    values.action === 'copyContacts' && !isLoading &&
                                    (
                                        <CopyToClipboard text={getSemicolonSeparatedContacts(values)}
                                            onCopy={handleCopy}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={!values.actorInfoItems.some(o => o.selected) || invalid}
                                            >
                                                {strings.copyToClipboard}
                                            </Button>
                                        </CopyToClipboard>
                                    )
                                }

                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={onClose}
                                >
                                    {strings.close}
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Form>
        </Dialog>
    );
};

SendMessageToTenantsDialog.propTypes = {
    messageType: PropTypes.string,
    filterParams: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default SendMessageToTenantsDialog;
