import React, { useState, useEffect } from 'react';
import { useAppContext } from 'context/AppContext';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { createBookingLockCode } from 'actions/tenantBookings';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { handleResponse } from 'actions/actionHelpers';
import lockTypes from 'enums/lockTypes';
import contactTypes from 'enums/contactTypes';
import { getContact } from 'helpers/ActorHelper';
import bookingItemTypes from 'enums/bookingItemTypes';
import additionalServiceTypes from 'enums/additionalServiceTypes';
import { getAdditionalServiceById } from 'helpers/AdditionalServiceHelper';

import PageTitle from 'common/PageTitle';
import MarginBox from 'common/MarginBox';
import Text from 'common/Text';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Loader from 'common/Loader';
import Alert from '@material-ui/lab/Alert';
import AddToHomeScreenInstructions from './AddToHomeScreenInstructions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(() => ({
    wrapper: {
        width: '400px',
        margin: '0 auto',
        padding: '2em'
    },
    logo: {
        textAlign: 'center',
        '& img': {
            width: '200px'
        }
    },
    storageSiteTitle: {
        textAlign: 'center',
        marginTop: '2em',
        fontWeight: 'bold',
        fontSize: '18px'
    },
    storageTitle: {
        textAlign: 'center',
        marginTop: '0.25em',
        fontWeight: 'bold',
        fontSize: '26px'
    },
    storageTitleLabel: {
        textAlign: 'center',
        marginTop: '2.5em'
    },
    multipleCodeContainer: {
        margin: '2em 0'
    },
    name: {
        textAlign: 'center',
        fontSize: '25px'
    },
    shortCode: {
        margin: '0.5em 0',
        textAlign: 'center',
        fontSize: '60px'
    },
    mediumCode: {
        margin: '1em 0',
        textAlign: 'center',
        fontSize: '30px'
    },
    longCode: {
        margin: '2em 0',
        textAlign: 'center',
        fontSize: '20px'
    },
    multipleCode: {
        marginTop: '0',
    },
    error: {
        textAlign: 'center',
        margin: '2em 0',
        fontWeight: 'bold',
        color: '#ff0000'
    },
    bookingLockCodeExpiration: {
        marginTop: '2em',
        color: '#7f7f7f'
    },
    homeScreenInstructions: {
        marginTop: '3em'
    }
}));

const BookingLockInformationPage = () => {
    const { appContext } = useAppContext();
    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [booking, setBooking] = useState(undefined);
    const [bookingLockCode, setBookingLockCode] = useState(undefined);

    const params = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(createBookingLockCode(params.bookingId, params.token))
            .then(handleResponse(
                response => {
                    setBooking(response.payload.booking);
                    setBookingLockCode(response.payload.bookingLockCode);
                    setLoading(false);
                },
                () => {
                    setLoading(false);
                }
            ));
    }, []);

    const nonParakeyCodes = bookingLockCode?.lockCodes?.filter(o => o.lockType !== lockTypes.parakey.key) ?? [];
    const isSingleCode = nonParakeyCodes.length === 1 && !nonParakeyCodes[0].name;
    const isMultipleCode = nonParakeyCodes.length > 0 && !isSingleCode;
    const hasStorageSiteLockCode = bookingLockCode?.storageSiteLockCode;
    const hasParakeyCodes = (bookingLockCode?.lockCodes?.filter(o => o.lockType === lockTypes.parakey.key).length ?? 0) > 0;
    const hasNonParakeyCodes = nonParakeyCodes.length > 0;

    const getCodeClassName = code => {
        if(code.length <= 10) {
            return classes.shortCode;
        }
        if(code.length <= 30) {
            return classes.mediumCode;
        }
        return classes.longCode;
    };

    const getParakeyAdditionalUserBookingItems = () => booking.bookingItems
        .filter(o => o.type === bookingItemTypes.service.key && getAdditionalServiceById(booking.storageGroup.storageSite.additionalServices, o.referenceId)?.type === additionalServiceTypes.parakeyAdditionalUser.key);

    const getParakeyUserEmails = () => [getContact(booking.tenantActor, contactTypes.mainEmail)]
        .concat(getParakeyAdditionalUserBookingItems().map(bookingItem => bookingItem.data.email));

    const getStorageTitle = () => (
        <>
            <Box className={classes.storageSiteTitle}>
                {strings.formatString(strings.bookingLockCodeTitle, booking.storageGroup.storageSite.title)}
            </Box>
            {
                booking.storage?.title &&
                (
                    <>
                        <Box className={classes.storageTitleLabel}>{strings.bookedStorageTitle}</Box>
                        <Box className={classes.storageTitle}>{booking.storage.title}</Box>
                    </>
                )
            }
        </>
    );

    const getBookingLockInformationElement = () => {
        return (
            <Box className={classes.wrapper}>
                <Box className={classes.logo}>
                    <img src={appContext.images.topLogo}/>
                </Box>
                {
                    (!bookingLockCode.accessControlPerformed && bookingLockCode.status !== 'ok') &&
                    (
                        <MarginBox bottom={2}>
                            <Alert severity="info">
                                <Typography>{strings.formatString(strings.bookingLockCodeShownForSuperAdmin, bookingLockCode.status)}</Typography>
                            </Alert>
                        </MarginBox>
                    )
                }
                {
                    hasParakeyCodes &&
                    (
                        <>
                            {getStorageTitle()}
                            <MarginBox top={2}>
                                <Alert severity="info">
                                    <Text html={strings.parakeyCodeInfo} />
                                </Alert>
                            </MarginBox>
                            {
                                getParakeyAdditionalUserBookingItems().length > 0 &&
                                (
                                    <MarginBox top={2}>
                                        <Typography gutterBottom>{strings.parakeyUsersForBooking}</Typography>
                                        <List dense>
                                            {
                                                getParakeyUserEmails().map(email => (
                                                    <ListItem key={email} disableGutters>
                                                        <ListItemText>
                                                            {email}
                                                        </ListItemText>
                                                    </ListItem>
                                                ))
                                            }
                                        </List>
                                    </MarginBox>
                                )
                            }
                        </>
                    )
                }
                {
                    (hasNonParakeyCodes || hasStorageSiteLockCode) && (bookingLockCode.status === 'ok' || !bookingLockCode.accessControlPerformed) &&
                    (
                        <Box>
                            {getStorageTitle()}
                            {
                                isSingleCode &&
                                (
                                    <Box className={classes.shortCode}>{bookingLockCode.lockCodes[0].code}</Box>
                                )
                            }
                            {
                                isMultipleCode &&
                                (
                                    <>
                                        {
                                            bookingLockCode.lockCodes.map((lockCode, i) => (
                                                <Box key={i} className={classes.multipleCodeContainer}>
                                                    <Box className={classes.name}>{lockCode.name}</Box>
                                                    <Box className={classes.shortCode + ' ' + classes.multipleCode}>{lockCode.code}</Box>
                                                </Box>
                                            ))
                                        }
                                    </>
                                )
                            }
                            {
                                hasStorageSiteLockCode &&
                                (
                                    <Box className={getCodeClassName(bookingLockCode.storageSiteLockCode)}>{bookingLockCode.storageSiteLockCode}</Box>
                                )
                            }
                            <Box className={classes.bookingLockCodeExpiration}>
                                <Alert severity="info">
                                    <Typography>{strings.bookingLockCodeExpiration}</Typography>
                                </Alert>
                            </Box>
                            <Box className={classes.homeScreenInstructions}>
                                <AddToHomeScreenInstructions/>
                            </Box>
                        </Box>
                    )
                }
                {
                    !hasParakeyCodes &&
                    (
                        <>
                            {
                                // not started
                                (bookingLockCode.status === 'bookingNotStarted' && bookingLockCode.accessControlPerformed) &&
                                (
                                    <Box className={classes.error}>{strings.bookingLockCodeNotStarted}</Box>
                                )
                            }
                            {
                                // expired
                                (bookingLockCode.status === 'bookingExpired' && bookingLockCode.accessControlPerformed) &&
                                (
                                    <Box className={classes.error}>{strings.bookingLockCodeExpired}</Box>
                                )
                            }
                        </>
                    )
                }
                {
                    // initial payment not received
                    (bookingLockCode.status === 'initialPaymentNotReceived' && bookingLockCode.accessControlPerformed) &&
                    (
                        <Box className={classes.error}>{strings.bookingLockCodeInitialPaymentNotReceived}</Box>
                    )
                }
                {
                    // tenant user blocked
                    (bookingLockCode.status === 'tenantUserBlocked' && bookingLockCode.accessControlPerformed) &&
                    (
                        <Box className={classes.error}>{strings.bookingLockCodeTenantUserBlocked}</Box>
                    )
                }
                {
                    // invalid time of day
                    (bookingLockCode.status === 'invalidTimeOfDay') &&
                    (
                        <Box className={classes.error}>{strings.formatString(strings.bookingLockCodeInvalidTimeOfDay, bookingLockCode.accessTimeOfDayStart, bookingLockCode.accessTimeOfDayEnd)}</Box>
                    )
                }
                {
                    (bookingLockCode.status === 'noCodeLock' || bookingLockCode.status === 'error') &&
                    (
                        <Box className={classes.error}>{strings.bookingLockCodeError}</Box>
                    )
                }
            </Box>
        );
    };

    return (
        <>
            <PageTitle>{strings.bookingLockCode}</PageTitle>
            {loading && <Loader/>}
            {bookingLockCode && getBookingLockInformationElement()}
        </>
    );
};

export default BookingLockInformationPage;
