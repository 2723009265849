import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from 'styles/util';
import routes from 'routes';
import strings from 'localization/strings';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ContentBox from 'common/ContentBox';

import OverviewSection from './OverviewSection';
import ConversationsSection from './ConversationsSection';
import ConversationDetails from './ConversationDetails';
import StorageSitesSection from './StorageSitesSection';
import CreateStorageSite from './editStorageSite/CreateStorageSite';
import EditStorageSite from './editStorageSite/EditStorageSite';
import BookingOverview from './bookings/BookingOverview';
import CapacityUtilizationTable from './bookings/CapacityUtilizationTable';
import CapacityUtilizationChartContainer from './bookings/CapacityUtilizationChartContainer';
import OwnerBookingsSection from './bookings/OwnerBookingsSection';
import OwnerBookingDetails from './bookings/OwnerBookingDetails';
import TenantBookingsSection from './bookings/TenantBookingsSection';
import TenantBookingDetails from './bookings/TenantBookingDetails';
import ReportsSection from './ReportsSection';
import SettingsSection from './SettingsSection';
import SelectActor from './SelectActor';
import CreatePrivateActor from './CreatePrivateActor';
import ActorBlocker from './ActorBlocker';
import StorageSiteLockCodeRequests from './StorageSiteLockCodeRequests';
import Breadcrumbs from 'common/Breadcrumbs';

const AccountRoutes = () => {
    const useStyles = makeStyles(({ theme, colors }) => ({
        accountContainer: {
            padding: theme.spacing(8, 3.75),
        },
        container: {
            paddingLeft: theme.spacing(3.75),
            paddingRight: theme.spacing(3.75),
        },
        buttonContainer: {
            padding: theme.spacing(5, 0),
        },
        breadcrumbs: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            backgroundColor: colors.primaryColor,
            color: colors.white,
            display: 'flex',
            gap: theme.spacing(0.625),
        },
        userInfo: {
            paddingTop: theme.spacing(0.75),
            paddingBottom: theme.spacing(0.75),
            backgroundColor: colors.lightBackgroundColor ?? colors.lightGrey,
        },
    }));
    const classes = useStyles();
    const location = useLocation();
    const authenticationContext = useSelector(state => state.authentication.context);
    const { user, selectedActor, actors } = authenticationContext;
    const unreadMessageCount = useSelector(state => state.notifications.unreadMessageCount);
    const unreadBookingCount = useSelector(state => state.notifications.unreadBookingCount);

    const isOwner = selectedActor?.isOwner;
    const isTenant = selectedActor?.tenantBookingCount > 0;

    const getLoggedInText = () => {
        if (
            actors.length > 1 &&
            selectedActor?.organizationType === 'business'
        ) {
            return `${user?.name}, ${selectedActor?.name}`;
        }
        return user?.name;
    };
    const accountRoutes = [
        {
            path: routes.account.overview,
            render: () => <OverviewSection accountRoutes={accountRoutes} />,
            visible: false, // Hide in OverviewSection
            label: strings.accountTabs.overview,
        },
        {
            path: routes.account.ownerBookingDetails,
            component: <OwnerBookingDetails />,
            visible: false,
            label: strings.accountNavigation.editBooking,
            contentWidth: 'md'
        },
        {
            path: routes.account.ownerBookings,
            component: <OwnerBookingsSection />,
            visible: isOwner,
            label: isTenant
                ? strings.accountTabs.ownerBookings
                : strings.accountTabs.bookings,
            badgeContent: unreadBookingCount,
        },
        {
            path: routes.account.tenantBookingDetails,
            component: <TenantBookingDetails />,
            visible: false,
            label: strings.accountNavigation.editBooking,
            contentWidth: 'md'
        },
        {
            path: routes.account.tenantBookings,
            component: <TenantBookingsSection />,
            visible: isTenant,
            label: isOwner
                ? strings.accountTabs.tenantBookings
                : strings.accountTabs.bookings,
            badgeContent: unreadBookingCount,
        },
        {
            path: routes.account.conversationDetails,
            component: <ConversationDetails />,
            visible: false,
            label: strings.accountNavigation.conversationDetalis,
        },
        {
            path: routes.account.conversations,
            component: <ConversationsSection />,
            visible: selectedActor !== undefined,
            badgeContent: unreadMessageCount,
            label: strings.accountNavigation.conversations,
        },
        {
            path: routes.account.createStorageSite,
            component: <CreateStorageSite />,
            visible: false,
            label: strings.accountNavigation.createSiteStep.replace(
                '{0}',
                location.pathname.split('/').pop()
            ),
            contentWidth: 'lg'
        },
        {
            path: routes.account.createStorageSiteIndex,
            component: (
                <Redirect
                    to={routes.account.createStorageSite.replace(':step', '1')}
                />
            ),
            visible: false,
            label: strings.accountNavigation.createSite,
            contentWidth: 'lg'
        },
        {
            path: routes.account.editStorageSite,
            component: <EditStorageSite />,
            visible: false,
            label: strings.accountNavigation.editSite,
            contentWidth: 'lg'
        },
        {
            path: routes.account.storageSiteBookingOverview,
            component: <BookingOverview />,
            visible: false,
            label: strings.accountNavigation.bookingOverview,
            contentWidth: 'lg'
        },
        {
            path: routes.account.storageSiteCapacityUtilizationTable,
            component: <CapacityUtilizationTable />,
            visible: false,
            label: strings.accountNavigation.bookingCapacityUtilizationTable,
            contentWidth: 'lg'
        },
        {
            path: routes.account.storageSiteCapacityUtilizationChart,
            component: <CapacityUtilizationChartContainer />,
            visible: false,
            label: strings.accountNavigation.bookingCapacityUtilizationChart,
            contentWidth: 'lg'
        },
        {
            path: routes.account.storageSiteLockCodeRequests,
            component: <StorageSiteLockCodeRequests />,
            visible: false,
            label: strings.accountNavigation.bookingLockCodeRequests,
        },
        {
            path: routes.account.storageSites,
            component: <StorageSitesSection />,
            visible: isOwner,
            label: strings.accountTabs.storageSites,
        },
        {
            path: routes.account.reports,
            component: <ReportsSection />,
            visible: isOwner,
            label: strings.accountTabs.reports,
            skipContainer: true,
        },
        {
            path: routes.account.settings,
            component: <SettingsSection />,
            visible: true,
            label: strings.accountTabs.settings,
            allowBlockedActors: true,
        },
        {
            path: routes.account.selectActor,
            component: <SelectActor />,
            visible: actors.length > 1,
            label: strings.accountTabs.selectActor,
            allowBlockedActors: true,
        },
        {
            path: routes.account.createPrivateActor,
            component: <CreatePrivateActor />,
            visible: false,
            allowBlockedActors: true,
            label: strings.accountNavigation.createPrivateActor,
        },
        {
            path: routes.admin.index,
            visible: authenticationContext.isSuperAdmin,
            label: strings.accountTabs.backoffice,
        },
        {
            path: routes.logOut,
            visible: true,
            label: strings.accountTabs.logOut,
            allowBlockedActors: true,
        },
    ];

    const getDefaultMaxWidth = route => {
        if(route.skipContainer) {
            return undefined;
        }
        if (!route.contentWidth) {
            return 'xs';
        }
        return route.contentWidth;
    };

    return (
        <>
            <AppBar position="static" className={classes.tabsContainer}>
                <Box className={`${classes.container} ${classes.breadcrumbs}`}>
                    <Breadcrumbs routes={accountRoutes} homeRoute={accountRoutes[0]} homeRouteLabel={strings.myPages}/>
                </Box>
                <Box className={`${classes.container} ${classes.userInfo}`}>
                    <Typography variant="body2">{getLoggedInText()}</Typography>
                </Box>
            </AppBar>
            <Switch>
                {
                    accountRoutes.map(route => (
                        <Route key={route.path} path={route.path} exact>
                            <ActorBlocker
                                block={
                                    selectedActor?.isBlocked &&
                                    !route.allowBlockedActors
                                }
                            >
                                {
                                    (route.render || route.component) &&
                                    (
                                        <ContentBox
                                            key={route.path}
                                            className={classes.accountContainer}
                                            themeMaxWidth={getDefaultMaxWidth(route)}
                                            widgetSpacing="40px"
                                        >
                                            {route.render ? route.render() : route.component}
                                        </ContentBox>
                                    )
                                }
                            </ActorBlocker>
                        </Route>
                    ))
                }
            </Switch>
        </>
    );
};

export default AccountRoutes;
