import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import {  formatShortDate } from 'helpers/DateHelper';
import { calculateChartData, getValueForDate, getAllColumns } from  'logic/capacityUtilizationLogic';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';
import capacityUtilizationProperties from 'enums/capacityUtilizationProperties';
import { getBusinessOrPrivateName } from 'helpers/ActorHelper';

import Box from '@material-ui/core/Box';
import CapacityUtilizationChart from './CapacityUtilizationChart';
import Form from 'form/Form';
import { TextField, Checkboxes } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { OnChange } from 'react-final-form-listeners';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(({ colors }) => ({
    chartContainer: {
        width: '100%'
    },
    compactCheckbox: {
        marginTop: 0,
        marginBottom: 0,
        paddingTop: 0,
        paddingBottom: 0
    },
    selectAll: {
        fontSize: '0.875rem'
    },
    header: {
        backgroundColor: colors.mediumLightGrey
    }
}));

const CapacityUtilization = ({ storageSites, fromDate, toDate, property, showStorageSiteList = false, showOwner = false, ownerLinkProvider, onPropertyChange }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    const [chartData, setChartData] = useState(undefined);
    const [chartWidth, setChartWidth] = useState(undefined);
    const [chartFormInitialValues, setChartFormInitialValues] = useState(undefined);
    const [selectedProperty, setSelectedProperty] = useState(property ?? capacityUtilizationProperties.bookedStorageCount.key);

    // a hack to make the select/unselect all checkbox efficient
    const [stateForm, setStateForm] = useState(undefined);
    const [preventUpdates, setPreventUpdates] = useState(false);

    const chartContainerRef = useRef();

    useEffect(() => {
        setPreventUpdates(true);
        initializeChartFormValues(storageSites);
    }, [storageSites]);

    useEffect(() => {
        if(chartFormInitialValues) {
            setPreventUpdates(false);
            setChartData(calculateChartData(storageSites, fromDate, toDate));
        }
    }, [chartFormInitialValues]);

    useEffect(() => {
        if(chartContainerRef.current) {
            setChartWidth(chartContainerRef.current.offsetWidth);
        }
    }, [chartContainerRef]);

    useEffect(() => {
        if(stateForm) {
            const formValues = stateForm.getState().values;
            stateForm.batch(() => {
                storageSites.forEach(storageSite => {
                    stateForm.change(getStorageSiteFieldName(storageSite), formValues.selectAll);
                });
            });
            recalculateChart(stateForm.getState().values);
            setStateForm(undefined);
            setPreventUpdates(false);
        }
    }, [stateForm]);

    const initializeChartFormValues = ss => {
        const iv = {
            property: selectedProperty
        };
        iv.selectAll = true;
        ss.forEach(storageSite => {
            const key = getStorageSiteFieldName(storageSite);
            if(chartFormInitialValues) {
                iv[key] = chartFormInitialValues[key];
            }
            iv[key] = iv[key] ?? true;
            iv.selectAll = iv.selectAll && iv[key];
        });
        setChartFormInitialValues(iv);
    };

    const recalculateChart = (formValues, usePreventUpdates) => {
        if(usePreventUpdates && preventUpdates) {
            return;
        }
        const filteredStorageSites = storageSites.filter(storageSite => formValues[getStorageSiteFieldName(storageSite)]);
        setChartData(calculateChartData(filteredStorageSites, fromDate, toDate));
    };

    const selectAll = form => {
        setPreventUpdates(true);
        setStateForm(form); // useEffect will take care of the rest
    };

    const getStorageSiteFieldName = storageSite => `storageSite_${storageSite.id}`;

    const handleChartFormSubmit = () => {};

    const renderOwner = storageSite => {
        const name = getBusinessOrPrivateName(storageSite.ownerActor);
        if(ownerLinkProvider) {
            return (
                <Link to={ownerLinkProvider(storageSite)}>{name}</Link>
            );
        }
        return name;
    };

    // need to use React.memo for efficient rendering
    const ChartContainerRenderer = React.memo(
        ({ form, values }) => {
            const propertyObject = capacityUtilizationProperties[values.property];
            const formatValue = (po, storageSite) => {
                const value = getValueForDate(storageSite, toDate);
                if(!value) {
                    return undefined;
                }
                return po.formatValueFromObject(value, appContext);
            };
            return (
                <>
                    <CapacityUtilizationChart
                        width={chartWidth}
                        height={400}
                        data={chartData}
                        property={propertyObject}
                    />
                    <TextField
                        select
                        name="property"
                        label={strings.capacityUtilizationPropertyLabel}
                        variant="outlined"
                    >
                        {
                            Object.values(capacityUtilizationProperties).map(item =>
                                <MenuItem key={item.key} value={item.key}>{item.title}</MenuItem>
                            )
                        }
                    </TextField>
                    <OnChange name="property">
                        {
                            newValue => {
                                setSelectedProperty(newValue);
                                if(onPropertyChange) {
                                    onPropertyChange(newValue);
                                }
                                recalculateChart({...values, property: newValue });
                            }
                        }
                    </OnChange>

                    {
                        showStorageSiteList &&
                        (
                            <Table size="small" padding="normal">
                                <TableHead className={classes.header}>
                                    <TableRow>
                                        <TableCell>
                                            <Checkboxes
                                                className={classes.compactCheckbox}
                                                name="selectAll"
                                                data={
                                                    { label: <Box component="span" className={classes.selectAll}>{strings.selectAll}</Box>, value: true }
                                                }
                                            />
                                            <OnChange name="selectAll">
                                                {newValue => selectAll(form, newValue)}
                                            </OnChange>
                                        </TableCell>
                                        <TableCell>
                                            {strings.storageSite}
                                        </TableCell>
                                        {
                                            showOwner &&
                                            (
                                                <TableCell>
                                                    {strings.owner}
                                                </TableCell>
                                            )
                                        }
                                        {
                                            getAllColumns(propertyObject).map(p => (
                                                <TableCell align="right" key={p.key}>
                                                    {strings.formatString(strings.propertyXAtDateY, p.title, formatShortDate(toDate, appContext))}
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        storageSites.map(storageSite => (
                                            <TableRow key={storageSite.id}>
                                                <TableCell>
                                                    <Checkboxes
                                                        className={classes.compactCheckbox}
                                                        name={getStorageSiteFieldName(storageSite)}
                                                        data={
                                                            { value: true }
                                                        }
                                                    />
                                                    <OnChange name={getStorageSiteFieldName(storageSite)}>
                                                        {newValue => recalculateChart({ ...values, [getStorageSiteFieldName(storageSite)]: newValue }, true)}
                                                    </OnChange>
                                                </TableCell>
                                                <TableCell>
                                                    {storageSite.title}
                                                </TableCell>
                                                {
                                                    showOwner &&
                                                    (
                                                        <TableCell>
                                                            {renderOwner(storageSite)}
                                                        </TableCell>
                                                    )
                                                }
                                                {
                                                    getAllColumns(propertyObject).map(p => (
                                                        <TableCell align="right" key={p.key}>
                                                            {formatValue(p, storageSite)}
                                                        </TableCell>
                                                    ))
                                                }
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        )
                    }
                </>
            );
        },
        // propsAreEqual
        (previousProps, nextProps) => {
            const previousValue = getFormString(previousProps.form?.getState().values);
            const nextValue = getFormString(nextProps.form?.getState().values);
            return previousValue === nextValue;
        }
    );

    ChartContainerRenderer.propTypes = {
        form: PropTypes.object.isRequired,
        values: PropTypes.object.isRequired
    };

    const getFormString = state =>  {
        const items = [state.property];
        storageSites.forEach(storageSite => {
            if(state[getStorageSiteFieldName(storageSite)]) {
                items.push(storageSite.id);
            }
        });
        return items.join(',');
    };

    return (
        <Box className={classes.chartContainer} ref={chartContainerRef}>
            {
                chartData &&
                (
                    <Form
                        component={ChartContainerRenderer}
                        initialValues={chartFormInitialValues}
                        onSubmit={handleChartFormSubmit}
                    >
                        {() => {} /* dummy func to avoid warnings */}
                    </Form>
                )
            }
        </Box>
    );
};

CapacityUtilization.propTypes = {
    storageSites: PropTypes.array.isRequired,
    fromDate: PropTypes.instanceOf(Date).isRequired,
    toDate: PropTypes.instanceOf(Date).isRequired,
    property: PropTypes.string,
    showStorageSiteList: PropTypes.bool,
    showOwner: PropTypes.bool,
    ownerLinkProvider: PropTypes.func,
    onPropertyChange: PropTypes.func
};

export default CapacityUtilization;
