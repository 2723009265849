import { createTheme } from '@material-ui/core/styles';
import { breakpoints } from './breakpoints';
import { nestedMerge } from 'helpers/ObjectHelper';
import { createBaseMuiTheme } from './baseMuiTheme';

export const createStorageMuiTheme = appContext => {
    const { colors, materialUiLocale } = appContext;

    const theme = {
        overrides: {
            MuiTypography: {
                h1: {
                    fontFamily: '"Circular Std Black", sans-serif',
                    fontWeight: '400',
                    fontSize: '3.25rem',
                    lineHeight: 0.92,
                    letterSpacing: '-2.5px',

                    [`@media only screen and (max-width: ${breakpoints.sm}px)`]: {
                        fontSize: '2.25rem',
                        letterSpacing: '-0.72px'
                    }
                },
                h2: {
                    fontFamily: '"Circular Std Black", sans-serif',
                    fontWeight: '400',
                    fontSize: '1.75rem',
                    lineHeight: 1.2,
                    letterSpacing: '-0.8px',

                    [`@media only screen and (max-width: ${breakpoints.sm}px)`]: {
                        fontSize: '1.5rem',
                        lineHeight: '1.95rem',
                        letterSpacing: '-0.48px'
                    }
                },
                h3: {
                    fontFamily: '"Circular Std Bold", sans-serif',
                    fontWeight: '400',
                    fontSize: '1.5rem',
                    lineHeight: '1.7875rem',
                    letterSpacing: '-0.44px',

                    [`@media only screen and (max-width: ${breakpoints.sm}px)`]: {
                        fontSize: '1.25rem',
                        lineHeight: '1.625rem',
                        letterSpacing: '-0.4px'
                    }
                },
                h4: {
                    fontFamily: '"Circular Std Bold", sans-serif',
                    fontWeight: '400',
                    fontSize: '1.25rem',
                    lineHeight: '1.5rem',
                    letterSpacing: '-0.4px',
                    [`@media only screen and (max-width: ${breakpoints.sm}px)`]: {
                        fontSize: '1rem',
                        lineHeight: '1.5rem',
                        letterSpacing: '-0.36px'
                    }
                },
                h5: {
                    fontFamily: '"Circular Std Bold", sans-serif',
                    fontWeight: '400',
                    fontSize: '1rem',
                    lineHeight: '1.5rem',
                    letterSpacing: '-0.16px',
                    [`@media only screen and (max-width: ${breakpoints.sm}px)`]: {
                    }
                },
                h6: {
                    fontFamily: '"Circular Std Bold", sans-serif',
                    fontWeight: '400',
                    fontSize: '0.9rem',
                    lineHeight: '1.5rem',
                    letterSpacing: '-0.16px',
                    [`@media only screen and (max-width: ${breakpoints.sm}px)`]: {
                    }
                },
                body1: {
                    fontFamily: '"Circular Std Book", sans-serif',
                    fontSize: '16px',
                    lineHeight: '24px',
                    '& strong': {
                        fontFamily: '"Circular Std Bold", sans-serif',
                    }
                },
                body2: {
                    fontFamily: '"Circular Std Book", sans-serif',
                    fontSize: '14px',
                    lineHeight: '22px'
                }
            },
            MuiAppBar: {
                colorPrimary: {
                    color: colors.darkBlue,
                    backgroundColor: colors.white
                }
            },
            MuiButton: {
                root: {
                    fontFamily: '"Circular Std Bold", sans-serif',
                    '& span': {
                        fontFamily: '"Circular Std Bold", sans-serif'
                    }
                },
                contained: {
                    padding: '22px 30px',
                    fontSize: '1rem',
                    lineHeight: '1',
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: 'none'
                    }
                },
                containedSecondary: {
                    backgroundColor: colors.lightBackgroundColor,
                    color: `${colors.textColor} !important`,
                    '&:hover': {
                        backgroundColor: colors.intermediateBackgroundColor,
                    },
                },
                outlined: {
                    padding: '22px 30px',
                    fontSize: '1rem',
                    lineHeight: '1'
                }
            },
            MuiContainer: {
                root: {
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    [`@media only screen and (min-width: ${breakpoints.sm}px)`]: {
                        paddingLeft: '30px',
                        paddingRight: '30px'
                    }
                }
            }
        }
    };

    return createTheme(nestedMerge(createBaseMuiTheme(appContext), theme), materialUiLocale);
};
