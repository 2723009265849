import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import ImageCapturer from './ImageCapturer';

const useStyles = makeStyles(({ theme }) => ({
    dialogTitle: {
        textAlign: 'center'
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        alignItems: 'center',
        paddingBottom: 0
    }
}));

const AttachRoundReportImageDialog = ({ open, roundReportStorageItem, onOk, onCancel }) => {
    const classes = useStyles();

    const handleCapture = ({ imageData }) => {
        onOk({ occupiedInReality: true, imageData });
    };

    const handleClose = () => {
        onCancel();
    };

    return (
        <Dialog fullWidth open={open} onClose={handleClose}>
            <DialogTitle disableTypography>
                <Typography variant="h5" className={classes.dialogTitle}>
                    {roundReportStorageItem.storage.title}
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography variant="body1">
                    {strings.pages.roundReport.captureImageOfOccupiedStorage}
                </Typography>
                <ImageCapturer
                    onCapture={handleCapture}
                />
            </DialogContent>
        </Dialog>
    );
};

AttachRoundReportImageDialog.propTypes = {
    open: PropTypes.bool,
    roundReportStorageItem: PropTypes.object.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default AttachRoundReportImageDialog;
