import React from 'react';
import dateRuleTypes from 'enums/dateRuleTypes';
import openingModes from 'enums/openingModes';
import { parseIsoDate, formatShortDate, formatTime, formatDayOfWeekLongDate, formatIsoDate } from 'helpers/DateHelper';
import strings from 'localization/strings';
import { differenceInCalendarDays, startOfDay } from 'date-fns';

import ShortDate from 'common/ShortDate';
import TimeSpan from 'common/TimeSpan';

export const getOpeningTimeSpanRules = formArray => formArray.map(formOpeningTimeSpanRule => ({
    type: formOpeningTimeSpanRule.type,
    mode: formOpeningTimeSpanRule.mode,
    restrictedToAction: formOpeningTimeSpanRule.mode === openingModes.closed.key || formOpeningTimeSpanRule.restrictedToAction === 'all'
        ? undefined
        : formOpeningTimeSpanRule.restrictedToAction,
    fromTime: formOpeningTimeSpanRule.mode === openingModes.open.key
        ? formOpeningTimeSpanRule.fromTime
        : undefined,
    toTime: formOpeningTimeSpanRule.mode === openingModes.open.key
        ? formOpeningTimeSpanRule.toTime
        : undefined,
    data: getOpeningTimeSpanRuleData(formOpeningTimeSpanRule)
}));

const getOpeningTimeSpanRuleData = openingTimeSpanRule => {
    switch(openingTimeSpanRule.type) {
        case dateRuleTypes.dayOfWeekPattern.key:
            return {
                ...openingTimeSpanRule.dayOfWeekPattern,
                from: formatIsoDate(openingTimeSpanRule.dayOfWeekPattern.from),
                to: formatIsoDate(openingTimeSpanRule.dayOfWeekPattern.to)
            };
        case dateRuleTypes.dateInterval.key:
            return {
                from: formatIsoDate(openingTimeSpanRule.dateInterval.from),
                to: formatIsoDate(openingTimeSpanRule.dateInterval.to)
            };
        case dateRuleTypes.dates.key:
            return {
                dates: openingTimeSpanRule.dates.dates.map(formatIsoDate)
            };
        default:
            return undefined;
    }
};

export const createOpeningTimeSpanRulesFormData = openingTimeSpanRules => openingTimeSpanRules.map(openingTimeSpanRule => {
    const formOpeningTimeSpanRule = createDefaultOpeningTimeSpanRule();
    formOpeningTimeSpanRule.type = openingTimeSpanRule.type;
    formOpeningTimeSpanRule.mode = openingTimeSpanRule.mode;
    formOpeningTimeSpanRule.restrictedToAction = openingTimeSpanRule.restrictedToAction ?? 'all';
    formOpeningTimeSpanRule.fromTime = openingTimeSpanRule.fromTime;
    formOpeningTimeSpanRule.toTime = openingTimeSpanRule.toTime;
    populateFormOpeningTimeSpanRuleData(formOpeningTimeSpanRule, openingTimeSpanRule);
    return formOpeningTimeSpanRule;
});

const populateFormOpeningTimeSpanRuleData = (formOpeningTimeSpanRule, openingTimeSpanRule) => {
    switch(openingTimeSpanRule.type) {
        case dateRuleTypes.dayOfWeekPattern.key:
            formOpeningTimeSpanRule.dayOfWeekPattern = {
                ...openingTimeSpanRule.data,
                from: parseIsoDate(openingTimeSpanRule.data.from),
                to: parseIsoDate(openingTimeSpanRule.data.to)
            };
            break;
        case dateRuleTypes.dateInterval.key:
            formOpeningTimeSpanRule.dateInterval = {
                from: parseIsoDate(openingTimeSpanRule.data.from),
                to: parseIsoDate(openingTimeSpanRule.data.to)
            };
            break;
        case dateRuleTypes.dates.key:
            formOpeningTimeSpanRule.dates = {
                dates: openingTimeSpanRule.data.dates.map(parseIsoDate)
            };
            break;
        default:
            break;
    }
};

export const createDefaultOpeningTimeSpanRule = () => ({
    type: dateRuleTypes.all.key,
    mode: openingModes.open.key,
    restrictedToAction: 'all',
    fromTime: undefined,
    toTime: undefined,
    dayOfWeekPattern: {
        daysOfWeek: [],
        from: undefined,
        to: undefined,
        weekInterval: 1
    },
    dates: {
        dates: [undefined]
    },
    dateInterval: {
        from: undefined,
        to: undefined
    }
});

export const parseOpeningTimeSpan = ots => ({ ...ots, date: parseIsoDate(ots.date) });

export const formatTimeSpan = (openingTimeSpan, { includeMode = false, appContext }) => {
    const prefix = includeMode
        ? strings.openingModesLC[openingTimeSpan.mode]
        : '';
    if((!openingTimeSpan.fromTime && !openingTimeSpan.toTime) || openingTimeSpan.mode === openingModes.closed.key) {
        return prefix;
    }
    return <>{prefix} {formatTime(openingTimeSpan.fromTime, appContext)} &ndash; {formatTime(openingTimeSpan.toTime, appContext)}</>;
};

const hasFromOrToTime = openingTimeSpan => openingTimeSpan?.fromTime || openingTimeSpan?.toTime;

export const formatDateInterval = ({ from, to }) => {
    return <><ShortDate value={from} /> &ndash; <ShortDate value={to} /></>;
};

export const getWeekIntervalTitle = weekInterval => {
    switch(weekInterval) {
        case 1: return strings.everyWeek;
        case 2: return strings.everySecondWeek;
        case 3: return strings.everyThirdWeek;
        case 4: return strings.everyFourthWeek;
        default: return '';
    }
};

export const getComingOpeningTimeSpanDates = (openingTimeSpanRule, ruleData, appContext) => {
    if(!openingTimeSpanRule.comingOpeningTimeSpans) {
        return undefined;
    }

    let count = 2;
    if(ruleData?.daysOfWeek) {
        count = 2 * ruleData.daysOfWeek.length;
    }

    const datesString = openingTimeSpanRule.comingOpeningTimeSpans
        .slice(0, count)
        .map(o => formatShortDate(o.date, appContext))
        .join(', ');

    const from = parseIsoDate(ruleData.from) > startOfDay(new Date())
        ? <>{strings.fromAbbreviated} <ShortDate value={ruleData.from}/></>
        : undefined;
    const to = ruleData.to
        ? <>{strings.untilAbbreviated} <ShortDate value={ruleData.to}/></>
        : undefined;
    let timeString = '';
    if(from && to) {
        timeString = <>{from} {to}</>;
    } else if (from) {
        timeString = from;
    } else if (to) {
        timeString = to;
    }

    return datesString
        ? <>{datesString}, &hellip;{timeString}</>
        : timeString;
};

export const formatDateAndTimeSpan = (date, openingTimeSpan, appContext, dateFormatter = formatDayOfWeekLongDate) =>
(
    <>
        {
            dateFormatter(date, appContext)
        }
        {
            hasFromOrToTime(openingTimeSpan) &&
            (
                <>, {strings.openingModesLC.open} <TimeSpan value={openingTimeSpan}/></>
            )
        }
    </>
);

const createDateDistanceSorter = referenceDate => (a, b) => Math.abs(differenceInCalendarDays(a.date, referenceDate)) - Math.abs(differenceInCalendarDays(b.date, referenceDate));

const dateSorter = (a, b) => a.date.getTime() - b.date.getTime();

export const stripDateCandidates = (dateCandidates, requestedDate, maxCount = 10) => {
    if(dateCandidates.length <= maxCount) {
        return dateCandidates;
    }
    let strippedDateCandidates = [...dateCandidates];
    strippedDateCandidates.sort(createDateDistanceSorter(requestedDate));
    strippedDateCandidates = strippedDateCandidates.slice(0, maxCount);
    strippedDateCandidates.sort(dateSorter);
    return strippedDateCandidates;
};

