import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { useSelector } from 'react-redux';
import strings from 'localization/strings';
import { formatAmount, formatNumber } from 'helpers/StringHelper';
import organizationTypes from 'enums/organizationTypes';
import storageSiteFacilities from 'enums/storageSiteFacilities';
import { makeStyles } from 'styles/util';
import { getResizedImageUrl } from 'helpers/ImageHelper';
import { getAreaAndPrice } from 'helpers/StorageAreaCalculator';
import { getAreaExplanation } from 'helpers/StorageSiteHelper';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import DefaultImage from 'assets/images/storage365_object_defaultplacementimage_list.jpg';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import PlaceIcon from 'assets/icons/place.svg';
import AreaAndPrice from 'common/AreaAndPrice';

const StorageSiteListItem = ({ storageSite, storageGroupCategory, storageSiteLinkCreator, relevantStorageSites, showDistance, showStreetAddress = false, showOrganizationType = false, forceSmall = false }) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs')) || forceSmall;
    const isTablet = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const polygonRef = useRef();
    const [polygonClipPath, setPolygonClipPath] = useState(71.625);

    const useStyles = makeStyles(({ theme, colors, fonts, styles }) => ({
        link: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            color: 'inherit',
            textDecoration: 'none',
            '&:visited': {
                color: 'inherit'
            },
            '&:active': {
                color: 'inherit'
            },
            maxWidth: isMobile ? '400px' : 'inherit',
            minWidth: 'inherit'
        },
        outer: {
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            borderRadius: `${styles.buttonBorderRadius}px`,
            overflow: 'hidden',
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
            width: '100%'
        },
        attentionWrapper: {
            display: relevantStorageSites ? 'none' : 'block',
            position: 'absolute',
            bottom: 0,
            left: 0,
            backgroundColor: colors.attentionBackground,
            clipPath: `polygon( 71.625% 0%, 0% 22.829%, 0% 100%, 100% 100%, ${polygonClipPath}% 0% )`
        },
        attentionText: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            padding: theme.spacing(2.25, 6.25, 1.25, 2),
            color: colors.attentionForeground,
            fontFamily: fonts.bold
        },
        imageWrapper: {
            position: 'relative',
            flex: '0 0 auto'
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            flex: '0 0 auto',
            maxWidth: isMobile ? 'none' : '363px'
        },
        info: {
            flex: '1 1 auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: theme.spacing(3.5, 2.5),
            backgroundColor: colors.white
        },
        title: {
            flex: '0 0 auto',
        },
        placeLogoContainer: {
            flex: '1 1 auto',
            paddingTop: theme.spacing(3),
            display: 'flex',
            gap: theme.spacing(2),
            alignItems: 'center',
            [theme.breakpoints.down('md')]: {
                flexWrap: 'wrap'
            }
        },
        placeContainer: {
            flex: '1 1 auto',
            display: 'flex',
            gap: theme.spacing(2)
        },
        logotype: {
            flex: '0 0 auto',
            height: '48px'
        },
        storageSiteInfoList: {
            flex: '0 0 auto',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            margin: 0,
            padding: 0
        },
        storageSiteInfoListItem: {
            display: 'block',
            fontSize: '14px',
            '&:not(:first-child):before': {
                content: '"\\2022"',
                padding: theme.spacing(0, 0.5)
            }
        },
        labels: {
            flex: '0 0 auto',
            margin: theme.spacing(isMobile ? 0 : 2.5, 2.5, 2.5, 2.5),
            width: isMobile ? 'auto' : (isTablet ? '160px' : '200px'),
            display: 'flex',
            flexDirection: 'column'
        },
    }),
    () => ({
        storage: {
            labels: {
                margin: '0'
            }
        }
    }));

    const classes = useStyles();

    const refs = useRef([]);
    const ulRef = useRef();
    const facilities = (storageSite.facilities || []).map(key => storageSiteFacilities[key]);

    const [numberOfShownFacilities, setNumberOfShownFacilities] = useState(facilities.length);

    const { appContext } = useAppContext();

    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor } = authenticationContext;

    useEffect(() => {
        let elementCount = 0;
        let totalWidth = 80;
        for (const elem of refs.current) {
            if (ulRef.current?.offsetWidth > totalWidth + elem.offsetWidth) {
                totalWidth += elem.offsetWidth;
                elementCount++;
            } else {
                break;
            }
        }

        // Standard is 71.625% based on a width of the polygon at 14 characters
        if (polygonRef.current) {
            const clipPathValue = Math.round(((polygonRef.current.textContent.length - 14) / 1.5 + 71.625) * 1000) / 1000;
            setPolygonClipPath(clipPathValue);
        }

        setNumberOfShownFacilities(elementCount);
    }, [refs]);

    const { areaString, pricePrefix, displayPrice, currency } = getAreaAndPrice(storageSite, storageGroupCategory, appContext, selectedActor?.organizationType);

    const numberOfHiddenFacilities = facilities.length - numberOfShownFacilities;

    const cityAndDistance =
        (showStreetAddress ? storageSite.address + ', ' : '') +
        storageSite.city +
        (
            showDistance && !showDistance && storageSite.distance !== undefined
                ? ' - ' + strings.formatString(strings.xKmFromSelectedPlace, formatNumber(storageSite.distance / 1000, appContext, { numberOfDecimals: 0 }))
                : ''
        );

    const imageWidth = 363;
    const imageHeight = 233;

    return (
        <Link to={storageSiteLinkCreator(storageSite)} className={classes.link + ' ' + (relevantStorageSites ? classes.relevantStorageSite : '')}>
            <Box className={classes.outer}>
                <Box className={classes.imageWrapper}>
                    <img
                        className={classes.image}
                        src={getResizedImageUrl(storageSite.images[0] ? storageSite.images[0].mediaUrl : DefaultImage, { width: imageWidth, height: imageHeight, mode: 'crop' })}
                    />
                    {
                        storageSite.attentionText &&
                        (
                            <Box className={classes.attentionWrapper}>
                                <Typography ref={polygonRef} variant="body2" className={classes.attentionText}>{storageSite.attentionText}</Typography>
                            </Box>
                        )
                    }
                </Box>
                <Box className={classes.info}>
                    <Typography variant="h3" className={classes.title}>
                        {storageSite.title}
                    </Typography>
                    <Box className={classes.placeLogoContainer}>
                        <Box className={classes.placeContainer}>
                            <img src={PlaceIcon}/>
                            <strong>{cityAndDistance}</strong>
                            {
                                showOrganizationType &&
                                (
                                    <>
                                        <CircleIcon className={classes.circleIcon} />
                                        {organizationTypes[storageSite.ownerType].rentingOutAs}
                                    </>
                                )
                            }
                        </Box>
                        {
                            storageSite.ownerActor && storageSite.ownerActor.logotypeUrl &&
                            (
                                <img
                                    src={getResizedImageUrl(storageSite.ownerActor.logotypeUrl, { height: 48 })}
                                    className={classes.logotype}
                                />
                            )
                        }
                    </Box>
                    <ul ref={ulRef} className={classes.storageSiteInfoList}>
                        {
                            facilities.slice(0, numberOfShownFacilities).map((s, index) => (
                                <li key={s.key} ref={el => { refs.current[index] = el; }} className={classes.storageSiteInfoListItem}>
                                    {s.title}
                                </li>
                            ))
                        }
                        {
                            numberOfHiddenFacilities > 0 &&
                            (
                                <li ref={el => { refs.current[(refs.current || []).length] = el; }} className={classes.storageSiteInfoListItem}>
                                    <Badge badgeContent={numberOfHiddenFacilities + 1} max={numberOfHiddenFacilities} color="primary" />
                                </li>
                            )
                        }
                    </ul>
                </Box>
                <Box className={classes.labels}>
                    <AreaAndPrice
                        areaTitle={getAreaExplanation(storageSite)}
                        areaText={areaString}
                        priceTitle={pricePrefix}
                        priceText={`${formatAmount(displayPrice, currency, appContext)}/${strings.monthAbbreviated}`}
                        backgroundColor={appContext.colors.secondaryColor}
                        relevantStorageSites={relevantStorageSites}
                        forceSmall={forceSmall}
                    />
                </Box>
            </Box>
        </Link>
    );
};

StorageSiteListItem.propTypes = {
    storageSite: PropTypes.object.isRequired,
    storageGroupCategory: PropTypes.object,
    showDistance: PropTypes.bool,
    storageSiteLinkCreator: PropTypes.func,
    relevantStorageSites: PropTypes.bool,
    showStreetAddress: PropTypes.bool,
    showOrganizationType: PropTypes.bool,
    forceSmall: PropTypes.bool
};

export default StorageSiteListItem;
