import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { calculateSum } from 'helpers/ArrayHelper';

import Percentage from 'common/Percentage';
import Amount from 'common/Amount';

const useStyles = makeStyles(() => ({
    caption: {
        '& td': {
            paddingBottom: '0.5em'
        }
    },
    smallTitle: {
        fontWeight: 'bold',
        fontSize: '12px'
    },
    boldLine: {
        '& td': {
            fontWeight: 'bold'
        }
    },
    smallBody: {
        '& td': {
            fontSize: '12px'
        }
    },
    right: {
        textAlign: 'right'
    }
}));

const VatSpecification = ({ purchase, isOwner }) => {
    const classes = useStyles();

    const vatItems = isOwner
        ? purchase.vatItemsExcludingFullCommissionRate
        : purchase.vatItems;


    if (vatItems.length === 0) {
        return null;
    }

    return (
        <>
            <tr>
                <td colSpan={4}><hr /></td>
            </tr>

            <tr className={classes.caption}>
                <td className={classes.smallTitle}>{strings.paymentDocuments.vatSpecification}</td>
            </tr>

            <tr className={classes.boldLine + ' ' + classes.smallBody}>
                <td colSpan={2}>{strings.paymentDocuments.vatRate}</td>
                <td className={classes.right}>{strings.paymentDocuments.basis}</td>
                <td className={classes.right}>{strings.vat}</td>
            </tr>

            {
                vatItems.map((vatItem, i) => (
                    <tr key={i} className={classes.smallBody}>
                        <td colSpan={2}><Percentage value={vatItem.vatRate}/></td>
                        <td className={classes.right}><Amount value={vatItem.amountExcludingVat} currency={purchase.currency} displayPriceRounding={false} /></td>
                        <td className={classes.right}><Amount value={vatItem.vat} currency={purchase.currency} displayPriceRounding={false} /></td>
                    </tr>
                ))
            }

            <tr className={classes.boldLine + ' ' + classes.smallBody}>
                <td colSpan={2}>{strings.sum}</td>
                <td className={classes.right}><Amount value={calculateSum(vatItems, o => o.amountExcludingVat)} currency={purchase.currency} displayPriceRounding={false} /></td>
                <td className={classes.right}><Amount value={calculateSum(vatItems, o => o.vat)} currency={purchase.currency} displayPriceRounding={false} /></td>
            </tr>
        </>
    );
};

VatSpecification.propTypes = {
    purchase: PropTypes.object.isRequired,
    isOwner: PropTypes.bool
};

export default VatSpecification;
