import React from 'react';
import PropTypes from 'prop-types';
import { useForm, useField } from 'react-final-form';
import { makeStyles } from 'styles/util';
import { mustBeNumber, required } from 'form/validation';
import bookingStatuses from 'enums/bookingStatuses';
import additionalServiceStatuses from 'enums/additionalServiceStatuses';
import paymentRecurrences from 'enums/paymentRecurrences';
import { createEndAdornment } from 'helpers/FormHelper';
// import { getShortDateFormat } from 'helpers/DateHelper';
import { getAdditionalServiceConfiguration } from 'helpers/AdditionalServiceHelper';

import Box from '@material-ui/core/Box';
import { TextField, showErrorOnBlur } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';
import { OnChange } from 'react-final-form-listeners';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
// import NullableDatePicker from 'form/NullableDatePicker';

const useStyles = makeStyles(({ theme, colors }) => ({
    container: {
        border: `2px solid ${colors.lightGreyBorder}`,
        borderRadius: '6px',
        padding: theme.spacing(2, 2, 0, 2),
        marginBottom: theme.spacing(2),
        '& div, & label, & input, & p': {
            fontSize: '0.75rem'
        }
    },
    firstLineContainer: {
        display: 'flex',
        gap: theme.spacing(2)
    },
    secondLineContainer: {
        display: 'flex',
        gap: theme.spacing(2)
    },
    additionalServiceContainer: {
        flex: '1 1 auto',
    },
    removeButtonContainer: {
        flex: '0 0 auto',
        alignSelf: 'center',
        textAlign: 'end'
    },
    amountContainer: {
        flex: '1 1 17%',
    },
    vatRateContainer: {
        flex: '1 1 12%',
    },
    commissionRateContainer: {
        flex: '1 1 12%',
    },
    startDateContainer: {
        flex: '1 1 12%',
    },
    endDateContainer: {
        flex: '1 1 12%',
    },
    dataContainer: {
        flex: '1 1 20%',
    },
    invisible: {
        visibility: 'hidden'
    }
}));

const BookingItemEditor = ({ fieldNamePrefix, booking, additionalServices, itemCreator, onRemove }) => {
    const classes = useStyles();
    const form = useForm();
    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;
    const item = useField(fieldNamePrefix).input.value;
    const selectedAdditionalService = additionalServices.find(additionalService => additionalService.id === item.referenceId);
    const selectedAdditionalServiceConfiguration = getAdditionalServiceConfiguration(selectedAdditionalService);

    const beforePurchase =
        booking.bookingStatus === bookingStatuses.requested.key ||
        booking.bookingStatus === bookingStatuses.confirmed.key;

    const disabled =
        !(
            booking.bookingStatus === bookingStatuses.requested.key ||
            booking.bookingStatus === bookingStatuses.confirmed.key ||
            item.paymentRecurrence === paymentRecurrences.perMonth.key ||
            (item.paymentRecurrence === paymentRecurrences.oneTime.key && !booking.subscriptionBooking) ||
            item.paymentRecurrence === undefined
        );
    const priceLabel = item.paymentRecurrence === paymentRecurrences.perMonth.key
        ? 'Monthly price'
        : 'One-time price';

    const createListItem = additionalService => ({
        value: additionalService.id,
        title: additionalService.description,
        status: additionalService.status
    });

    const availableAdditionalServices = additionalServices
        .filter(additionalService => !booking.subscriptionBooking || beforePurchase || additionalService.paymentRecurrence === paymentRecurrences.perMonth.key);

    const additionalServiceListItems = (disabled ? additionalServices : availableAdditionalServices)
        .map(createListItem);

    const handleAdditionalServiceChange = newValue => {
        const newAdditionalService = additionalServices.find(additionalService => additionalService.id === newValue);
        const newItem = itemCreator(newAdditionalService);
        ['referenceId', 'amount', 'vatRate', 'commissionRate', 'paymentRecurrence', 'startDate', 'endDate', 'data']
            .forEach(property => form.mutators.changeValue({ name: getName(property), value: newItem[property] }));
    };

    return (
        <Box className={classes.container}>
            <Box className={classes.firstLineContainer}>
                <Box className={classes.additionalServiceContainer}>
                    {
                        item.isPredefined &&
                        (
                            <>
                                <TextField
                                    select
                                    size="small"
                                    name={getName('referenceId')}
                                    label="Additional service"
                                    variant="outlined"
                                    disabled={disabled}
                                >
                                    {
                                        additionalServiceListItems
                                            .map(additionalService => (
                                                <MenuItem key={additionalService.value} value={additionalService.value}>
                                                    {additionalService.title}
                                                    {
                                                        additionalService.status === additionalServiceStatuses.deleted.key && ' - deleted'
                                                    }
                                                </MenuItem>
                                            ))
                                    }
                                </TextField>
                                <OnChange name={getName('referenceId')}>
                                    {handleAdditionalServiceChange}
                                </OnChange>
                            </>
                        )
                    }
                    {
                        !item.isPredefined &&
                        (
                            <TextField
                                name={getName('description')}
                                size="small"
                                label="Additional service"
                                variant="outlined"
                                disabled={disabled}
                                required
                                fieldProps={{ validate: required }}
                                placeholder="Enter service description"
                            />
                        )
                    }
                </Box>
                {
                    !disabled && item.id === undefined &&
                    (
                        <Box className={classes.removeButtonContainer}>
                            <IconButton
                                onClick={onRemove}
                            >
                                <DeleteOutlineOutlinedIcon/>
                            </IconButton>
                        </Box>
                    )
                }
            </Box>

            <Box className={classes.secondLineContainer}>
                <Box className={classes.amountContainer}>
                    <TextField
                        name={getName('amount')}
                        size="small"
                        label={priceLabel}
                        helperText="Excluding VAT."
                        variant="outlined"
                        showError={showErrorOnBlur}
                        disabled={disabled}
                        required
                        fieldProps={{ validate: mustBeNumber }}
                        InputProps={createEndAdornment(booking.currency)}
                    />
                </Box>

                <Box className={classes.vatRateContainer}>
                    <TextField
                        name={getName('vatRate')}
                        size="small"
                        label="VAT rate"
                        variant="outlined"
                        showError={showErrorOnBlur}
                        disabled={disabled}
                        required
                        fieldProps={{ validate: mustBeNumber }}
                        InputProps={createEndAdornment('%')}
                    />
                </Box>

                <Box className={classes.commissionRateContainer}>
                    <TextField
                        name={getName('commissionRate')}
                        size="small"
                        label="Commission rate"
                        variant="outlined"
                        showError={showErrorOnBlur}
                        disabled={disabled}
                        required
                        fieldProps={{ validate: mustBeNumber }}
                        InputProps={createEndAdornment('%')}
                    />
                </Box>

                {/*
                    <Box className={classes.startDateContainer}>
                        <NullableDatePicker
                            name={getName('startDate')}
                            size="small"
                            label="Start date"
                            nullString="Booking start"
                            nullButtonLabel="From booking start"
                            formatFunc={getShortDateFormat}
                            inputVariant="outlined"
                            showError={showErrorOnBlur}
                            disabled={disabled}
                        />
                    </Box>

                    <Box className={classes.endDateContainer}>
                        <NullableDatePicker
                            name={getName('endDate')}
                            size="small"
                            label="End date"
                            nullString="Booking end"
                            nullButtonLabel="To booking end"
                            formatFunc={getShortDateFormat}
                            inputVariant="outlined"
                            showError={showErrorOnBlur}
                            disabled={disabled}
                        />
                    </Box>
                */}

                <Box className={classes.dataContainer}>
                    <TextField
                        name={getName('data')}
                        size="small"
                        variant="outlined"
                        className={selectedAdditionalServiceConfiguration?.showDataTextbox ? undefined : classes.invisible}
                        disabled={disabled}
                        {...selectedAdditionalServiceConfiguration?.dataTextboxProps}
                        helperText={undefined}
                    />
                </Box>
            </Box>
        </Box>
    );
};

BookingItemEditor.propTypes = {
    fieldNamePrefix: PropTypes.string.isRequired,
    booking: PropTypes.object.isRequired,
    additionalServices: PropTypes.array.isRequired,
    itemCreator: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired
};

export default BookingItemEditor;
