import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { useAppContext } from 'context/AppContext';
import storageSiteStatuses from 'enums/storageSiteStatuses';
import routes from 'routes';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CardLink from 'common/CardLink';
import StorageSiteImage from './StorageSiteImage';
import StatusChip from 'common/StatusChip';
import IconPrefix from 'common/IconPrefix';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import ChevronRight from 'common/ChevronRight';

const useStyles = makeStyles(({ colors }) => ({
    location: {
        marginLeft: '-4px',
        justifyContent: 'start'
    },
    information: {
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        '& > :first-child': {
            flex: '1 1 auto'
        },
        '& > :last-child': {
            flex: '0 0 auto',
        }
    },
    image: {
        display: 'flex',
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'cover'
        }
    },
    chevronRight: {
        fill: colors.secondaryColor
    }
}));

const OwnerStorageSite = ({ storageSite }) => {
    const classes = useStyles();
    const { appContext } = useAppContext(appContext);
    const imageUrl = storageSite.images[0]
        ? storageSite.images[0].mediaUrl
        : undefined;

    const editStorageSiteUrl = routes.account.editStorageSite.replace(':storageSiteId', storageSite.id);
    const storageSiteStatusTitle = storageSiteStatuses[storageSite.status].title;
    const location = storageSite.city
        ? (
            <IconPrefix className={classes.location} icon={<LocationOnIcon htmlColor={appContext.colors.icon}/>}>
                {storageSite.city}
            </IconPrefix>
        )
        : undefined;

    return (
        <CardLink to={editStorageSiteUrl}>
            <Box className={classes.image}>
                <StorageSiteImage storageSite={storageSite} imageUrl={imageUrl} width={385} height={182} />
            </Box>
            <Box className={classes.information}>
                <Typography variant="h5" component="p">
                    {storageSite.title}
                </Typography>
                <ChevronRight className={classes.chevronRight} />
            </Box>
            {location && <Box>{location}</Box>}
            <StatusChip text={storageSiteStatusTitle} status={storageSiteStatuses[storageSite.status].chipType} />
        </CardLink>
    );
};

OwnerStorageSite.propTypes = {
    storageSite: PropTypes.object.isRequired
};

export default OwnerStorageSite;
