import { formatUtcIsoDateTime, formatIsoDate } from 'helpers/DateHelper';
import { convertToNumber } from 'helpers/StringHelper';
import { createBookingItem } from 'helpers/BookingHelper';
import cancellationReasons from 'enums/cancellationReasons';
import { startOfDay } from 'date-fns';

export const createUpdateBookingRequest = (formValues, additionalServices) => ({
    startDate: formatIsoDate(formValues.startDate),
    endDate: formatIsoDate(formValues.endDate),
    actualEndDate: formatIsoDate(formValues.actualEndDate),
    confirmationDeadline: formatUtcIsoDateTime(formValues.confirmationDeadline),
    initialPaymentDeadline: formatUtcIsoDateTime(formValues.initialPaymentDeadline),
    flagged: formValues.flagged,
    cancellationReason: formValues.cancellationReason,
    cancellationReasonText: formValues.cancellationReason === cancellationReasons.other.key
        ? formValues.cancellationReasonText
        : undefined,
    subscriptionPeriodStoragePrice: convertToNumber(formValues.subscriptionPeriodStoragePrice),
    storageVatRate: convertToNumber(formValues.storageVatRate) / 100,
    bookingItems: formValues.bookingItems.map(formBookingItem => createBookingItem(formBookingItem, additionalServices)),
    storageGroupId: formValues.storageGroupId,
    storageId: formValues.storageId,
    effectDate: formatIsoDate(formValues.effectDate) ?? formatIsoDate(startOfDay(new Date())),
    tenantActorId: formValues.tenantActorId,
    bookingStatus: formValues.bookingStatus,
    tenantReferenceText: formValues.tenantReferenceText
        ? formValues.tenantReferenceText
        : undefined,
    createPostInvoiceForExtendedTimePeriod: formValues.createPostInvoiceForExtendedTimePeriod
});
