import React from 'react';
import { calculateDaysInPeriod } from 'helpers/PriceCalculator';
import strings from 'localization/strings';
import rentalTypes from 'enums/rentalTypes';
import { calculateArea } from 'helpers/StorageAreaCalculator';
import { convertToNumber, formatArea, formatNumber } from 'helpers/StringHelper';
import { onlySubscriptionBookingsAllowed, onlyPeriodBookingsAllowed } from 'helpers/StorageSiteHelper';
import { parseIsoDate } from 'helpers/DateHelper';
import storageGroupCategories from 'enums/storageGroupCategories';
import vatModes from 'enums/vatModes';
import { max, isBefore, isAfter } from 'date-fns';
import goodsTypes from 'enums/goodsTypes';

export const maxDateString = '2099-12-31';
const maxDate = parseIsoDate(maxDateString);

export const getOrderedStorageGroupCategories = (categoryKey, appContext) =>
    appContext.storageGroupCategories.filter(o => o.key === categoryKey)
        .concat(appContext.storageGroupCategories.filter(o => o.key !== categoryKey));

export const getOrderedStorageGroups = (storageGroups, categoryKey, appContext) => {
    const result = [];
    getOrderedStorageGroupCategories(categoryKey, appContext).forEach(category => {
        Array.prototype.push.apply(result, storageGroups.filter(o => o.category === category.key));
    });
    return result;
};

export const getInitialBookingFormValues = (storageSite, requestedStartDate, requestedEndDate, categories, selectedCategory, selectedStorageGroupId, appContext, isPreview) => {
    // returns { categories: { vehicle: { storageGroupId, width, height }, storeHouse: { storageGroupId, width, height }, buildingsAndLand }: { storageGroupId, width, height }, requestedStartDate, requestedEndDate, tenantOrganizationType }
    const orderedStorageGroupCategories = getOrderedStorageGroupCategories(selectedCategory?.key, appContext);
    const orderedStorageGroups = getOrderedStorageGroups(storageSite.storageGroups, selectedCategory?.key, appContext);
    // if only subscriptions are allowed, no end date sholuld be set
    const sanitizedRequestedEndDate = onlySubscriptionBookingsAllowed(storageSite)
        ? undefined
        : requestedEndDate;

    const initialFormValues = {
        categories: {}, // will eventually have all categories as keys, see below
        requestedStartDate,
        requestedEndDate: sanitizedRequestedEndDate,
        tenantOrganizationType: undefined,
        storageGroupCategory: undefined
    };
    const firstBookableStorageGroup = selectedCategory?.key === orderedStorageGroups[0].category
        ? orderedStorageGroups[0] // force select the first storage group even if it is not bookable
        : orderedStorageGroups.find(o => storageGroupIsBookable(o, requestedStartDate, sanitizedRequestedEndDate));

    orderedStorageGroupCategories.forEach(o => {
        const categoryObject = {};
        if(selectedStorageGroupId && orderedStorageGroups.find(sg => sg.id === selectedStorageGroupId)) {
            // we have a storage group thet should be preselected, probably because an unauthenticated user selected a storage group, was requested to log in, and then returned from the login page
            categoryObject.storageGroupId = storageSite.storageGroups.find(sg => sg.category === o.key && sg.id === selectedStorageGroupId)
                ? selectedStorageGroupId
                : -1;
        } else {
            // select the first bookable storage group, if there is any
            categoryObject.storageGroupId = isPreview || !firstBookableStorageGroup || firstBookableStorageGroup.category !== o.key || !storageGroupIsBookable(firstBookableStorageGroup, requestedStartDate, sanitizedRequestedEndDate)
                ? -1
                : firstBookableStorageGroup.id;
        }
        if(categories && categories[o.key]) {
            categoryObject.width = formatNumber(categories[o.key].width, appContext, { maxNumberOfDecimals: 1 });
            categoryObject.length = formatNumber(categories[o.key].length, appContext, { maxNumberOfDecimals: 1 });
        }
        if(categoryObject.storageGroupId !== -1) {
            initialFormValues.storageGroupCategory = o.key;
        }
        initialFormValues.categories[o.key] = categoryObject;
    });

    // None selected, select the first one
    if (!initialFormValues.storageGroupCategory) {
        initialFormValues.storageGroupCategory = isPreview
            ? orderedStorageGroups[0].category
            : Object.keys(initialFormValues.categories)[0];
    }

    return initialFormValues;
};

export const getSelectedStorageGroupId = formValues => {
    let selectedValue = undefined;
    if(formValues.categories) {
        Object.values(formValues.categories).forEach(category => {
            const value = convertToNumber(category.storageGroupId);
            if(value !== -1) {
                selectedValue = value;
            }
        });
    }
    return selectedValue;
};

export const findStorageGroupIdBy = (isBookable, equal, findByKey, value,) => {
    if (findByKey) {
        return (storageGroup) => equal ? storageGroup[findByKey] === value && isBookable(storageGroup)
            : storageGroup[findByKey] !== value && isBookable(storageGroup);
    }

    return (storageGroup) => isBookable(storageGroup);
};

export const findStorageGroupIdByCategory = (category, isBookable, equal) => {
    return findStorageGroupIdBy(isBookable, equal, 'category', category);
};

export const findStorageGroupIdByRentalType = (rentalType, isBookable, equal) => {
    return findStorageGroupIdBy(isBookable, equal, 'rentalType', rentalType);
};

export const getData = ({storageGroups}, form) => {
    const {categories, requestedStartDate, requestedEndDate} = form.getState().values;
    const isBookable = (storageGroup) => storageGroupIsBookable(storageGroup, requestedStartDate, requestedEndDate);
    const selectedStorageGroupId = getSelectedStorageGroupId({categories});
    const selectedStorageGroup = storageGroups?.find(o => o.id === selectedStorageGroupId);

    return {
        isBookable,
        categories,
        storageGroups,
        selectedStorageGroup
    };
};

export const updateSelectedStorageGroupIdRentalType = (site, form, rentalType, appContext) => {
    const {isBookable, categories, storageGroups, selectedStorageGroup} = getData(site, form);
    let newSelectedStorageGroup;
    if(selectedStorageGroup) {
        // a storage group is selected, do we have to deselect it?
        if (selectedStorageGroup && selectedStorageGroup.rentalType !== rentalType) {
            newSelectedStorageGroup =
                // first try to find a replacement in the selected category
                storageGroups.find(findStorageGroupIdByRentalType(rentalType, isBookable, true));
        } else {
            newSelectedStorageGroup = rentalType ? selectedStorageGroup : undefined;
        }
    } else {
        newSelectedStorageGroup =  rentalType ? storageGroups.find(findStorageGroupIdByRentalType(rentalType, isBookable, true)) : undefined;
    }

    appContext.storageGroupCategories.forEach(o => {
        categories[o.key].storageGroupId = !newSelectedStorageGroup || newSelectedStorageGroup.category !== o.key
            ? -1
            : newSelectedStorageGroup.id;
    });

    return categories;
};

export const updateSelectedStorageGroupId = (formValues, form, category, appContext) => {
    const {isBookable, categories, storageGroups, selectedStorageGroup} = getData(formValues, form);
    let newSelectedStorageGroup;
    if(selectedStorageGroup) {
        // a storage group is selected, do we have to deselect it?
        if (!isBookable(selectedStorageGroup)) {
            const categoryToFind = (category || selectedStorageGroup.key);
            newSelectedStorageGroup =
                // first try to find a replacement in the selected category
                storageGroups.find(findStorageGroupIdByCategory(categoryToFind, isBookable, true));
        } else {
            if (category && category !== selectedStorageGroup.key) {
                newSelectedStorageGroup = storageGroups
                    .find(findStorageGroupIdByCategory(category, isBookable, true));
            } else {
                newSelectedStorageGroup = selectedStorageGroup;
            }
        }
    } else {
        // no storage group is selected, do we have to select the first bookable?
        newSelectedStorageGroup = storageGroups.find(findStorageGroupIdByCategory(category, isBookable, true));
    }
    appContext.storageGroupCategories.forEach(o => {
        categories[o.key].storageGroupId = !newSelectedStorageGroup || newSelectedStorageGroup.category !== o.key
            ? -1
            : newSelectedStorageGroup.id;
    });

    return categories;
};

export const validateBookingFormValues = (storageSite, formValues, appContext, authenticationContext) => {
    const errorArray = [];

    if(!formValues.requestedStartDate) {
        errorArray.push(() => strings.bookingStartDateRequired);
    }
    if(!formValues.requestedEndDate && onlyPeriodBookingsAllowed(storageSite)) {
        errorArray.push(() => strings.bookingEndDateRequired);
    }

    if(errorArray.length === 0) {
        const storageGroupId = getSelectedStorageGroupId(formValues);
        const storageGroup = storageSite.storageGroups.find(sg => sg.id === storageGroupId);
        const { rentalType } = storageGroup;

        const days = calculateDaysInPeriod({ startDate: formValues.requestedStartDate, endDate: formValues.requestedEndDate });

        const { minimumBookingLength, area } = storageSite;

        const { storageWidth, storageLength, minBookableArea } = storageGroup;

        if (days < minimumBookingLength && !authenticationContext.isSuperAdminOrImpersonated) {
            errorArray.push(createErrorFormatter({
                typeOfError: strings.smallest,
                unit: strings.bookingLength,
                aimingValue: strings.formatString(strings.xDays, minimumBookingLength),
                value: strings.formatString(strings.xDays, days)
            }));
        }

        if (rentalType === rentalTypes.customArea.key) {
            const width = convertToNumber(formValues.categories[storageGroup.category].width);
            const length = convertToNumber(formValues.categories[storageGroup.category].length);
            const areaCalculation = calculateArea(length, width, storageGroupCategories[storageGroup.category]);
            if (areaCalculation.width && storageLength && storageWidth) {
                const minSide = Math.min(storageWidth, storageLength);
                const maxSide = Math.max(storageWidth, storageLength);
                const compareWidth = Math.min(areaCalculation.adjustedWidth, areaCalculation.adjustedLength);
                const compareLength = Math.max(areaCalculation.adjustedWidth, areaCalculation.adjustedLength);
                if (minSide < compareWidth) {
                    errorArray.push(createErrorFormatter({typeOfError: strings.biggest, unit: strings.width.toLowerCase(), aimingValue: strings.formatString(strings.lengthTemplate, minSide), value: strings.formatString(strings.lengthTemplate, compareWidth)}));
                }
                if (maxSide < compareLength) {
                    errorArray.push(createErrorFormatter({typeOfError: strings.biggest, unit: strings.length.toLowerCase(), aimingValue: strings.formatString(strings.lengthTemplate, maxSide), value: strings.formatString(strings.lengthTemplate, compareLength)}));
                }
            }

            if (areaCalculation.adjustedArea > area) {
                errorArray.push(createErrorFormatter({typeOfError: strings.biggest, unit: strings.area.toLowerCase(), aimingValue: formatArea(area, appContext, { maxNumberOfDecimals: 1 }), value: formatArea(areaCalculation.adjustedArea, appContext, { maxNumberOfDecimals: 1 })}));
            }

            if (areaCalculation.adjustedArea < minBookableArea) {
                errorArray.push(createErrorFormatter({typeOfError: strings.smallest, unit: strings.area.toLowerCase(), aimingValue: formatArea(minBookableArea, appContext, { maxNumberOfDecimals: 1 }), value: formatArea(areaCalculation.adjustedArea, appContext, { maxNumberOfDecimals: 1 })}));
            }
        }
    }

    return errorArray;
};

export const storageGroupIsBookable = (storageGroup, startDate, endDate) => {
    if(!storageGroup.bookingCalendar) {
        return false;
    }
    if(!startDate) {
        // if no start date is given, check if there is at least one bookable interval in the future
        return storageGroup.bookingCalendar.storageBookingCalendars.find(calendar => calendar.availableBookingIntervals.length > 0) !== undefined;
    }
    // check if there is at least one calendar being bookable the given dates
    const bookableInterval = storageGroup.bookingCalendar.storageBookingCalendars
        .find(calendar => storageIsBookable(calendar, startDate, endDate));
    return bookableInterval !== undefined;
};

export const getSimplifiedAvailableBookingIntervals = storageGroup => {
    if(storageGroup && storageGroup.bookingCalendar) {
        return storageGroup.bookingCalendar.simplifiedAvailableBookingIntervals;
    }
    return [{ startDate: new Date(), endDate: undefined }];
};

export const getBookableStorages = (storageGroup, storageGroupBookingCalendar /* set to undefined to use storageGroup.bookingCalendar */, startDate, endDate) => {
    const bookingCalendar = storageGroupBookingCalendar || storageGroup.bookingCalendar;
    const bookableStorageIds = bookingCalendar.storageBookingCalendars
        .filter(calendar => storageIsBookable(calendar, startDate, endDate))
        .map(calendar => calendar.storageId);
    return bookableStorageIds
        .map(storageId => storageGroup.storages.find(storage => storage.id === storageId))
        .filter(storage => storage);
};

export const askForTenantOrganizationType = (storageSite, storageGroup) => !storageSite.considerBookingsAsServices && storageGroup.vatMode === vatModes.businessesOnlyEligibleForVat.key;

export const getBookingDescription = booking => {
    const atoms = [];
    if(booking.description) {
        atoms.push(booking.description);
    }
    if(booking.goodsType) {
        atoms.push(goodsTypes[booking.goodsType].title);
    }
    if(booking.registrationNumber) {
        atoms.push(`(${booking.registrationNumber})`);
    }
    return atoms.join(' ');
};

const storageIsBookable = (storageBookingCalendar, startDate, endDate) => {
    // booking calendars only contain info for today and onwards, so adjust dates if they are too early
    const adjustedStartDate = max([parseIsoDate(startDate), new Date()]);
    const adjustedEndDate = max([parseIsoDate(endDate) || maxDate, new Date()]);
    return storageBookingCalendar.availableBookingIntervals.find(interval => !isAfter(parseIsoDate(interval.startDate), adjustedStartDate) && !isBefore(parseIsoDate(interval.endDate ?? maxDateString), adjustedEndDate)) !== undefined;
};

const createErrorFormatter = err => highlightClass => strings.formatString(strings.bookingError, err.typeOfError, err.unit, <span className={highlightClass}>{err.aimingValue}</span>, err.unit, <span className={highlightClass}>{err.value}</span>);
