import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { handleResponse } from 'actions/actionHelpers';
import { useParams } from 'react-router';
import { getConversation, updateConversation, createMessage } from 'actions/admin/conversations';
import { fetchPredefinedTexts } from 'actions/predefinedTexts';
import { shorten } from 'helpers/StringHelper';
import routes from 'routes';

import Loader from 'common/Loader';
import AdminPage from 'admin/presentational/AdminPage';
import AdminContentWrapper from 'admin/presentational/AdminContentWrapper';
import EditConversationForm from './EditConversationForm';
import SendMessageForm from './SendMessageForm';

const Conversation = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const authenticationContext = useSelector(state => state.authentication.context);
    const { user } = authenticationContext;

    const [conversation, setConversation] = useState(undefined);
    const [predefinedTexts, setPredefinedTexts] = useState(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadConversation();
        loadPredefinedTexts();
    }, []);

    const loadConversation = () => {
        dispatch(getConversation(params.conversationId))
            .then(handleResponse(
                response => {
                    setConversation(response.payload);
                },
                () => setIsLoading(false)
            ));
    };

    const loadPredefinedTexts = () => {
        dispatch(fetchPredefinedTexts('backofficeConversations'))
            .then(handleResponse(
                response => {
                    setPredefinedTexts(response.payload);
                },
                () => setIsLoading(false)
            ));
    };

    useEffect(() => {
        if(conversation && predefinedTexts) {
            setIsLoading(false);
        }
    }, [conversation, predefinedTexts]);

    const handleSubmit = formValues => {
        setIsSubmitting(true);
        const updateConversationRequest = {
            completed: formValues.completed
        };
        dispatch(updateConversation(conversation.id, updateConversationRequest))
            .then(handleResponse(
                () => {
                    history.push(routes.admin.conversations);
                    setIsSubmitting(false);
                    return { snackbar: { message: 'Conversation was successfully updated.' } };
                },
                () => setIsSubmitting(false)
            ));
    };

    const handleCancel = () => {
        history.push(routes.admin.conversations);
    };

    const handleSendMessageFormSubmit = formValues => {
        const createMessageRequest = {
            writerUserId: user.id,
            writerActorId: formValues.senderActorId,
            writeAsAdmin: formValues.writeAsAdmin,
            text: formValues.messageText
        };
        setIsSubmitting(true);
        dispatch(createMessage(conversation.id, createMessageRequest))
            .then(handleResponse(
                () => {
                    history.push(routes.admin.conversations);
                    setIsSubmitting(false);
                    return { snackbar: { message: 'Message was successfully sent.' } };
                },
                () => setIsSubmitting(false)
            ));
    };

    const title = 'Conversation: ' + (conversation ? shorten(conversation.storageSiteTitle, 40) : 'Loading...');

    return (
        <AdminPage title={title}>
            {
                isLoading && <Loader />
            }
            {
                conversation && predefinedTexts &&
                (
                    <AdminContentWrapper type="list">
                        <EditConversationForm
                            conversation={conversation}
                            isSubmitting={isSubmitting}
                            onSubmit={handleSubmit}
                            onCancel={handleCancel}
                        />

                        <SendMessageForm
                            conversation={conversation}
                            predefinedTexts={predefinedTexts}
                            isSubmitting={isSubmitting}
                            onSubmit={handleSendMessageFormSubmit}
                        />
                    </AdminContentWrapper>
                )
            }
        </AdminPage>
    );
};

export default Conversation;
