import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders, createDownloadFileTypes } from './actionHelpers';

export const OWNERBOOKINGS_FETCH = 'OWNERBOOKINGS_FETCH';
export const OWNERBOOKINGS_UPDATE = 'OWNERBOOKINGS_UPDATE';
export const OWNERBOOKINGS_END_SUBSCRIPTION = 'OWNERBOOKINGS_END_SUBSCRIPTION';
export const OWNERBOOKINGS_CREATE_INVOICE = 'OWNERBOOKINGS_CREATE_INVOICE';
export const OWNERBOOKINGS_DELETE_PURCHASE = 'OWNERBOOKINGS_DELETE_PURCHASE';
export const OWNERBOOKINGS_CREATE_NOTE = 'OWNERBOOKINGS_CREATE_NOTE';
export const OWNERBOOKINGS_PREVIEW_CREATE_INVOICE = 'OWNERBOOKINGS_PREVIEW_CREATE_INVOICE';
export const OWNERBOOKINGS_PREVIEW_CREATE_INVOICE_SUCCESS = 'OWNERBOOKINGS_PREVIEW_CREATE_INVOICE_SUCCESS';
export const OWNERBOOKINGS_PREVIEW_CREATE_INVOICE_FAILURE = 'OWNERBOOKINGS_PREVIEW_CREATE_INVOICE_FAILURE';
export const OWNERBOOKINGS_SEND_MESSAGE_TO_TENANTS = 'OWNERBOOKINGS_SEND_MESSAGE_TO_TENANTS';

export const fetchOwnerBooking = bookingId => ({
    [RSAA]: {
        types: createRsaaTypes(OWNERBOOKINGS_FETCH),
        endpoint: `/api/ownerBookings/${bookingId}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const updateOwnerBooking = (bookingId, updateBookingRequest) => {
    return {
        [RSAA]: {
            types: createRsaaTypes(OWNERBOOKINGS_UPDATE),
            endpoint: `/api/ownerBookings/${bookingId}`,
            headers: createHeaders(true),
            method: 'PUT',
            body: JSON.stringify(updateBookingRequest)
        }
    };
};

export const endOwnerSubscriptionBooking = bookingId => ({
    [RSAA]: {
        types: createRsaaTypes(OWNERBOOKINGS_END_SUBSCRIPTION),
        endpoint: `/api/ownerBookings/${bookingId}/endSubscription`,
        method: 'PUT',
        headers: createHeaders(false)
    }
});

export const createPostInvoice = (bookingId, postInvoice) => {
    return {
        [RSAA]: {
            types: createRsaaTypes(OWNERBOOKINGS_CREATE_INVOICE),
            endpoint: `/api/ownerBookings/${bookingId}/postInvoices`,
            headers: createHeaders(true),
            method: 'POST',
            body: JSON.stringify(postInvoice)
        }
    };
};

export const previewPostInvoice = (bookingId, postInvoice) => {
    return {
        [RSAA]: {
            types: createDownloadFileTypes(OWNERBOOKINGS_PREVIEW_CREATE_INVOICE),
            endpoint: `/api/ownerBookings/${bookingId}/postInvoices/preview`,
            headers: createHeaders(true),
            method: 'POST',
            body: JSON.stringify(postInvoice)
        }
    };
};

export const deletePurchase = (bookingId, purchaseId) => {
    return {
        [RSAA]: {
            types: createRsaaTypes(OWNERBOOKINGS_DELETE_PURCHASE),
            endpoint: `/api/ownerBookings/${bookingId}/purchases/${purchaseId}`,
            headers: createHeaders(false),
            method: 'DELETE'
        }
    };
};

export const createBookingNote = (bookingId, request) => ({
    [RSAA]: {
        types: createRsaaTypes(OWNERBOOKINGS_CREATE_NOTE),
        endpoint: `/api/ownerBookings/${bookingId}/notes`,
        headers: createHeaders(true),
        method: 'POST',
        body: JSON.stringify(request)
    }
});

export const sendMessageToTenants = (ownerActorId, request) => ({
    [RSAA]: {
        types: createRsaaTypes(OWNERBOOKINGS_SEND_MESSAGE_TO_TENANTS),
        endpoint: `/api/actors/${ownerActorId}/sendMessageToTenants`,
        headers: createHeaders(true),
        method: 'POST',
        body: JSON.stringify(request)
    }
});
