import React, { useState, useEffect } from 'react';
import { useAppContext } from 'context/AppContext';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { unsubscribeFromMarketing } from 'actions/tenantBookings';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { handleResponse } from 'actions/actionHelpers';

import PageTitle from 'common/PageTitle';
import Box from '@material-ui/core/Box';
import Loader from 'common/Loader';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(({ theme }) => ({
    wrapper: {
        width: '400px',
        margin: '0 auto',
        padding: '2em'
    },
    logo: {
        textAlign: 'center',
        '& img': {
            width: '200px'
        },
        marginBottom: theme.spacing(4)
    }
}));

const states = {
    loading: 'loading',
    success: 'success',
    failure: 'failure'
};

const BookingUnsubscribeFromMarketingPage = () => {
    const classes = useStyles();
    const { appContext } = useAppContext();
    const params = useParams();
    const dispatch = useDispatch();

    const [state, setState] = useState(states.loading);

    useEffect(() => {
        dispatch(unsubscribeFromMarketing(params.bookingId, params.token))
            .then(handleResponse(
                () => {
                    setState(states.success);
                },
                () => {
                    setState(states.failure);
                }
            ));
    }, []);

    const renderSuccess = () => {
        return (
            <Alert severity="success">
                {strings.unsubscribeSucceeded}
            </Alert>
        );
    };

    const renderFailure = () => {
        return (
            <Alert severity="error">
                {strings.unsubscribeFailed}
            </Alert>
        );
    };

    return (
        <>
            <PageTitle>{strings.unsubscribeTitle}</PageTitle>
            <Box className={classes.wrapper}>
                <Box className={classes.logo}>
                    <img src={appContext.images.topLogo}/>
                </Box>
                {state === states.loading && <Loader/>}
                {state === states.success && renderSuccess()}
                {state === states.failure && renderFailure()}
            </Box>
        </>
    );
};

export default BookingUnsubscribeFromMarketingPage;
