import storageTypes from 'enums/storageTypes';
import storageGroupCategories from 'enums/storageGroupCategories';
import storageSiteFacilities from 'enums/storageSiteFacilities';
import storageStatuses from 'enums/storageStatuses';
import landingPages from 'enums/landingPages';
import rentalTypes from 'enums/rentalTypes';
import vatModes from 'enums/vatModes';
import queryString from 'query-string';
import strings from 'localization/strings';
import { convertToNumber, isNumber, formatNumber, formatArea, formatLength, formatQuantity, replaceAll } from 'helpers/StringHelper';
import { getDayAndMonthFormat, formatDateTime, parseIsoDate } from 'helpers/DateHelper';
import { startOfDay } from 'date-fns';
import { chainableDefaultCompare, defaultCompare } from 'helpers/SortHelper';
import bookingTimeModes from 'enums/bookingTimeModes';
import displayPriceModes from 'enums/displayPriceModes';
import routes from 'routes';
import lockTypes from 'enums/lockTypes';
import { MINIMUM_SUBSCRIPTION_BOOKING_LENGTH } from 'helpers/BookingHelper';

export const getStorageTypeItem = storageType => {
    return storageTypes[storageType] || {};
};

export const getStorageSiteDetailsUrl = (storageSite, categoryKey, querystringParameters = {}) => {
    const numberOfStorageGroupCategories = categoryKey
        ? Object.keys(storageGroupCategories)
            .filter(ck => storageSite.storageGroups.find(sg => sg.category === ck) !== undefined)
            .length
        : getStorageGroupCategories(storageSite).length;

    const city = storageSite.city || strings.unknown;
    let categoryString;
    if(numberOfStorageGroupCategories === 1) {
        categoryString = landingPages[storageSite.storageGroups[0].category].url;
    } else if(categoryKey && landingPages[categoryKey]) {
        categoryString = landingPages[categoryKey].url;
    } else {
        categoryString = strings.genericStorageUrl;
    }

    // build querystring
    const sanitizedQuerystringParameters1 = { ...querystringParameters };
    sanitizedQuerystringParameters1.category = categoryKey && numberOfStorageGroupCategories > 1
        ? categoryKey
        : undefined;
    const sanitizedQuerystringParameters2 = {};
    Object.keys(sanitizedQuerystringParameters1).forEach(key => {
        if(sanitizedQuerystringParameters1[key] !== undefined) {
            sanitizedQuerystringParameters2[key] = sanitizedQuerystringParameters1[key];
        }
    });

    return routes.storageSiteDetailsExtended
        .replace('/:storageGroupCategory', categoryString)
        .replace(':location', makeTidy(city))
        .replace(':title', makeTidy(storageSite.title))
        .replace(':storageSiteId', storageSite.id)
        + (Object.keys(sanitizedQuerystringParameters2).length > 0 ? '?' + queryString.stringify(sanitizedQuerystringParameters2) : '');
};

export const getAreaExplanation = storageSite =>
    storageSite.storageGroups.find(sg => sg.rentalType === rentalTypes.customArea.key)
        ? strings.customArea
        : strings.fixedArea;

export const getPriceAdjustmentExplanation = (template, percentage, adjustmentDate, appContext) =>
    strings.formatString(
        template,
        formatNumber(100 * percentage, appContext),
        formatDateTime(`2021-${adjustmentDate}`, getDayAndMonthFormat, appContext)
    );

export const getAboutOurPricesText = (storageSite, selectedCategory, selectedStorageGroup, appContext) => {
    if(appContext.displayPriceMode === displayPriceModes.excludingVat.key) {
        return strings.aboutOurPricesVatExcluded;
    }

    if(selectedStorageGroup) {
        switch(selectedStorageGroup.vatMode) {
            case vatModes.eligibleForVat.key:
                return strings.aboutOurPricesVatIncludedMessage;
            case vatModes.notEligibleForVat.key:
                return strings.aboutOurPricesNoVatMessage;
            case vatModes.businessesOnlyEligibleForVat.key:
                return strings.aboutOurPricesVatExcludedForBusinessesMessage;
            default:
                throw new Error(`Unknown VAT mode: ${JSON.stringify(selectedStorageGroup.vatMode)}.`);
        }
    }
    const firstStorageGroupInSelectedCategory = storageSite.storageGroups.filter(o => selectedCategory && o.category === selectedCategory.key)[0];
    if(firstStorageGroupInSelectedCategory) {
        return getAboutOurPricesText(storageSite, selectedCategory, firstStorageGroupInSelectedCategory, appContext);
    }
    return strings.aboutOurPricesMessage;
};

export const getArea = (storageGroup, appContext, maxNumberOfDecimals = 1) => {
    return formatArea(convertToNumber(storageGroup.storageArea), appContext, { maxNumberOfDecimals }, '-');
};

export const getStorageGroupTitle = (storageGroup, appContext, { showCeilingHeight, forceShowSize, includeStorageTitles, useSpecialCustomAreaLabel } = {}) => {
    if(useSpecialCustomAreaLabel && storageGroup.rentalType === rentalTypes.customArea.key) {
        return strings.customSize;
    }

    const formattedArea = getArea(storageGroup, appContext);
    const ceilingHeightText = showCeilingHeight
        ? getCeilingHeightSuffix(storageGroup, appContext)
        : '';

    let storageTitles = '';
    if(includeStorageTitles) {
        storageTitles = getStorageTitlesForStorageGroup(storageGroup, appContext);
        if(storageTitles) {
            storageTitles = ', ' + storageTitles;
        }
    }

    if(storageGroup.title) {
        return storageGroup.title +
            (forceShowSize ? `, ${formattedArea + ceilingHeightText}` : '') +
            storageTitles;
    }

    return formattedArea + ceilingHeightText + storageTitles;
};

export const getStorageTitlesForStorageGroup = (storageGroup, appContext) => {
    const visibleStorages = storageGroup.storages
        .filter(storage => storage.status !== storageStatuses.deleted.key);
    if(!visibleStorages.find(o => o.title)) {
        return undefined;
    }
    const sortedStorages = getStorageSorter(appContext)(visibleStorages.map(storage => ({ storage, storageGroup })))
        .map(item => item.storage);
    const atoms = [];
    let lastNumber;
    let lastAtom;

    sortedStorages
        .forEach(storage => {
            const title = getStorageSortableTitle(storage, storageGroup, appContext);
            if(isNumber(title)) {
                if(!lastNumber || title !== lastNumber + 1) {
                    lastAtom = { first: storage.title };
                    atoms.push(lastAtom);
                } else {
                    lastAtom.last = title;
                }
                lastNumber = title;
            } else {
                atoms.push({ first: title });
            }
        });
    return atoms.map(o => o.last ? `${o.first}-${o.last}` : o.first).join(', ');
};

export const getStorageTitle = (storage, storageGroup, appContext) => {
    const area = storageGroup.storageArea;
    const areaString = formatArea(area, appContext, { maxNumberOfDecimals: 1 });
    if(storageGroup.rentalType === rentalTypes.customArea.key) {
        return area
            ? areaString
            : rentalTypes.customArea.title;
    }
    if(!storageGroup.enumerateStorages && storageGroup.quantity > 1) {
        return `${areaString}, ${formatQuantity(storageGroup.quantity)}`;
    }
    return storageGroup.enumerateStorages
        ? `${storage.title}, ${areaString}`
        : areaString;
};

export const getStorageSiteAndStorageTitle = (storage, storageGroup) => {
    if(storage?.title) {
        return `${storageGroup.storageSite.title} - ${storage.title}`;
    }
    return storageGroup.storageSite.title;
};

export const getStorageSortableTitle = (storage, storageGroup, appContext) => {
    if(storage.title) {
        // try to extract a numeric atom from the title, e.g. 1234 from 'Storage 1234'
        const atoms = storage.title.split(' ');
        const numericTitle = atoms.map(convertToNumber).find(o => o);
        if(numericTitle) {
            return numericTitle;
        }
    }
    // fallback to normal title
    return getStorageTitle(storage, storageGroup, appContext);
};

export const formatStorageSiteAddress = storageSite => `${storageSite.address}, ${storageSite.postalCode} ${storageSite.city}`;

export const getStorageSorter = appContext => (items  /* { storage, storageGroup } */, sortBy) => {
    switch(sortBy?.toLowerCase()) {
        case 'area':
            items.sort((a, b) =>
                chainableDefaultCompare(calculateStorageGroupArea(a.storageGroup), calculateStorageGroupArea(b.storageGroup))
                ??
                defaultCompare(
                    getStorageSortableTitle(a.storage, a.storageGroup, appContext),
                    getStorageSortableTitle(b.storage, b.storageGroup, appContext)
                )
            );
            break;
        default:
            items.sort((a, b) => defaultCompare(
                getStorageSortableTitle(a.storage, a.storageGroup, appContext),
                getStorageSortableTitle(b.storage, b.storageGroup, appContext)
            ));
        break;
    }
    return items;
};

export const getCeilingHeightSuffix = (storageGroup, appContext, withText = true) =>
    storageGroup.ceilingHeight
        ? `, ${withText ? strings.ceilingHeightLC : ''} ${formatLength(convertToNumber(storageGroup.ceilingHeight), appContext)}`
        : '';

export const getFlattenedStorageSiteFacilities = appContext => {
    const result = [];
    Object.values(appContext.facilityCategories)
        .forEach(fc => result.push(...fc.map(key => storageSiteFacilities[key])));
    return result;
};

export const getStorageGroupCategories = storageSite =>
    Object.values(storageGroupCategories)
        .filter(category => storageSite.storageGroups.find(sg => sg.category === category.key));

export const hasDiscountedMonths = storageSite => storageSite.activeDiscount?.numberOfMonths !== undefined;

export const getDiscountMinimumBookingLength = storageSite => {
    if(!hasDiscountedMonths(storageSite)) {
        return undefined;
    }
    return Math.max(storageSite.activeDiscount.minimumBookingLength ?? 0, storageSite.activeDiscount.minimumBookingLength ?? MINIMUM_SUBSCRIPTION_BOOKING_LENGTH);
};

export const getStorageSiteCurrentDiscountPercentage = storageSite => {
    const today = startOfDay(new Date());
    const activeDiscount = storageSite.discounts.find(o => parseIsoDate(o.startDate) <= today && parseIsoDate(o.endDate) >= today && !o.numberOfMonths);
    return activeDiscount?.percentage;
};

export const sanitizeSelectedCategory = (storageSite, selectedCategory) => {
    if(storageSite.storageGroups.find(storageGroup => storageGroup.category === selectedCategory?.key)) {
        return selectedCategory;
    }
    return storageGroupCategories[storageSite.storageGroups[0].category];
};

export const onlySubscriptionBookingsAllowed = storageSite => storageSite.allowedTimeModes.length === 1 && storageSite.allowedTimeModes[0] === bookingTimeModes.subscription.key;

export const onlyPeriodBookingsAllowed = storageSite => storageSite.allowedTimeModes.length === 1 && storageSite.allowedTimeModes[0] === bookingTimeModes.period.key;

export const storageSiteHasLockCodes = storageSite => storageSite.storageGroups.filter(storageGroupHasLockCodes).length > 0;

export const storageGroupHasLockCodes = storageGroup => storageGroup.lockConfigurations?.length > 0;

export const storageSiteHasLockType = (storageSite, lockType) => storageSite.storageGroups.some(sg => storageGroupHasLockType(sg, lockType));

export const storageGroupHasLockType = (storageGroup, lockType) => storageGroup.lockConfigurations?.some(lc => lc.lockType === lockType) ?? false;

export const formatLockConfigurations = storageGroup => {
    if(!storageGroup.lockConfigurations || storageGroup.lockConfigurations.length === 0) {
        return undefined;
    }
    const prefixFormatter = lc => storageGroup.lockConfigurations.length > 1
        ? `${lc.name}: `
        : '';

    return storageGroup.lockConfigurations
        .map(o => `${prefixFormatter(o)}${lockTypes[o.lockType].title} ${lockTypes[o.lockType].format(o)}`)
        .join(', ');
};

const calculateStorageGroupArea = storageGroup => {
    if(storageGroup.storageArea !== undefined) {
        return storageGroup.storageArea;
    }
    if(storageGroup.storageLength !== undefined && storageGroup.storageWidth !== undefined) {
        return storageGroup.storageLength * storageGroup.storageWidth;
    }
    return undefined;
};


const makeTidy = s => replaceAll(s || '', [' ', '/'], '-');
