import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import bookingStatuses from 'enums/bookingStatuses';
import paymentRecurrences from 'enums/paymentRecurrences';
import bookingItemTypes from 'enums/bookingItemTypes';
import { createFormBookingItem } from 'helpers/BookingHelper';

import { FieldArray } from 'react-final-form-arrays';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import BookingItemEditor from './BookingItemEditor';

const useStyles = makeStyles(({ theme }) => ({
    addButtonContainer: {
        '& > *': {
            marginRight: theme.spacing(1)
        }
    }
}));

const BookingItemsEditor = ({ booking, storageSite }) => {
    const classes = useStyles();

    const { appContext } = useAppContext();

    const beforePurchase =
        booking.bookingStatus === bookingStatuses.requested.key ||
        booking.bookingStatus === bookingStatuses.confirmed.key;

    const availableAdditionalServices = storageSite.additionalServices
        .filter(additionalService => !booking.subscriptionBooking || beforePurchase || additionalService.paymentRecurrence === paymentRecurrences.perMonth.key);

    const storageCommissionRate = booking.bookingItems.find(o => o.type === bookingItemTypes.storage.key).commissionRate;

    const handleRemove = (fields, index) => {
        fields.remove(index);
    };

    const handleAdd = (fields, paymentRecurrence, isPredefined) => {
        let item;
        if(isPredefined) {
            item = createItem(availableAdditionalServices[0]);
        } else {
            item = createFormBookingItem({
                commissionRate: storageCommissionRate,
                paymentRecurrence,
                type: bookingItemTypes.service.key,
            }, booking, storageSite.additionalServices, appContext);
        }
        fields.push(item);
    };

    const createItem = additionalService => createFormBookingItem({
        referenceId: additionalService?.id,
        amount: additionalService.price,
        vatRate: additionalService.vatRate,
        commissionRate: additionalService.commissionRate ?? storageCommissionRate,
        paymentRecurrence: additionalService.paymentRecurrence,
        type: bookingItemTypes.service.key,
        isPredefined: true
    }, booking, storageSite.additionalServices, appContext);

    return (
        <FieldArray name="bookingItems">
            {({ fields }) => (
                <>
                    {
                        fields.map((name, index) => {
                            return <BookingItemEditor
                                key={index}
                                fieldNamePrefix={name}
                                booking={booking}
                                additionalServices={storageSite.additionalServices}
                                itemCreator={createItem}
                                onRemove={() => handleRemove(fields, index)}
                            />;
                        })
                    }
                    <Box className={classes.addButtonContainer}>
                        {
                            availableAdditionalServices.length > 0 &&
                            (
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => handleAdd(fields, undefined, true)}
                                >
                                    Add predefined service
                                </Button>
                            )
                        }
                        <Button
                            size="small"
                            color="primary"
                            variant="outlined"
                            onClick={() => handleAdd(fields, paymentRecurrences.perMonth.key, false)}
                        >
                            Add free-text service paid by month
                        </Button>
                        {
                            (beforePurchase || !booking.subscriptionBooking) &&
                            (
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => handleAdd(fields, paymentRecurrences.oneTime.key, false)}
                                >
                                    Add free-text service paid once
                                </Button>
                            )
                        }
                    </Box>
                </>
            )}
        </FieldArray>
    );
};

BookingItemsEditor.propTypes = {
    booking: PropTypes.object.isRequired,
    storageSite: PropTypes.object.isRequired
};

export default BookingItemsEditor;
