import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';

export const PREDEFINED_TEXTS_FETCH = 'PREDEFINED_TEXTS_FETCH';

export const fetchPredefinedTexts = section => ({
    [RSAA]: {
        types: createRsaaTypes(PREDEFINED_TEXTS_FETCH),
        endpoint: `/api/predefinedTexts/${section}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});
