import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'styles/util';
import { handleResponse } from 'actions/actionHelpers';
import { updateRoundReportStorageItem, deleteRoundReportStorageItem } from 'actions/roundReports';
import strings from 'localization/strings';
import clsx from 'clsx';

import AttachRoundReportImageDialog from './AttachRoundReportImageDialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CachedIcon from '@material-ui/icons/Cached';
import UndoIcon from '@material-ui/icons/Undo';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Lightbox from 'react-image-lightbox';

import 'react-image-lightbox/style.css';

const useStyles = makeStyles(({ theme, colors }) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1.5, 2),
        gap: theme.spacing(1),
        '&:nth-child(even)': {
            backgroundColor: colors.lightGrey
        }
    },
    changed: {
        backgroundColor: '#ffffbf',
        '&:nth-child(even)': {
            backgroundColor: '#f7f7bf'
        }
    },
    line1: {
        fontWeight: 'bold'
    },
    line2: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2)
    },
    status: {
        flex: '1 1 auto'
    },
    buttons: {
        flex: `0 0 ${theme.spacing(10)}px`,
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        justifyContent: 'flex-end',
        height: theme.spacing(5)
    },
    image: {
        flex: `0 0 ${theme.spacing(6)}px`,
        display: 'flex',
        '& > img': {
            alignItems: 'center',
            maxHeight: theme.spacing(5)
        }
    },
    occupied: {
        color: colors.no
    },
    free: {
        color: colors.yes
    },
    statusMarkupContainer: {
        display: 'flex',
        gap: theme.spacing(1)
    },
    button: {
        padding: theme.spacing(1),
        minWidth: 'auto'
    },
    whiteButton: {
        backgroundColor: colors.white
    }
}));

const RoundReportStorageItem = ({ roundReport, roundReportStorageItem, onChange }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [updatedRoundReportStorageItem, setUpdatedRoundReportStorageItem] = useState(roundReportStorageItem);
    const [isSaving, setIsSaving] = useState(false);

    const changed =
        (roundReportStorageItem.occupiedInSystem && updatedRoundReportStorageItem.occupiedInReality === false)
        ||
        (!roundReportStorageItem.occupiedInSystem && updatedRoundReportStorageItem.occupiedInReality === true);

    const getStatusMarkup = () => {
        const occupied = changed ? updatedRoundReportStorageItem.occupiedInReality : roundReportStorageItem.occupiedInSystem;
        const text = occupied ? strings.pages.roundReport.occupationStatuses.occupied : strings.pages.roundReport.occupationStatuses.free;
        const className = occupied ? classes.occupied : classes.free;
        const icon = updatedRoundReportStorageItem.occupiedInReality === undefined ? <RadioButtonUncheckedIcon/> : <CheckCircleIcon/>;
        return (
            <Box className={clsx(classes.statusMarkupContainer, className)}>
                {icon}
                <Box>{text}</Box>
            </Box>
        );
    };

    const getImageMarkup = () => {
        const src = updatedRoundReportStorageItem.imageData ?? updatedRoundReportStorageItem.imageUrl;
        if(!src) {
            return undefined;
        }

        return (
            <>
                <img
                    src={src}
                    onClick={() => setLightboxOpen(true)}
                />
                {
                    lightboxOpen &&
                    (
                        <Lightbox
                            mainSrc={src}
                            onCloseRequest={() => setLightboxOpen(false)}
                        />
                    )
                }
            </>
        );
    };

    const getButtonsMarkup = () => {
        if(roundReport.completedTime) {
            return undefined;
        }
        return (
            <>
                {
                    isSaving &&
                    (
                        <CircularProgress
                            size={16}
                            color="primary"
                        />
                    )
                }
                {
                    updatedRoundReportStorageItem.occupiedInReality === undefined &&
                    (
                        <>
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                disabled={isSaving}
                                onClick={handleCorrectButtonClick}
                            >
                                <CheckIcon/>
                            </Button>
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                disabled={isSaving}
                                onClick={handleChangeButtonClick}
                            >
                                <CachedIcon/>
                            </Button>
                        </>
                    )
                }
                {
                    updatedRoundReportStorageItem.occupiedInReality !== undefined &&
                    (
                        <Button
                            className={clsx(classes.button, classes.whiteButton)}
                            variant="outlined"
                            color="primary"
                            disabled={isSaving}
                            onClick={handleUndoButtonClick}
                        >
                            <UndoIcon/>
                        </Button>
                    )
                }
            </>
        );
    };

    const handleCorrectButtonClick = () => {
        update({
            occupiedInReality: roundReportStorageItem.occupiedInSystem
        });
    };

    const handleChangeButtonClick = () => {
        if(!roundReportStorageItem.occupiedInSystem) {
            // capture image
            setDialogOpen(true);
        } else {
            update({
                occupiedInReality: false
            });
        }
    };

    const handleUndoButtonClick = () => {
        setIsSaving(true);
        dispatch(deleteRoundReportStorageItem(roundReport.id, roundReport.token, roundReportStorageItem.storage.id))
            .then(handleResponse(
                () => {
                    setIsSaving(false);
                    const item = {
                        occupiedInReality: undefined,
                        imageData: undefined,
                        imageUrl: undefined
                    };
                    setUpdatedRoundReportStorageItem(item);
                    onChange(item);
                },
                () => {
                    setIsSaving(false);
                }
            ));
    };

    const handleDialogOk = values => {
        update({
            occupiedInReality: values.occupiedInReality,
            imageData: values.imageData
        });
        setDialogOpen(false);
    };

    const handleDialogCancel = () => {
        setDialogOpen(false);
    };

    const update = updatedValue => {
        setIsSaving(true);
        dispatch(updateRoundReportStorageItem(roundReport.id, roundReport.token, roundReportStorageItem.storage.id, updatedValue))
            .then(handleResponse(
                () => {
                    setIsSaving(false);
                    setUpdatedRoundReportStorageItem(updatedValue);
                    onChange(updatedValue);
                },
                () => {
                    setIsSaving(false);
                }
            ));
    };

    return (
        <Box className={clsx(classes.container, { [classes.changed]: changed })}>
            <Box className={classes.line1}>
                {roundReportStorageItem.storage.title}
            </Box>
            <Box className={classes.line2}>
                <Box className={classes.status}>
                    {getStatusMarkup()}
                </Box>
                <Box className={classes.image}>
                    {getImageMarkup()}
                </Box>
                <Box className={classes.buttons}>
                    {getButtonsMarkup()}
                </Box>
            </Box>
            <AttachRoundReportImageDialog
                open={dialogOpen}
                roundReportStorageItem={roundReportStorageItem}
                onOk={handleDialogOk}
                onCancel={handleDialogCancel}
            />
        </Box>
    );
};

RoundReportStorageItem.propTypes = {
    roundReport: PropTypes.object.isRequired,
    roundReportStorageItem: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};

export default RoundReportStorageItem;
