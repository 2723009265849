import React, { useState } from 'react';
import { useAppContext } from 'context/AppContext';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { add, getISODay, getDate, differenceInCalendarDays, endOfMonth, isAfter } from 'date-fns';
import { parseIsoDate, formatShortMonth } from 'helpers/DateHelper';
import rentalTypes from 'enums/rentalTypes';
import { maxDateString } from 'logic/bookingLogic';
import { getBusinessOrPrivateName } from 'helpers/ActorHelper';
import { toPascalCase } from 'helpers/StringHelper';
import storageStatuses from 'enums/storageStatuses';
import storageGroupStatuses from 'enums/storageGroupStatuses';
import subscriptionEndReasons from 'enums/subscriptionEndReasons';
import strings from 'localization/strings';

import Box from '@material-ui/core/Box';
import ToolTip from 'react-portal-tooltip';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';

const useStyles = makeStyles(({ theme, colors }) => ({
    container: {
    },

    header: {
        display: 'flex',
        width: '100%',
        height: '2rem',
    },
    headerLeft: {
        flex: '15em 0 0',
        lineHeight: '2rem'
    },
    monthNames: {
        position: 'relative',
        flex: '1 0 auto',
        fontSize: '75%'
    },
    monthName: {
        textAlign: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        overflow: 'hidden',
        lineHeight: '2rem'
    },
    timelineContainer: {
        position: 'relative',
    },

    item: {
        display: 'flex',
        width: '100%',
        height: '2rem',
    },
    itemTitle: {
        flex: '15em 0 0',
        lineHeight: '2rem',
        overflow: 'hidden'
    },
    itemTimelineContainer: {
        position: 'relative',
        flex: '1 0 auto'
    },
    itemCapacityUtilizations: {
        position: 'absolute',
        width: '100%',
        top: 0,
        bottom: 0,
        zIndex: 1
    },
    capacityUtilization: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        overflow: 'hidden',
        borderTop: `1px solid ${colors.black}`,
        borderLeft: `1px solid ${colors.black}`,
        borderBottom: `1px solid ${colors.black}`,
        lineHeight: '2rem',
        cursor: 'pointer',
        '&:last-child': {
            borderRight: `1px solid ${colors.black}`
        },
        '&:hover': {
            opacity: 1
        }
    },
    free: {
        background: 'rgba(0, 255, 0, 0.2)',
        '&:hover': {
            background: 'rgba(0, 255, 0, 0.3)'
        }
    },
    occupied: {
        background: 'rgba(255, 0, 0, 0.2)',
        '&:hover': {
            background: 'rgba(255, 0, 0, 0.3)'
        }
    },
    customAreaOccupied: {
        background: 'rgba(96, 96, 96, 0.2)',
        '&:hover': {
            background: 'rgba(96, 96, 96, 0.3)'
        }
    },
    itemGridlineContainer: {
        position: 'absolute',
        left: '15em',
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 0,
        opacity: 0.4
    },
    gridline: {
        position: 'absolute',
        top: 0,
        bottom: 0
    },
    monthGridline: {
        borderLeft: `1px solid ${colors.black}`
    },
    weekGridline: {
        borderLeft: `1px solid ${colors.black}`,
        opacity: 0.6
    },
    infinity: {
        position: 'absolute',
        right: '4px'
    },
    bookingEndIndicatorContainer: {
        position: 'absolute',
        display: 'flex',
        right: '4px',
        width: '8px',
        height: '100%',
        '& > *': {
            width: '100%',
            height: '8px',
            border: `1px solid ${colors.black}`,
            borderRadius: '2px',
            alignSelf: 'center'
        }
    },
    bookingEndIndicatorPeriodBooking: {
        backgroundColor: 'rgba(128, 128, 128, 1)'
    },
    bookingEndIndicatorRequestedByTenant: {
        backgroundColor: 'rgba(64, 64, 255, 1)'
    },
    bookingEndIndicatorRequestedByOwner: {
        backgroundColor: 'rgb(64, 192, 192)'
    },
    bookingEndIndicatorMissingPayment: {
        backgroundColor: 'rgba(255, 0, 0, 1)'
    },
    bookingEndIndicatorRefund: {
        backgroundColor: 'rgba(192, 192 , 64, 1)'
    },
    infinityIcon: {
        fontSize: '75%'
    },
    countLabel: {
        paddingLeft: '4px'
    },
    inactive: {
        opacity: 0.33
    },
    bookingEndIndicatorLegend: {
        margin: theme.spacing(2, 0)
    },
    bookingEndIndicatorLegendItem: {
        marginRight: theme.spacing(2),
        display: 'inline-flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        '& > :first-child': {
            width: '1em',
            height: '1em',
            border: `1px solid ${colors.black}`,
            borderRadius: '2px'
        }
    }
}));

// items: [{ storage, storageGroup, title, capacityUtilizations, bookings }, ...]
const CapacityUtilizationTimelineView = ({ items, startDate, endDate, onItemClick }) => {
    const { appContext } = useAppContext();
    const classes = useStyles();

    const tooltipStyle = {
        style: {
            background: appContext.colors.tooltipGrey,
            color: appContext.colors.white,
            fontSize: '75%'
        },
        arrowStyle: {
            color: appContext.colors.tooltipGrey,
            borderColor: false
        }
    };

    const totalNumberOfDays = differenceInCalendarDays(endDate, startDate) + 1;

    const [tooltipContent, setTooltipContent] = useState(undefined);
    const [tooltipActive, setTooltipActive] = useState(false);
    const [tooltipElementId, setTooltipElementId] = useState('dummy');

    const renderCapacityUtilization = ({ item, capacityUtilization, isLast }) => {
        const isBookedUntilInfinity =
            isLast &&
            capacityUtilization.untilFurtherBookedStorageCount >= capacityUtilization.totalStorageCount &&
            capacityUtilization.untilFurtherBookedStorageCount > 0;
        const id = getCapacityUtilizationElementId(item.storage, capacityUtilization);
        return (
            <Box
                key={id}
                id={id}
                className={classes.capacityUtilization + ' ' + getOccupationStatusClassName(item.storageGroup, capacityUtilization)}
                style={getCapacityUtilizationStyle(capacityUtilization)}
                onClick={onItemClick ? () => onItemClick({ item, capacityUtilization }) : undefined}
                onMouseEnter={() => handleShowTooltip(item, capacityUtilization)} onMouseLeave={handleHideTooltip}
            >
                {getCapacityUtilizationLabel(item.storageGroup, capacityUtilization)}
                {
                    isBookedUntilInfinity &&
                    (
                        <Box className={classes.infinity}>
                            <AllInclusiveIcon className={classes.infinityIcon} />
                        </Box>
                    )
                }
                {getBookingEndIndicator(item, capacityUtilization)}
            </Box>
        );
    };

    const getCapacityUtilizationElementId = (storage, capacityUtilization) => `storage-${storage.id}-${capacityUtilization.interval.startDate}`;

    const getCapacityUtilizationLabel = (storageGroup, capacityUtilization) => {
        if(storageGroup.rentalType === rentalTypes.customArea.key) {
            return undefined;
        }

        const freeCount = capacityUtilization.totalStorageCount - capacityUtilization.bookedStorageCount;
        if(freeCount > 0) {
            return <Box className={classes.countLabel}>{freeCount}</Box>;
        }
        return undefined;
    };

    const getCapacityUtilizationStyle = capacityUtilization => {
        const dayIndex = differenceInCalendarDays(parseIsoDate(capacityUtilization.interval.startDate), startDate);
        const numberOfDays = differenceInCalendarDays(parseIsoDate(capacityUtilization.interval.endDate), parseIsoDate(capacityUtilization.interval.startDate)) + 1;
        return {
            left: `${100 * dayIndex / totalNumberOfDays}%`,
            width: `${100 * numberOfDays / totalNumberOfDays}%`
        };
    };

    const getOccupationStatusClassName = (storageGroup, capacityUtilization) => {
        if(storageGroup.rentalType === rentalTypes.customArea.key) {
            return capacityUtilization.totalArea > capacityUtilization.bookedArea || capacityUtilization.bookedArea === 0
                ? classes.free
                : classes.customAreaOccupied;
        }
        return capacityUtilization.totalStorageCount > capacityUtilization.bookedStorageCount
            ? classes.free
            : classes.occupied;
    };

    const getBookingEndIndicator = (item, capacityUtilization) => {
        const className = getBookingEndIndicatorClassName(getBookingsInInterval(item, capacityUtilization));
        if(!className) {
            return undefined;
        }
        return <Box className={classes.bookingEndIndicatorContainer}><Box className={className}/></Box>;
    };

    const getBookingEndIndicatorClassName = bookings => {
        bookings.sort((a, b) => {
            const aDate = a.actualEndDate ?? a.endDate ?? a.subscriptionEndDate;
            const bDate = b.actualEndDate ?? b.endDate ?? b.subscriptionEndDate;
            if(aDate > bDate) {
                return -1;
            }
            if(aDate > bDate) {
                return 1;
            }
            return 0;
        });
        const reason = getBookingEndReason(bookings[0]);
        return reason
            ? classes[`bookingEndIndicator${toPascalCase(reason)}`]
            : undefined;
    };

    const getBookingEndReason = booking => {
        if(!booking) {
            return undefined;
        }
        if(!booking.subscriptionBooking) {
            return 'periodBooking';
        }
        switch(booking.subscriptionEndReason) {
            case subscriptionEndReasons.requestedByTenant.key: return 'requestedByTenant';
            case subscriptionEndReasons.requestedByOwner.key: return 'requestedByOwner';
            case subscriptionEndReasons.missingPayment.key: return 'missingPayment';
            case subscriptionEndReasons.refund.key: return 'refund';
            default: return undefined;
        }
    };

    const renderMonthNames = () => {
        let date = startDate;
        const months = [];
        while(date <= endDate) {
            if(date === startDate || getDate(date) === 1) { // first day of month
                let lastVisibleDateOfMonth = endOfMonth(date);
                if(isAfter(lastVisibleDateOfMonth, endDate)) {
                    lastVisibleDateOfMonth = endDate;
                }
                months.push({
                    date,
                    title: formatShortMonth(date, appContext),
                    startPosition: differenceInCalendarDays(date, startDate) / totalNumberOfDays,
                    width: (differenceInCalendarDays(lastVisibleDateOfMonth, date) + 1) / totalNumberOfDays
                });
            }
            date = add(date, { days: 1 });
        }

        return months.map(month => (
            <Box key={month.date} className={classes.monthName} style={{ left: `${100 * month.startPosition}%`, width: `${100 * month.width}%` }}>
                {month.title}
            </Box>
        ));
    };

    const renderGridlines = () => {
        let date = startDate;
        const lines = [];
        let dayIndex = 0;
        while(date <= endDate) {
            if(getDate(date) === 1) { // first day of month
                lines.push({ date, className: classes.monthGridline, position: dayIndex / totalNumberOfDays });
            } else if(getISODay(date) === 1) { // Monday
                lines.push({ date, className: classes.weekGridline, position: dayIndex / totalNumberOfDays });
            }
            date = add(date, { days: 1 });
            dayIndex++;
        }

        return lines.map(line => (
            <Box
                key={line.date}
                className={classes.gridline + ' ' + line.className}
                style={{ left: `${100 * line.position}%` }}
            />
        ));
    };

    const getBookingsInInterval = (item, capacityUtilization) => {
        const interval = capacityUtilization.interval;
        return item.bookings.filter(o => o.startDate <= interval.endDate && (o.actualEndDate ?? o.endDate ?? o.subscriptionEndDate ?? maxDateString) >= interval.startDate);
    };

    const formatCapacityUtilizationTooltip = (item, capacityUtilization) => {
        const bookingsInInterval = getBookingsInInterval(item, capacityUtilization);
        bookingsInInterval.sort((a, b) => a.startDate > b.startDate ? 1 : -1);
        return (
            <>
                {item.title}
                {bookingsInInterval.map(o => formatBookingForTooltip(o))}
            </>
        );
    };

    const formatBookingForTooltip = booking => <Box key={booking.id}>{getBusinessOrPrivateName(booking.tenantActor)}: {booking.startDate} &ndash; {booking.actualEndDate ?? booking.endDate ?? booking.subscriptionEndDate}{getBookingEndReasonText(booking, ', ')}</Box>;

    const getBookingEndReasonText = (booking, prefix) => {
        const reason = getBookingEndReason(booking);
        if(!reason) {
            return undefined;
        }
        return `${prefix}${strings.bookingEndReasons[reason]}`;
    };

    const getItemClassName = item => {
        const classNames = [classes.item];
        if(item.storage.status === storageStatuses.inactive.key || item.storageGroup.status === storageGroupStatuses.inactive.key) {
            classNames.push(classes.inactive);
        }
        return classNames.join(' ');
    };

    const handleShowTooltip = (item, capacityUtilization) => {
        setTooltipContent({ item, capacityUtilization });
        setTooltipElementId(getCapacityUtilizationElementId(item.storage, capacityUtilization));
        setTooltipActive(true);
    };

    const handleHideTooltip = () => {
        setTooltipActive(false);
    };

    return (
        <Box className={classes.container}>
            <Box className={classes.header}>
                <Box className={classes.headerLeft}>
                    &nbsp;
                </Box>
                <Box className={classes.monthNames}>
                    {renderMonthNames()}
                </Box>
            </Box>
            <Box className={classes.timelineContainer}>
                <Box className={classes.itemGridlineContainer}>
                    {renderGridlines()}
                </Box>
                {
                    items.map((item, i) => (
                        <Box key={i} className={getItemClassName(item)}>
                            <Box className={classes.itemTitle}>
                                {item.title}
                            </Box>
                            <Box className={classes.itemTimelineContainer}>
                                <Box className={classes.itemCapacityUtilizations}>
                                    {
                                        item.capacityUtilizations.map((capacityUtilization, j) => renderCapacityUtilization({ item, capacityUtilization, isLast: j === item.capacityUtilizations.length - 1 }))
                                    }
                                </Box>
                            </Box>
                        </Box>
                    ))
                }
            </Box>
            <Box className={classes.bookingEndIndicatorLegend}>
                {
                    ['periodBooking', 'requestedByTenant', 'requestedByOwner', 'missingPayment', 'refund']
                        .map(reason =>
                            (
                                <Box key={reason} className={classes.bookingEndIndicatorLegendItem}>
                                    <Box className={classes[`bookingEndIndicator${toPascalCase(reason)}`]}/>
                                    <Box>{strings.bookingEndReasons[reason]}</Box>
                                </Box>
                            )
                        )
                }
            </Box>
            <ToolTip
                style={tooltipStyle}
                active={tooltipActive}
                position="top"
                align="left"
                arrow="left"
                parent={`#${tooltipElementId}`}
            >
                <Box>
                    {
                        tooltipContent && formatCapacityUtilizationTooltip(tooltipContent.item, tooltipContent.capacityUtilization)
                    }
                </Box>
            </ToolTip>
        </Box>
    );
};

CapacityUtilizationTimelineView.propTypes = {
    items: PropTypes.array.isRequired,
    startDate: PropTypes.instanceOf(Date).isRequired,
    endDate: PropTypes.instanceOf(Date).isRequired,
    onItemClick: PropTypes.func
};

export default CapacityUtilizationTimelineView;
