import { RSAA } from 'redux-api-middleware';
import { createRsaaTypes, createHeaders } from './actionHelpers';
import queryString from 'query-string';

export const TENANTBOOKINGS_FETCH = 'TENANTBOOKINGS_FETCH';
export const TENANTBOOKINGS_FETCH_BY_IMMEDIATE_BOOKING_REQUEST_ID = 'TENANTBOOKINGS_FETCH_BY_IMMEDIATE_BOOKING_REQUEST_ID';
export const TENANTBOOKINGS_CREATE = 'TENANTBOOKINGS_CREATE';
export const TENANTBOOKINGS_UPDATE = 'TENANTBOOKINGS_UPDATE';
export const TENANTBOOKINGS_END_SUBSCRIPTION = 'TENANTBOOKINGS_END_SUBSCRIPTION';
export const TENANTBOOKINGS_EXTEND = 'TENANTBOOKINGS_EXTEND';
export const TENANTBOOKINGS_EXTEND_PREVIEW = 'TENANTBOOKINGS_EXTEND_PREVIEW';
export const TENANTBOOKINGS_CREATE_LOCK_CODE = 'TENANTBOOKINGS_CREATE_LOCK_CODE';
export const TENANTBOOKINGS_FETCH_BY_TOKEN = 'TENANTBOOKINGS_FETCH_BY_TOKEN';
export const TENANTBOOKINGS_CREATE_DRAFT_AGREEMENT = 'TENANTBOOKINGS_CREATE_DRAFT_AGREEMENT';
export const TENANTBOOKINGS_UPDATE_PARAKEY_BOOKING_ITEMS = 'TENANTBOOKINGS_UPDATE_PARAKEY_BOOKING_ITEMS';

export const fetchTenantBooking = (bookingId, includeQrCode) => ({
    [RSAA]: {
        types: createRsaaTypes(TENANTBOOKINGS_FETCH),
        endpoint: `/api/tenantBookings/${bookingId}?${queryString.stringify({ includeQrCode: !!includeQrCode })}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const fetchTenantBookingByImmediateBookingRequestId = (immediateBookingRequestId, delay) => ({
    [RSAA]: {
        types: createRsaaTypes(TENANTBOOKINGS_FETCH_BY_IMMEDIATE_BOOKING_REQUEST_ID),
        endpoint: `/api/tenantBookings/immediateBookingRequests/${immediateBookingRequestId}?${queryString.stringify({ delay })}`,
        headers: createHeaders(false),
        method: 'GET'
    }
});

export const createTenantBooking = createBookingRequest => {
    return {
        [RSAA]: {
            types: createRsaaTypes(TENANTBOOKINGS_CREATE),
            endpoint: '/api/tenantBookings',
            headers: createHeaders(true),
            method: 'POST',
            body: JSON.stringify(createBookingRequest)
        }
    };
};

export const updateTenantBooking = (bookingId, updateBookingRequest) => {
    return {
        [RSAA]: {
            types: createRsaaTypes(TENANTBOOKINGS_UPDATE),
            endpoint: `/api/tenantBookings/${bookingId}`,
            headers: createHeaders(true),
            method: 'PUT',
            body: JSON.stringify(updateBookingRequest)
        }
    };
};

export const endTenantSubscriptionBooking = bookingId => ({
    [RSAA]: {
        types: createRsaaTypes(TENANTBOOKINGS_END_SUBSCRIPTION),
        endpoint: `/api/tenantBookings/${bookingId}/endSubscription`,
        method: 'PUT',
        headers: createHeaders(false)
    }
});

export const extendTenantBooking = (bookingId, extendBookingRequest) => {
    return {
        [RSAA]: {
            types: createRsaaTypes(TENANTBOOKINGS_EXTEND),
            endpoint: `/api/tenantBookings/${bookingId}/extend`,
            headers: createHeaders(true),
            method: 'POST',
            body: JSON.stringify(extendBookingRequest)
        }
    };
};

export const createExtendBookingPreview = (bookingId, extendBookingRequest) => {
    return {
        [RSAA]: {
            types: createRsaaTypes(TENANTBOOKINGS_EXTEND_PREVIEW),
            endpoint: `/api/tenantBookings/${bookingId}/extend/preview`,
            headers: createHeaders(true),
            method: 'POST',
            body: JSON.stringify(extendBookingRequest)
        }
    };
};

export const createBookingLockCode = (bookingId, token) => ({
    [RSAA]: {
        types: createRsaaTypes(TENANTBOOKINGS_CREATE_LOCK_CODE),
        endpoint: `/api/bookings/${bookingId}/lockCodes?${queryString.stringify({ token })}`,
        headers: createHeaders(false),
        method: 'POST'
    }
});

export const unsubscribeFromMarketing = (bookingId, token) => ({
    [RSAA]: {
        types: createRsaaTypes(TENANTBOOKINGS_FETCH_BY_TOKEN),
        endpoint: `/api/bookings/${bookingId}/unsubscribeFromMarketing?${queryString.stringify({ token })}`,
        headers: createHeaders(false),
        method: 'PUT'
    }
});

export const updateParakeyBookingItems = (bookingId, bookingItems) => ({
    [RSAA]: {
        types: createRsaaTypes(TENANTBOOKINGS_UPDATE_PARAKEY_BOOKING_ITEMS),
        endpoint: `/api/tenantBookings/${bookingId}/parakeyBookingItems`,
        headers: createHeaders(true),
        method: 'PUT',
        body: JSON.stringify(bookingItems)
    }
});
