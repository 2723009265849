import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';

import Popover from '@material-ui/core/Popover';
import StorageGroupSelector from './StorageGroupSelector';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

const StorageGroupSelectorPopover = ({ anchorEl, open, onClose, storageSite, selectedCategory, form, isPreview }) => {
    const useStyles = makeStyles(({ theme, colors }) => ({
        popoverContainer: {
            '& .MuiPopover-paper': {
                width: `${anchorEl?.offsetWidth + 48}px`,
                marginLeft: theme.spacing(-3),
                [theme.breakpoints.down('sm')]: {
                    width: `${anchorEl?.offsetWidth}px`,
                    marginLeft: 0
                }
            }
        },
        titleContainer: {
            position: 'sticky',
            display: 'flex',
            justifyContent: 'space-between',
            background: colors.secondaryColor,
            padding: theme.spacing(2),
            top: theme.spacing(-0.125),
            zIndex: 1300,
            border: `1px solid ${colors.secondaryBorderColor}`,
            '& button': {
                color: colors.white,
                padding: 0
            },
            '& h2': {
                fontSize: theme.spacing(2),
                color: colors.white,
                fontStyle: 'normal',
                fontWeight: 'normal',
                lineHeight: '140%',
            }
        }
    }));
    const classes = useStyles();

    return (
        <Popover
            anchorEl={anchorEl}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            onClose={() => onClose()}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            className={classes.popoverContainer}
        >
            <Box>
                <Box className={classes.titleContainer}>
                    <Typography variant="h2">{strings.chooseSize}</Typography>
                    {
                        onClose &&
                        (
                            <IconButton onClick={onClose}>
                                <Close />
                            </IconButton>
                        )
                    }
                </Box>
                <StorageGroupSelector storageSite={storageSite} selectedCategory={selectedCategory} form={form} isPreview={isPreview} closeModal={onClose}  />
            </Box>
        </Popover>
    );
};

StorageGroupSelectorPopover.propTypes = {
    anchorEl: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    storageSite: PropTypes.object.isRequired,
    selectedCategory: PropTypes.object,
    form: PropTypes.object.isRequired,
    isPreview: PropTypes.bool
};

export default StorageGroupSelectorPopover;
