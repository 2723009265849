import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { handleResponse } from 'actions/actionHelpers';
import { getStorageSitesWithCapacityUtilizations } from 'actions/capacityUtilization';
import { startOfDay, add } from 'date-fns';
import { formatIsoDate } from 'helpers/DateHelper';
import strings from 'localization/strings';
import queryString from 'query-string';
import routes from 'routes';
import capacityUtilizationProperties from 'enums/capacityUtilizationProperties';

import PageTitle from 'common/PageTitle';
import ShadowBox from 'common/ShadowBox';
import ButtonContainer from 'common/ButtonContainer';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import CapacityUtilization from 'components/capacityUtilization/CapacityUtilization';
import Loader from 'common/Loader';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const CapacityUtilizationChartContainer = () => {
    const dispatch = useDispatch();
    const params = useParams();

    const storageSiteId = parseInt(params.storageSiteId, 10);

    const [toDate, setToDate] = useState(startOfDay(new Date()));
    const [selectedProperty, setSelectedProperty] = useState(capacityUtilizationProperties.bookedStorageCount.key);
    const [isLoading, setIsLoading] = useState(true);
    const [storageSites, setStorageSites] = useState(undefined);

    const storageSite = storageSites ? storageSites[0] : undefined;

    const fromDate = add(add(toDate, { years: -1 }), { days: 1 });

    useEffect(() => {
        setIsLoading(true);
        const request = {
            fromDate: formatIsoDate(fromDate),
            toDate: formatIsoDate(toDate),
            storageSiteId
        };
        dispatch(getStorageSitesWithCapacityUtilizations(request))
            .then(handleResponse(
                response => {
                    setStorageSites(response.payload);
                    setIsLoading(false);
                },
                () => setIsLoading(false)
            ));
    }, [formatIsoDate(fromDate)]);

    const handlePreviousButtonClick = () => {
        const d = add(toDate, { years: -1 });
        setToDate(d);
    };

    const handleNextButtonClick = () => {
        const d = add(toDate, { years: 1 });
        setToDate(d);
    };

    const handlePropertyChange = property => {
        setSelectedProperty(property);
    };

    const actionPanel = (
        <>
            <IconButton
                disabled={!storageSite}
                onClick={handlePreviousButtonClick}
            >
                <ChevronLeftIcon/>
            </IconButton>
            <IconButton
                disabled={!storageSite}
                onClick={handleNextButtonClick}
            >
                <ChevronRightIcon/>
            </IconButton>
        </>
    );

    const propertyLabel = capacityUtilizationProperties[selectedProperty].title;

    const title = storageSite
        ? `${propertyLabel}: ${storageSite.title}`
        : propertyLabel;

    return (
        <>
            <PageTitle>{title}</PageTitle>
            <ShadowBox title={title} actionPanel={actionPanel}>
                {
                    isLoading && <Loader />
                }
                {
                    !isLoading && storageSite &&
                    (
                        <>
                            <CapacityUtilization
                                storageSites={storageSites}
                                fromDate={fromDate}
                                toDate={toDate}
                                property={selectedProperty}
                                onPropertyChange={handlePropertyChange}
                            />
                            <ButtonContainer>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={Link}
                                    to={routes.account.ownerBookings + '?' + queryString.stringify({ storageSiteId })}
                                >
                                    {strings.showBookingList}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={Link}
                                    to={routes.account.storageSites}
                                >
                                    {strings.showStorageSiteList}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={Link}
                                    to={routes.account.storageSiteBookingOverview.replace(':storageSiteId', storageSiteId)}
                                >
                                    {strings.showBookingOverview}
                                </Button>
                            </ButtonContainer>
                        </>
                    )
                }
            </ShadowBox>
        </>
    );
};

export default CapacityUtilizationChartContainer;
