import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(({ theme }) => ({
    captureContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        alignItems: 'center'
    },
    outerVideoContainer: {
        position: 'relative',
        overflow: 'hidden'
    },
    innerVideoContainer: {
        position: 'absolute'
    },
    imageCanvas: {
        position: 'absolute',
        display: 'none'
    },
    captureImageButton: {
        padding: theme.spacing(1),
        minWidth: 'auto'
    }
}));

// image capture inspired by https://developer.mozilla.org/en-US/docs/Web/API/Media_Capture_and_Streams_API/Taking_still_photos

const ImageCapturer = ({ onCapture }) => {
    const classes = useStyles();
    const captureContainerRef = useRef(null);
    const outerVideoContainerRef = useRef(null);
    const innerVideoContainerRef = useRef(null);
    const videoRef = useRef(null);
    const cachedVideoRef = useRef(null);
    const canvasRef = useRef(null);
    const initializeSizesRef = useRef(null);
    const initializingCameraNowRef = useRef(false);
    // The width and height of the captured image. We will set the
    // width to the value defined here, but the height will be
    // calculated based on the aspect ratio of the input stream.
    const width = 1024; // We will scale the image width to this
    const heightRef = useRef(0); // This will be computed based on the input stream

    const [errorMessage, setErrorMessage] = useState(undefined);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        initializeCamera();
        return () => {
            disposeCamera();
        };
    }, []);

    const handleCapture = () => {
        let imageData;
        const context = canvasRef.current.getContext('2d');
        if (width && heightRef.current) {
            canvasRef.current.width = width;
            canvasRef.current.height = heightRef.current;
            context.drawImage(videoRef.current, 0, 0, width, heightRef.current);
            imageData = canvasRef.current.toDataURL('image/jpeg');
        }
        onCapture({ imageData });
    };

    const initializeCamera = () => {
        initializingCameraNowRef.current = true;
        if(!navigator.mediaDevices) {
            setErrorMessage(strings.couldNotInitializeCamera);
            initializingCameraNowRef.current = false;
        } else {
            navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' }, audio: false })
                .then(stream => {
                    videoRef.current.srcObject = stream;
                    videoRef.current.play();
                    cachedVideoRef.current = videoRef.current;
                    setInitialized(true);
                })
                .catch(error => {
                    setErrorMessage(`${error.name}: ${error.message}`);
                });

            initializeSizesRef.current = initializeSizes;
            videoRef.current.addEventListener('canplay', initializeSizesRef.current, false);
        }
    };

    const disposeCamera = () => {
        const stream = cachedVideoRef.current.srcObject;
        if(stream) {
            stream.getTracks().forEach(track => {
                track.stop();
                stream.removeTrack(track);
            });
        }
        if(initializeSizesRef.current) {
            cachedVideoRef.current.removeEventListener('canplay', initializeSizesRef.current, false);
        }
        cachedVideoRef.current = undefined;
    };

    const initializeSizes = () => {
        heightRef.current = videoRef.current.videoHeight / (videoRef.current.videoWidth / width);

        // Firefox currently has a bug where the height can't be read from
        // the video, so we will make assumptions if this happens.
        if (isNaN(heightRef.current)) {
            heightRef.current = width / (4 / 3);
        }

        const scale = captureContainerRef.current.clientWidth / width;

        videoRef.current.setAttribute('width', width);
        videoRef.current.setAttribute('height', heightRef.current);
        innerVideoContainerRef.current.style.transform = `translate(${-(1 - scale) * width / 2}px ,${-(1 - scale) * heightRef.current / 2}px) scale(${scale})`;
        canvasRef.current.setAttribute('width', width);
        canvasRef.current.setAttribute('height', heightRef.current);
        outerVideoContainerRef.current.style.width = `${captureContainerRef.current.clientWidth}px`;
        outerVideoContainerRef.current.style.height = `${scale * heightRef.current}px`;
        initializingCameraNowRef.current = false;
    };

    return (
        <Box className={classes.captureContainer} ref={captureContainerRef}>
            <Box className={classes.outerVideoContainer} ref={outerVideoContainerRef}>
                <Box className={classes.innerVideoContainer} ref={innerVideoContainerRef}>
                    <video
                        ref={videoRef}
                        playsinline
                        disablepictureinpicture
                        muted
                        autoPlay
                    >
                        {strings.couldNotInitializeCamera}
                    </video>
                    <canvas className={classes.imageCanvas} ref={canvasRef}/>
                </Box>
            </Box>
            {
                errorMessage &&
                (
                    <Alert severity="error">
                        {errorMessage}
                    </Alert>
                )
            }
            <Button
                className={classes.captureImageButton}
                variant="contained"
                color="primary"
                disabled={!initialized}
                onClick={handleCapture}
            >
                <PhotoCameraIcon/>
            </Button>
        </Box>
    );
};

ImageCapturer.propTypes = {
    onCapture: PropTypes.func.isRequired
};

export default ImageCapturer;
