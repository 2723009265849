import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-final-form';
import { makeStyles } from 'styles/util';
import { createUpdateBookingRequest } from './AdminBookingHelper';
import { handleResponse } from 'actions/actionHelpers';
import { createUpcomingPurchasePreview } from 'actions/admin/bookings';
import { formatIsoDate } from 'helpers/DateHelper';
import bookingStatuses from 'enums/bookingStatuses';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import MarginBox from 'common/MarginBox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Amount from 'common/Amount';

const useStyles = makeStyles(() => ({
    newPrice: {
        fontWeight: 'bold'
    }
}));

const SubscriptionPriceChangeInfo = ({ booking, storageSite, previousPrice, newPrice, nextInvoiceTime }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const form = useForm();
    const formValues = form.getState().values;

    const [isLoadingUpcomingPurchasePreview, setIsLoadingUpcomingPurchasePreview] = useState(false);
    const [upcomingPurchasePreview, setUpcomingPurchasePreview] = useState(false);

    const priceChanged = newPrice !== previousPrice;

    const request = createUpdateBookingRequest(formValues, storageSite.additionalServices);

    const isPurchased = booking.bookingStatus === bookingStatuses.purchased.key;

    useEffect(() => {
        if(priceChanged && isPurchased) {
            setIsLoadingUpcomingPurchasePreview(true);
            dispatch(createUpcomingPurchasePreview(booking.id, request))
                .then(handleResponse(
                    response => {
                        setUpcomingPurchasePreview(response.payload);
                        setIsLoadingUpcomingPurchasePreview(false);
                    },
                    () => {
                        setIsLoadingUpcomingPurchasePreview(false);
                    }
                ));
        }
    }, [newPrice, request.effectDate]);

    if(!priceChanged) {
        return null;
    }

    return (
        <MarginBox top={2}>
            <Typography variant="body1" gutterBottom>
                Previous monthly price (including VAT): <Amount value={previousPrice} currency={booking.currency}/>
            </Typography>
            <Typography variant="body1" gutterBottom>
                New monthly price (including VAT): <Box component="span" className={classes.newPrice}><Amount value={newPrice} currency={booking.currency}/></Box>
            </Typography>
            {
                isPurchased &&
                (
                    <Typography variant="body1" component="div">
                        Next invoice (including VAT):
                        {' '}
                        {isLoadingUpcomingPurchasePreview && <CircularProgress size={16} color="primary"/>}
                        {!isLoadingUpcomingPurchasePreview && upcomingPurchasePreview.amount && <><Amount value={upcomingPurchasePreview.amount} currency={upcomingPurchasePreview.currency}/> ({formatIsoDate(nextInvoiceTime)})</>}
                    </Typography>
                )
            }
        </MarginBox>
    );
};

SubscriptionPriceChangeInfo.propTypes = {
    booking: PropTypes.object.isRequired,
    storageSite: PropTypes.object.isRequired,
    previousPrice: PropTypes.number.isRequired,
    newPrice: PropTypes.number.isRequired,
    nextInvoiceTime: PropTypes.string
};

export default SubscriptionPriceChangeInfo;
