import { formatIsoDate, formatShortDate } from 'helpers/DateHelper';
import { formatAmount, formatNumber } from 'helpers/StringHelper';
import { createBookingItemDataObject, createBookingItemDataText, getAdditionalServiceById, getAdditionalServiceConfiguration } from 'helpers/AdditionalServiceHelper';
import { convertToNumber } from 'helpers/StringHelper';

export const MINIMUM_SUBSCRIPTION_BOOKING_LENGTH = 60;

const formatAmountOptions = { skipCurrencySuffix: true, skipThousandSeparators: true };
const formatNumberOptions = { skipThousandSeparators: true, sanitizeMinusSign: true };

export const createBookingItem = (formBookingItem, additionalServices = []) => ({
    ...formBookingItem,
    amount: convertToNumber(formBookingItem.amount) ?? 0,
    vatRate: (convertToNumber(formBookingItem.vatRate) ?? 0) / 100,
    commissionRate: (convertToNumber(formBookingItem.commissionRate) ?? 0) / 100,
    startDate: formatIsoDate(formBookingItem.startDate),
    endDate: formatIsoDate(formBookingItem.endDate),
    data: createBookingItemDataObject(getAdditionalServiceById(additionalServices, formBookingItem.referenceId), formBookingItem)
});

export const createFormBookingItem = (bookingItem, booking, additionalServices, appContext) => {
    const additionalService = getAdditionalServiceById(additionalServices, bookingItem.referenceId);
    return {
        ...bookingItem,
        amount: formatAmount(bookingItem.amount, booking.currency, appContext, formatAmountOptions),
        vatRate: formatNumber(100 * bookingItem.vatRate, appContext, formatNumberOptions),
        commissionRate: formatNumber(100 * bookingItem.commissionRate, appContext, formatNumberOptions),
        startDate: formatShortDate(bookingItem.startDate, appContext),
        endDate: formatShortDate(bookingItem.endDate, appContext),
        data: createBookingItemDataText(additionalService, bookingItem),
        configuration: getAdditionalServiceConfiguration(additionalService)
    };
};
