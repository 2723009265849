import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { shorten } from 'helpers/StringHelper';
import routes from 'routes';
import strings from 'localization/strings';

import { relativeDateFormat } from 'helpers/DateHelper';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(({ theme, colors }) => ({
    conversationListItem: {
        padding: theme.spacing(2.5, 0, 0),
        borderBottom: `1px solid ${colors.lightGreyBorder ?? colors.mediumGrey}`,
    },
    content: {
        flex: '1 1 auto',
    },
    link: {
        width: '100%',
        color: colors.textColor,
        textDecoration: 'none',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    header: {
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'start',
        justifyContent: 'space-between'
    },
    completed: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(0.5)
    },
    message: {
        padding: theme.spacing(3.125, 0),
        display: 'flex',
        justifyContent: 'space-between',
        gap: theme.spacing(2)
    },
    messageText: {
        flexGrow: 1
    },
    messageTime: {
        minWidth: 'max-content'
    }
}));

const ConversationListItem = ({ conversation, actor }) => {
    const classes = useStyles();
    const message = conversation.messages[0];
    const storageSite = conversation.storageSite;

    const formatConversationSubject = title => strings.formatString(strings.accountConversations.subject, title);
    const getCounterpartActor = () => conversation.recipientActor.id === actor.id
        ? conversation.senderActor
        : conversation.recipientActor;

    return (
        <Box className={classes.conversationListItem}>
            <Link
                to={routes.account.conversationDetails.replace(':conversationId', conversation.id)}
                onClick={() => window.scrollTo(0, 0)}
                className={classes.link}
            >
                <Box className={classes.content}>
                    <Box className={classes.header}>
                        <Box>
                            <Typography variant="h5" component="p" className={classes.capitalize}>
                                {getCounterpartActor().name}
                            </Typography>
                            <Typography variant="body2" component="p">{formatConversationSubject(storageSite.title)}</Typography>
                        </Box>
                        {
                            conversation.completed &&
                            (
                                <Typography variant="body2" className={classes.completed}>
                                    <CheckCircleIcon color="primary" />
                                    <Box>{strings.completed}</Box>
                                </Typography>
                            )
                        }
                    </Box>
                    <Box className={classes.message}>
                        <Typography variant="h5" component="p" className={classes.messageText}>{shorten(message.text, 160, true)}</Typography>
                        <Typography variant="body2" className={classes.messageTime}>{relativeDateFormat(message.createdTime)}</Typography>
                    </Box>
                </Box>
            </Link>
        </Box>
    );
};

ConversationListItem.propTypes = {
    conversation: PropTypes.object.isRequired,
    actor: PropTypes.object.isRequired
};

export default ConversationListItem;
