import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { getStorageGroupTitle } from 'helpers/StorageSiteHelper';
import { getPriceInfo } from 'helpers/PriceCalculator';
import { formatLengthAndWidth } from 'helpers/StringHelper';

import Radio from '@material-ui/core/Radio';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Amount from 'common/Amount';

const useStyles = makeStyles(({ theme, colors }) => ({
    textInput: {
        '& .MuiOutlinedInput-root': {
            width: '70px',
            margin: theme.spacing(1),
            '& input': {
                textAlign: 'right',
                padding: theme.spacing(1, 2),
            }
        },
    },
    disabled: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '0',
            width: '70px',
            margin: theme.spacing(1),
            '& input': {
                textAlign: 'right',
                padding: theme.spacing(1, 2),
                background: 'lightestGrey'
            }
        },
    },
    containerFixedSize: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: colors.white,
        padding: theme.spacing(0.375, 2, 0.375, 0),
        borderBottom: `1px solid ${colors.secondaryColor}`,
        color: 'black',
        '&:last-child': {
            borderBottom: 'none'
        },
        '&:hover': {
            backgroundColor: colors.whiteHover
        }
    },
    containerCustomSize: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2, 2, 0),
        backgroundColor: colors.white,
        marginBottom: theme.spacing(2),
        color: 'black',
        [theme.breakpoints.down('sm')]: {
            background: 'none',
            color: colors.white,
            padding: theme.spacing(1, 0)
        },
        '&:hover': {
            backgroundColor: colors.whiteHover
        },
    },
    radioButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        flexWrap: 'wrap',
        cursor: 'pointer'
    },
    storageGroupTitle: {
        marginRight: theme.spacing(1)
    },
    priceContainer: {
        textAlign: 'right',
        marginLeft: 'auto'
    },
    customAreaField: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiFormControl-fullWidth': {
            width: 'auto'
        }
    },
    price: {
        color: `${colors.primaryColor}`,
    },
    notBookable: {
        color: `${colors.textColorDisabled} !important`,
        '& h6': {
            color: `${colors.textColorDisabled} !important`
        }
    },
    measurementPadding: {
        paddingRight: theme.spacing(2)
    },
    lengthAndWidth: {
        fontSize: '65%',
        color: colors.textColorLight
    }
}));

const StorageGroupItem = ({ storageGroup, value, isBookable, disabled, showLengthAndWidth, onChange, closeModal }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    const fixedAreaPriceInfo = getPriceInfo(storageGroup.pricePerStorage, appContext, storageGroup);
    const fieldName = `categories.${storageGroup.category}.storageGroupId`;
    const onClick = () => {
        if (isBookable && !disabled) {
            onChange({name: fieldName, value});
            closeModal();
        }
    };

    return (
        <Box className={`${classes.containerFixedSize} ${!isBookable && classes.notBookable}`}>
            <Radio name={fieldName} value={value} disabled={disabled || !isBookable} onChange={closeModal}/>
            <Box className={classes.radioButton} onClick={onClick}>
                <Box className={classes.storageGroupTitle}>
                    <Typography variant="subtitle1">{getStorageGroupTitle(storageGroup, appContext, { useSpecialCustomAreaLabel: true })}</Typography>
                    {
                        showLengthAndWidth && storageGroup.storageLength && storageGroup.storageWidth &&
                        (
                            <Box className={classes.lengthAndWidth}>{formatLengthAndWidth(storageGroup.storageLength, storageGroup.storageWidth, appContext, { numberOfDecimals: 1 })}</Box>
                        )
                    }
                </Box>
                <Box className={classes.priceContainer}>
                    {
                        isBookable &&
                        (
                            <Typography variant="subtitle1" display="block" className={classes.price}><Amount value={fixedAreaPriceInfo.displayPrice} currency={fixedAreaPriceInfo.currency}/>/{strings.monthAbbreviated}</Typography>
                        )
                    }
                    {
                        !isBookable &&
                        (
                            <Typography variant="subtitle1" display="block">{strings.notBookableShort}</Typography>
                        )
                    }
                </Box>
            </Box>
        </Box>
    );
};

StorageGroupItem.propTypes = {
    storageGroup: PropTypes.object.isRequired,
    value: PropTypes.any,
    isBookable: PropTypes.bool,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    showLengthAndWidth: PropTypes.bool,
    onChange: PropTypes.func,
    closeModal: PropTypes.func
};

export default StorageGroupItem;
