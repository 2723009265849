import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ButtonLink from 'common/ButtonLink';
import IconPrefix from 'common/IconPrefix';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

const BookingLockCodeButton = ({ booking }) => {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    return (
        <ButtonLink
            target="_blank"
            variant="contained"
            href={booking.lockCodeUrl}
            fullWidth={isMobile}
        >
            <IconPrefix icon={<VpnKeyIcon/>} singleLineHeight>
                {strings.bookingLockCodeLink}
            </IconPrefix>
        </ButtonLink>
    );
};

BookingLockCodeButton.propTypes = {
    booking: PropTypes.object.isRequired
};

export default BookingLockCodeButton;
