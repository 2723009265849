import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { formatPurchaseItemDescription } from 'helpers/PurchaseItemHelper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ReadOnlyField from '../../presentational/ReadOnlyField';
import Amount from 'common/Amount';
import Percentage from 'common/Percentage';

const useStyles = makeStyles(() => ({
    nowrap: {
        whiteSpace: 'nowrap'
    }
}));

const formatAmountOptions = { displayPriceRounding: false };

const PurchaseItemList = ({ purchaseItems }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    return (
        <ReadOnlyField label="Purchase items">
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell align="right">Amount excl. VAT</TableCell>
                        <TableCell align="right">VAT rate</TableCell>
                        <TableCell align="right">VAT</TableCell>
                        <TableCell align="right">Commission rate</TableCell>
                        <TableCell>Reference ID</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        purchaseItems.map(purchaseItem => (
                            <TableRow key={purchaseItem.id}>
                                <TableCell>{formatPurchaseItemDescription(purchaseItem, appContext)}</TableCell>
                                <TableCell align="right" className={classes.nowrap}><Amount value={purchaseItem.amount} currency={purchaseItem.currency} {...formatAmountOptions} /></TableCell>
                                <TableCell align="right" className={classes.nowrap}><Percentage value={purchaseItem.vatRate}/></TableCell>
                                <TableCell align="right" className={classes.nowrap}><Amount value={purchaseItem.vat} currency={purchaseItem.currency} {...formatAmountOptions} /></TableCell>
                                <TableCell align="right" className={classes.nowrap}><Percentage value={purchaseItem.commissionRate}/></TableCell>
                                <TableCell>{purchaseItem.referenceId}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </ReadOnlyField>
    );
};

PurchaseItemList.propTypes = {
    purchaseItems: PropTypes.array.isRequired
};

export default PurchaseItemList;
