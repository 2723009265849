import React from 'react';
import PropTypes from 'prop-types';
import organizationTypes from 'enums/organizationTypes';
import routes from 'routes';
import { createEndAdornment } from 'helpers/FormHelper';
import { getContact } from 'helpers/ActorHelper';
import contactTypes from 'enums/contactTypes';

import AdminFormWrapper from '../../presentational/AdminFormWrapper';
import AdminFormFields from '../../presentational/AdminFormFields';
import ReadOnlyField from '../../presentational/ReadOnlyField';
import AdminFormButtons from '../../presentational/AdminFormButtons';
import Form from 'form/Form';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { TextField, Checkboxes } from 'mui-rff';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';

const EditActorForm = ({ actor, isSubmitting = false, companies, onSubmit, onCancel }) => {
    const initialValues = {
        isOwner: actor.isOwner,
        organizationType: actor.organizationType,
        name: actor.name,
        firstName: actor.firstName,
        lastName: actor.lastName,
        email: getContact(actor, contactTypes.mainEmail),
        checkInEmail: getContact(actor, contactTypes.checkInEmail),
        mobileTelephone: getContact(actor, contactTypes.mainMobileTelephone),
        checkInMobileTelephone: getContact(actor, contactTypes.checkInMobileTelephone),
        sendSms: actor.sendSms,
        commissionRate: 100 * actor.commissionRate,
        organizationNumber: actor.organizationNumber,
        vatIdentificationNumber: actor.vatIdentificationNumber,
        isBlocked: actor.isBlocked,
        backofficeNote: actor.backofficeNote,
        parentCompanyId: actor.parentCompanyId ?? 0,
        isCertifiedPartner: actor.isCertifiedPartner
    };

    const companyListItems = [{
        id: 0,
        name: '[None]'
    }].concat((companies || []));

    return (
        <AdminFormWrapper isSubmitting={isSubmitting}>
            <Form
                initialValues={initialValues}
                onSubmit={onSubmit}
            >
                {({ values, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <AdminFormFields>

                            <ReadOnlyField label="Id">
                                {actor.id}
                            </ReadOnlyField>

                            <TextField
                                select
                                name="organizationType"
                                label="Organization type"
                                variant="outlined"
                            >
                                {
                                    Object.values(organizationTypes).map(item =>
                                        <MenuItem key={item.key} value={item.key}>{item.title}</MenuItem>
                                    )
                                }
                            </TextField>

                            {
                                values.organizationType === organizationTypes.business.key &&
                                (
                                    <>
                                        <TextField
                                            name="name"
                                            label="Name"
                                            type="text"
                                            variant="outlined"
                                        />

                                        <TextField
                                            name="organizationNumber"
                                            label="Organization number"
                                            type="text"
                                            variant="outlined"
                                        />

                                        <TextField
                                            name="vatIdentificationNumber"
                                            label="VAT identification number"
                                            type="text"
                                            variant="outlined"
                                        />

                                        <TextField
                                            select
                                            name="parentCompanyId"
                                            label="Parent company"
                                            variant="outlined"
                                        >
                                            {
                                                companyListItems.map(item =>
                                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                )
                                            }
                                        </TextField>
                                    </>
                                )
                            }

                            {
                                values.organizationType === organizationTypes.private.key &&
                                (
                                    <>
                                        <TextField
                                            name="firstName"
                                            label="First name"
                                            type="text"
                                            variant="outlined"
                                        />

                                        <TextField
                                            name="lastName"
                                            label="Last name"
                                            type="text"
                                            variant="outlined"
                                        />

                                        <ReadOnlyField label="Personal identity type">
                                            {actor.personalIdentityType}
                                        </ReadOnlyField>

                                        <ReadOnlyField label="Personal identity">
                                            {actor.personalIdentity}
                                        </ReadOnlyField>
                                    </>
                                )
                            }

                            <TextField
                                name="email"
                                label="Email"
                                type="email"
                                variant="outlined"
                            />

                            <TextField
                                name="mobileTelephone"
                                label="Mobile telephone number"
                                type="text"
                                variant="outlined"
                            />

                            <Checkboxes
                                name="sendSms"
                                data={{
                                    label: 'Send SMS',
                                    value: true
                                }}
                            />

                            <TextField
                                name="backofficeNote"
                                label="Note"
                                type="text"
                                variant="outlined"
                                multiline
                                rows={3}
                                helperText="For internal use only. Not shown for the actor."
                            />

                            <Checkboxes
                                name="isBlocked"
                                data={{
                                    label: 'Blocked',
                                    value: true
                                }}
                            />

                            <Checkboxes
                                name="isOwner"
                                data={{
                                    label: 'Owner',
                                    value: true
                                }}
                            />

                            {
                                values.isOwner &&
                                (
                                    <>
                                        <TextField
                                            name="checkInEmail"
                                            label="Check-in email"
                                            type="email"
                                            variant="outlined"
                                        />

                                        <TextField
                                            name="checkInMobileTelephone"
                                            label="Check-in mobile telephone number"
                                            type="text"
                                            variant="outlined"
                                        />

                                        <TextField
                                            name="commissionRate"
                                            label="Commission rate"
                                            type="text"
                                            variant="outlined"
                                            InputProps={createEndAdornment('%')}
                                            helperText="Note that the commission rate can be overridden on storage site level. Changing this value will also update the commission rate for all ongoing bookings."
                                        />

                                        <Checkboxes
                                            name="isCertifiedPartner"
                                            data={{
                                                label: 'Certified partner',
                                                value: true
                                            }}
                                        />

                                        {
                                            actor.stripeDashboardAccountUrl &&
                                            (
                                                <ReadOnlyField label="Stripe account ID">
                                                    <a href={actor.stripeDashboardAccountUrl} target="_blank">{actor.stripeAccountId}</a>
                                                </ReadOnlyField>
                                            )
                                        }
                                    </>
                                )
                            }

                            <ReadOnlyField label="Users">
                                <List disablePadding>
                                    {
                                        actor.users.map(user => (
                                            <ListItem key={user.id} disableGutters>
                                                <ListItemText>
                                                    <Link to={routes.admin.user.replace(':userId', user.id)}>{user.name}</Link>
                                                </ListItemText>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </ReadOnlyField>
                        </AdminFormFields>

                        <AdminFormButtons>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Save
                            </Button>
                            <Button
                                type="button"
                                variant="outlined"
                                color="primary"
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </AdminFormButtons>
                    </form>
                )}
            </Form>
        </AdminFormWrapper>
    );
};

EditActorForm.propTypes = {
    actor: PropTypes.object.isRequired,
    companies: PropTypes.array.isRequired,
    isSubmitting: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default EditActorForm;
