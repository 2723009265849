import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';
import { getLongDateFormat, formatIsoDate } from 'helpers/DateHelper';
import { mustBeGreaterThan, mustBeNullOrGreaterThan } from 'form/validation';
import { createEndAdornment } from 'helpers/FormHelper';

import Box from '@material-ui/core/Box';
import { TextField, showErrorOnBlur } from 'mui-rff';
import NullableDatePicker from 'form/NullableDatePicker';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

const useStyles = makeStyles(({ theme, colors }) => ({
    container: {
        border: `2px solid ${colors.lightGreyBorder}`,
        borderRadius: '6px',
        padding: theme.spacing(2, 2, 0, 2),
        marginBottom: theme.spacing(2)
    },
    firstLineContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2)
    },
    secondLineContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2)
    },
    date: {
        flex: '1 1 50%'
    },
    delete: {
        flex: '0 0 auto'
    },
    percentage: {
        flex: '1 1 33%'
    },
    numberOfMonths: {
        flex: '1 1 33%'
    },
    minimumBookingLength: {
        flex: '1 1 33%'
    }
}));

const StorageSiteDiscountEditor = ({ fieldNamePrefix, onRemove }) => {
    const classes = useStyles();

    const getName = suffix => suffix ? `${fieldNamePrefix}.${suffix}` : fieldNamePrefix;
    const getValue = suffix => useField(getName(suffix)).input.value;

    return (
        <Box className={classes.container}>
            <Box className={classes.firstLineContainer}>
                <Box className={classes.date}>
                    <NullableDatePicker
                        name={getName('startDate')}
                        label={strings.fromUppercase}
                        formatFunc={getLongDateFormat}
                        inputVariant="outlined"
                        showError={showErrorOnBlur}
                        nullString={strings.noLimitation}
                        nullButtonLabel={strings.noLimitation}
                        fieldProps={{ maxDate: formatIsoDate(getValue('endDate')) }}
                    />
                </Box>
                <Box className={classes.date}>
                    <NullableDatePicker
                        name={getName('endDate')}
                        label={strings.toUppercase}
                        formatFunc={getLongDateFormat}
                        inputVariant="outlined"
                        showError={showErrorOnBlur}
                        nullString={strings.noLimitation}
                        nullButtonLabel={strings.noLimitation}
                        fieldProps={{ minDate: formatIsoDate(getValue('startDate')) }}
                    />
                </Box>
                <IconButton
                    className={classes.delete}
                    onClick={onRemove}
                >
                    <DeleteOutlineOutlinedIcon/>
                </IconButton>
            </Box>

            <Box className={classes.secondLineContainer}>
                <Box className={classes.percentage}>
                    <TextField
                        name={getName('percentage')}
                        label={strings.percentage}
                        variant="outlined"
                        required
                        fieldProps={{ validate: mustBeGreaterThan(0) }}
                        InputProps={createEndAdornment('%')}
                        showError={showErrorOnBlur}
                    />
                </Box>
                <Box className={classes.numberOfMonths}>
                    <TextField
                        name={getName('numberOfMonths')}
                        label={strings.discountNumberOfMonths}
                        variant="outlined"
                        showError={showErrorOnBlur}
                    />
                </Box>
                <Box className={classes.minimumBookingLength}>
                    <TextField
                        name={getName('minimumBookingLength')}
                        label={strings.minimumBookingLength}
                        variant="outlined"
                        fieldProps={{ validate: mustBeNullOrGreaterThan(0) }}
                        showError={showErrorOnBlur}
                        helperText={strings.minimumBookingLengthHelperText}
                    />
                </Box>
            </Box>
        </Box>
    );
};

StorageSiteDiscountEditor.propTypes = {
    fieldNamePrefix: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired
};

export default StorageSiteDiscountEditor;
