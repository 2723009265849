export const roundAmount = (amount, arg1 /* appContext (object) or number of decimals (int) */, arg2 /* useDisplayPriceRounding, only if arg1 is appContext */) => {
    let numberOfDecimals;
    if(typeof arg1 === 'object') {
        const appContext = arg1;
        const useDisplayPriceRounding = arg2 || false;
        numberOfDecimals = useDisplayPriceRounding
            ? appContext.currency.numberOfDecimals.displayPriceRounding
            : appContext.currency.numberOfDecimals.exact;
    } else {
        numberOfDecimals = !arg1 && arg1 !== 0
            ? 2
            : arg1;
    }

    if(amount === undefined || isNaN(amount)) {
        return undefined;
    }
    // round away from zero if halfway in between steps
    const factor = Math.pow(10, numberOfDecimals);
    return Math.sign(amount) * Math.round(factor * Math.sign(amount) * amount) / factor;
};

export const calculateVat = (amountExcludingVat, vatRate, appContext) => roundAmount((vatRate ?? 0) * amountExcludingVat, appContext);
