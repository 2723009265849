import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import strings from 'localization/strings';
import { fetchStorageGroupBookingCalendar } from 'actions/storageGroups';
import { requiredIf, mustBeNumberIf } from 'form/validation';
import { handleResponse } from 'actions/actionHelpers';
import { getBookableStorages } from 'logic/bookingLogic';
import paymentRecurrences from 'enums/paymentRecurrences';
import discountTypes from 'enums/discountTypes';
import bookingItemTypes from 'enums/bookingItemTypes';
import arrayMutators from 'final-form-arrays';
import { roundAmount, calculateVat } from 'helpers/MonetaryHelper';
import { convertToNumber } from 'helpers/StringHelper';
import { createBookingPriceInfo } from 'helpers/PriceCalculator';

import { isProduction } from 'helpers/EnvironmentHelper';
import { createEndAdornment } from 'helpers/FormHelper';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from 'form/Form';
import { TextField, Checkboxes, showErrorOnBlur } from 'mui-rff';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Loader from 'common/Loader';
import LoadingError from 'common/LoadingError';
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FieldArray } from 'react-final-form-arrays';
import DiscountEditor from './DiscountEditor';
import Alert from '@material-ui/lab/Alert';
import Separator from 'common/Separator';
import AcceptPriceSpecification from './AcceptPriceSpecification';

const useStyles = makeStyles(({ theme }) => ({
    alert: {
        marginBottom: theme.spacing(2)
    },
    assignStorage: {
        marginBottom: theme.spacing(2)
    },
    requestedServiceBookingItem: {
        margin: theme.spacing(2, 0)
    },
    requestedServiceBookingItemGrid: {
        alignItems: 'center'
    },
    discountInformation: {
        marginBottom: theme.spacing(2)
    },
    discountAccordion: {
        marginBottom: theme.spacing(2)
    },
    discountAccordionDetails: {
        flexDirection: 'column'
    }
}));

const AcceptDialog = ({ booking, open, onOk, onCancel }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { appContext } = useAppContext();

    const stripeAccountInfo = useSelector(state => state.stripe.stripeAccountInfo);
    const showNoStripeAccountError = isProduction(appContext) && !stripeAccountInfo;
    const authenticationContext = useSelector(state => state.authentication.context);

    const [bookingCalendar, setBookingCalendar] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(undefined);

    const initialValues = {
        storageId: booking.storage
            ? booking.storage.id
            : -1,
        requestedServiceBookingItem: {
            description: undefined,
            amount: undefined,
            eligibleForVat: false,
            delete: false,
            paymentRecurrence: paymentRecurrences.oneTime.key
        },
        discounts: booking.bookingItems
            .filter(o => o.type === bookingItemTypes.discount.key)
            .map(o => ({
                id: o.id,
                description: o.description,
                value: -o.amount,
                // TODO: eligibleForVat
                type: discountTypes.amount.key,
                typeChangeable: false,
                paymentRecurrence: o.paymentRecurrence,
                referenceId: o.referenceId,
                data: o.data,
                deleted: false
            }))
    };

    useEffect(() => {
        if(open && booking.storageGroup.enumerateStorages) {
            setIsLoading(true);
            dispatch(fetchStorageGroupBookingCalendar(booking.storageGroup.id, booking.id))
                .then(handleResponse(
                    response => {
                        setBookingCalendar(response.payload);
                        setIsLoading(false);
                    },
                    () => {
                        setLoadingError(true);
                        setIsLoading(false);
                    }
                ));
        }
    }, [open]);

    const bookableStorages = bookingCalendar
        ? getBookableStorages(booking.storageGroup, bookingCalendar, booking.startDate, booking.endDate)
        : undefined;
    const storageInfos = bookableStorages
        ? (bookableStorages.length > 0 ? [{ title: strings.assignedRandomly, storageId: -1, isBookable: true }] : []) // only show "assign randomly" when there are storages bookable
            .concat(booking.storageGroup.storages
                .map(storage => ({
                    storageId: storage.id,
                    title: storage.title,
                    isBookable: bookableStorages.find(o => o.id === storage.id) !== undefined || storage.id === initialValues.storageId
                }))
            )
        : undefined;

    const formatStorageInfo = storageInfo => storageInfo.isBookable
        ? storageInfo.title
        : <span className={classes.notAvailable}>{storageInfo.title} - {strings.notAvailable}</span>;

    const showAttentionText = booking.storageGroup.storageSite.attentionText && authenticationContext.isSuperAdminOrImpersonated;

    // should we give a possibility to set a price for free-text additional services?
    const requestedService = booking.bookingItems.find(o => o.type === bookingItemTypes.requestedService.key);
    const showPriceEditor = requestedService !== undefined;
    const requestedServiceText = showPriceEditor
        ? requestedService.description
        : undefined;

    const handleFormSubmit = values => {
        const sanitizedValues = {
            ...values
        };
        if(sanitizedValues.storageId === -1) {
            sanitizedValues.storageId = undefined;
        }
        sanitizedValues.bookingItems = getAllBookingItems(values)
            .filter(bookingItem => bookingItem.type !== bookingItemTypes.storage.key); // storage booking item added in backend
        onOk(sanitizedValues);
    };

    const handleRemoveDiscount = (fields, index) => {
        fields.update(index, { ...fields.value[index], deleted: true });
    };

    const handleAddDiscount = fields => {
        const newDiscount = {
            description: strings.discount,
            value: undefined,
            type: discountTypes.amount.key,
            // TODO: eligibleForVat
            typeChangeable: true, // TODO: false if multiple VAT rates,
            paymentRecurrence: undefined, // implicitly determined by discount.type
            deleted: false
        };
        fields.push(newDiscount);
    };

    const createRequestedServiceBookingItem = formValues => {
        if(formValues.requestedServiceBookingItem.delete || !formValues.requestedServiceBookingItem.amount) {
            return undefined;
        }
        const currency = booking.storageGroup.currency;
        const amount = roundAmount(convertToNumber(formValues.requestedServiceBookingItem.amount), appContext);
        const vatRate = formValues.requestedServiceBookingItem.eligibleForVat
            ? appContext.defaultVatRate
            : 0;

        return {
            description: formValues.requestedServiceBookingItem.description,
            amount,
            vat: calculateVat(amount, vatRate, appContext),
            vatRate,
            currency: currency,
            paymentRecurrence: formValues.requestedServiceBookingItem.paymentRecurrence,
            type: bookingItemTypes.service.key // the requested service now becomes a service
        };
    };

    const createDiscountBookingItems = formValues => {
        const currency = booking.storageGroup.currency;
        const vatRate = booking.bookingItems.find(o => o.type === bookingItemTypes.storage.key).vatRate;
        return formValues.discounts
            .map(discount => {
                let amount;
                let paymentRecurrence;
                if(discount.type === discountTypes.amount.key) {
                    amount = roundAmount(-(convertToNumber(discount.value)), appContext);
                    paymentRecurrence = discount.paymentRecurrence ?? paymentRecurrences.oneTime.key;
                } else {
                    // TODO: percentual discounts should only be allowed when all vat rates are the same
                    amount = roundAmount(-(Math.max(0, Math.min(1, convertToNumber(discount.value) / 100))) * booking.priceInfo.perMonth.amounts.storageAmount, appContext);
                    paymentRecurrence = discount.paymentRecurrence ?? paymentRecurrences.perMonth.key;
                }

                return {
                    id: discount.id,
                    description: discount.description,
                    amount,
                    vat: calculateVat(amount, vatRate, appContext), // TODO: use eligibleForVat instead
                    vatRate, // TODO: use eligibleForVat instead
                    currency,
                    paymentRecurrence,
                    type: bookingItemTypes.discount.key,
                    referenceId: discount.referenceId,
                    data: discount.data,
                    deleted: discount.deleted
                };
            });
    };

    const fullWidth = showPriceEditor || booking.storageGroup.enumerateStorages;

    const hasDiscountBookingItems = booking.bookingItems.some(o => o.type === bookingItemTypes.discount.key);

    const getEditableBookingItems = formValues => {
        const bookingItems = [];

        const requestedServiceBookingItem = createRequestedServiceBookingItem(formValues);
        if(requestedServiceBookingItem) {
            bookingItems.push(requestedServiceBookingItem);
        }

        createDiscountBookingItems(formValues).forEach(o => bookingItems.push(o));

        return bookingItems.filter(o => o.amount);
    };

    const getAllBookingItems = formValues => {
        const editableBookingItems = getEditableBookingItems(formValues);
        const editableBookingItemIds = editableBookingItems
            .map(o => o.id)
            .filter(o => o);
        return [
                ...booking.bookingItems.filter(bi => !editableBookingItemIds.includes(bi.id)),
                ...editableBookingItems
            ]
            .filter(o => !o.deleted);
    };

    return (
        <Dialog fullWidth={fullWidth} maxWidth="md" open={open} onClose={onCancel}>
            <Form
                initialValues={initialValues}
                mutators={arrayMutators}
                onSubmit={handleFormSubmit}
            >
                {({ handleSubmit, invalid, values }) => {
                    let validationKeyCount = 0;
                    const bookingItems = getAllBookingItems(values);
                    const bookingPriceInfo = createBookingPriceInfo(booking, bookingItems, appContext);
                    const requestedServiceBookingItemRequired = !values.requestedServiceBookingItem.delete;
                    const canSubmit =
                        !invalid &&
                        !loadingError &&
                        !showNoStripeAccountError &&
                        (bookingPriceInfo.perMonth?.amounts.totalAmount ?? 0) >= 0 &&
                        (bookingPriceInfo.wholePeriod?.amounts.totalAmount ?? 0) >= 0;
                    const acceptDialogBody = showPriceEditor
                        ? strings.acceptDialogAdjustBody
                        : strings.acceptDialogBody;
                    return (
                        <form onSubmit={handleSubmit}>
                            <DialogTitle disableTypography>
                                <Typography variant="h5">
                                    {strings.acceptDialogTitle}
                                </Typography>
                            </DialogTitle>
                            <DialogContent>
                                {
                                    showAttentionText &&
                                    (
                                        <Alert severity="info" className={classes.alert}>
                                            {strings.thisStorageSiteHasAnAttentionText}: <strong>{booking.storageGroup.storageSite.attentionText}</strong>
                                        </Alert>
                                    )
                                }
                                {
                                    showNoStripeAccountError &&
                                    (
                                        <Alert severity="error" className={classes.alert}>
                                            {strings.stripeAccountMustBeProvidedToApproveBooking}
                                        </Alert>
                                    )
                                }
                                <Typography variant="body1" gutterBottom>
                                    {acceptDialogBody}
                                </Typography>
                                { isLoading && <Loader /> }
                                { loadingError && <LoadingError /> }
                                {
                                    !isLoading && !loadingError &&
                                    (
                                        <>
                                            {
                                                storageInfos &&
                                                (
                                                    <>
                                                        <Separator spacing={2}/>
                                                        <Box className={classes.assignStorage}>
                                                            <Typography variant="body1" gutterBottom>
                                                                {strings.assignStorageInformation}
                                                                {(booking.storage ? ' ' + strings.formatString(strings.tenantSelectedX, booking.storage.title) : '')}
                                                            </Typography>
                                                            <TextField
                                                                select
                                                                name="storageId"
                                                                variant="outlined"
                                                                autoFocus
                                                            >
                                                                {
                                                                    storageInfos.map(storageInfo => (
                                                                        <MenuItem key={(storageInfo.storageId ?? '')} value={storageInfo.storageId}>
                                                                            {formatStorageInfo(storageInfo)}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </TextField>
                                                        </Box>
                                                    </>
                                                )
                                            }
                                            {
                                                showPriceEditor &&
                                                (
                                                    <>
                                                        <Separator spacing={2}/>
                                                        <Box>
                                                            {strings.acceptDialogServicesBody}
                                                            {' '}
                                                            {booking.subscriptionBooking && strings.acceptDialogSubscriptionInfo}
                                                            <Box className={classes.requestedServiceBookingItem}>
                                                                {requestedServiceText}
                                                            </Box>
                                                            <Grid container spacing={2} className={classes.requestedServiceBookingItemGrid}>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        name="requestedServiceBookingItem.description"
                                                                        label={strings.additionalServicesText}
                                                                        variant="outlined"
                                                                        showError={showErrorOnBlur}
                                                                        required={requestedServiceBookingItemRequired}
                                                                        fieldProps={{ validate: requiredIf(requestedServiceBookingItemRequired) }}
                                                                        // we need a key prop, see https://codesandbox.io/s/changing-field-level-validators-zc8ei?fontsize=14&file=/src/index.js:1722-1771
                                                                        key={requestedServiceBookingItemRequired ? validationKeyCount++ : -(validationKeyCount++)}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6} md={4}>
                                                                    <TextField
                                                                        name="requestedServiceBookingItem.amount"
                                                                        label={strings.additionalServicesPrice}
                                                                        placeholder={strings.enterAmountExcludingVatPlaceholder}
                                                                        variant="outlined"
                                                                        showError={showErrorOnBlur}
                                                                        required={requestedServiceBookingItemRequired}
                                                                        fieldProps={{ validate: mustBeNumberIf(requestedServiceBookingItemRequired) }}
                                                                        InputProps={createEndAdornment(appContext.currency.label)}
                                                                        // we need a key prop, see https://codesandbox.io/s/changing-field-level-validators-zc8ei?fontsize=14&file=/src/index.js:1722-1771
                                                                        key={requestedServiceBookingItemRequired ? validationKeyCount++ : -(validationKeyCount++)}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={6} md={4}>
                                                                    <Checkboxes
                                                                        name="requestedServiceBookingItem.eligibleForVat"
                                                                        data={
                                                                            { label: strings.eligibleForVat, value: true }
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={4}>
                                                                    <TextField
                                                                        select
                                                                        name="requestedServiceBookingItem.paymentRecurrence"
                                                                        label={strings.paymentRecurrence}
                                                                        variant="outlined"
                                                                    >
                                                                        {
                                                                            Object.values(paymentRecurrences).map(pt =>
                                                                                <MenuItem key={pt.key} value={pt.key}>{pt.title}</MenuItem>
                                                                            )
                                                                        }
                                                                    </TextField>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Checkboxes
                                                                        name="requestedServiceBookingItem.delete"
                                                                        data={
                                                                            { label: strings.deleteAdditionalService, value: true }
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </>
                                                )
                                            }

                                            <Separator spacing={2}/>

                                            <Accordion className={classes.discountAccordion} defaultExpanded={hasDiscountBookingItems}>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                    <Typography>{strings.discounts}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails className={classes.discountAccordionDetails}>
                                                    <FieldArray name="discounts">
                                                        {({ fields }) => (
                                                            <>
                                                                <Alert severity="info" className={classes.discountInformation}>
                                                                    {(booking.subscriptionBooking ? strings.subscriptionBookingDiscountInformation : strings.periodBookingDiscountInformation)}
                                                                </Alert>
                                                                <Box>
                                                                    {
                                                                        fields.map((name, index) => (
                                                                            <>
                                                                                {
                                                                                    !fields.value[index].deleted &&
                                                                                    (
                                                                                        <DiscountEditor
                                                                                            key={name}
                                                                                            booking={booking}
                                                                                            discount={fields.value[index]}
                                                                                            fieldNamePrefix={name}
                                                                                            onRemove={() => handleRemoveDiscount(fields, index)}
                                                                                        />
                                                                                    )
                                                                                }
                                                                            </>
                                                                        ))
                                                                    }
                                                                </Box>
                                                                <Box>
                                                                    <Button
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        onClick={() => handleAddDiscount(fields)}
                                                                    >
                                                                        {strings.addDiscount}
                                                                    </Button>
                                                                </Box>
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                </AccordionDetails>
                                            </Accordion>

                                            <AcceptPriceSpecification booking={booking} bookingPriceInfo={bookingPriceInfo} />
                                        </>
                                    )
                                }
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={!canSubmit}
                                >
                                    {strings.accept}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={onCancel}
                                >
                                    {strings.cancel}
                                </Button>
                            </DialogActions>
                        </form>
                    );
                }}
            </Form>
        </Dialog>
    );
};

AcceptDialog.propTypes = {
    booking: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default AcceptDialog;
