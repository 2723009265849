import React from 'react';
import { useAppContext } from 'context/AppContext';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import bookingItemTypes from 'enums/bookingItemTypes';
import additionalServiceTypes from 'enums/additionalServiceTypes';
import { getAdditionalServiceById } from 'helpers/AdditionalServiceHelper';
import { createBookingItem, createFormBookingItem } from 'helpers/BookingHelper';
import arrayMutators from 'final-form-arrays';
import routes from 'routes';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from 'form/Form';
import { TextField } from 'mui-rff';
import { FieldArray } from 'react-final-form-arrays';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import MarginBox from 'common/MarginBox';
import Text from 'common/Text';
import Typography from '@material-ui/core/Typography';

const ParakeyAdditionalUsersDialog = ({ booking, storageSite, open, onOk, onCancel }) => {
    const { appContext } = useAppContext();

    const parakeyAdditionalUserBookingItems = booking.bookingItems
        .filter(o => o.type === bookingItemTypes.service.key && getAdditionalServiceById(storageSite.additionalServices, o.referenceId)?.type === additionalServiceTypes.parakeyAdditionalUser.key);

    const initialValues = {
        bookingItems: parakeyAdditionalUserBookingItems.map(bookingItem => createFormBookingItem(bookingItem, booking, storageSite.additionalServices, appContext))
    };

    const handleFormSubmit = values => {
        onOk(values.bookingItems
            .map(formBookingItem => createBookingItem(formBookingItem, storageSite.additionalServices))
            .map(bookingItem => ({
                id: bookingItem.id,
                data: bookingItem.data
            }))
        );
    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onCancel}>
            <Form
                initialValues={initialValues}
                mutators={arrayMutators}
                onSubmit={handleFormSubmit}
            >
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogTitle disableTypography>
                            <Typography variant="h5">
                                {strings.manageParakeyAdditionalUsers}
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <FieldArray name="bookingItems">
                                {({ fields }) => (
                                    fields.map((name, index) => {
                                        const formBookingItem = fields.value[index];
                                        return (
                                            <Box key={name}>
                                                <TextField
                                                    name={`${name}.data`}
                                                    variant="outlined"
                                                    {...formBookingItem.configuration.dataTextboxProps}
                                                    helperText={undefined}
                                                />
                                            </Box>
                                        );
                                    })
                                )}
                            </FieldArray>
                            <MarginBox top={2}>
                                <Typography variant="body1">
                                    <Text html={strings.formatString(strings.contactSupportToAddParakeyUsers, routes.questionsAndAnswers)} />
                                </Typography>
                            </MarginBox>
                        </DialogContent>
                        <DialogActions>
                            {
                                parakeyAdditionalUserBookingItems.length > 0 &&
                                (
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        {strings.save}
                                    </Button>
                                )
                            }
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={onCancel}
                            >
                                {strings.cancel}
                            </Button>
                        </DialogActions>
                    </form>
                )}
            </Form>
        </Dialog>
    );
};

ParakeyAdditionalUsersDialog.propTypes = {
    booking: PropTypes.object.isRequired,
    storageSite: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default ParakeyAdditionalUsersDialog;
