import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Button from '@material-ui/core/Button';
import IconPrefix from 'common/IconPrefix';
import GroupIcon from '@material-ui/icons/Group';

const ParakeyAdditionalUsersButton = ({ onClick }) => {
    const isMobile = !useMediaQuery(theme => theme.breakpoints.up('md'));
    return (
        <Button
            type="button"
            color="primary"
            variant="contained"
            onClick={onClick}
            fullWidth={isMobile}
        >
            <IconPrefix icon={<GroupIcon/>} singleLineHeight>
                {strings.manageParakeyAdditionalUsers}
            </IconPrefix>
        </Button>
    );
};

ParakeyAdditionalUsersButton.propTypes = {
    onClick: PropTypes.func.isRequired
};

export default ParakeyAdditionalUsersButton;
