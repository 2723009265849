import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'styles/util';
import { useForm, useField } from 'react-final-form';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles(({ theme }) => ({
    quantitySelectorContainer: {
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center'
    },
}));


const QuantitySelector = ({ name, onChange }) => {
    const classes = useStyles();
    const form = useForm();
    const value = useField(name).input.value;

    const handleChange = delta => {
        form.change(name, value + delta);
        onChange(value + delta, value);
    };

    return (
        <Box className={classes.quantitySelectorContainer}>
            <IconButton
                onClick={() => handleChange(-1)}
                disabled={value === 0}
            >
                <RemoveIcon/>
            </IconButton>
            <Box>
                {value}
            </Box>
            <IconButton
                onClick={() => handleChange(1)}
            >
                <AddIcon/>
            </IconButton>
        </Box>
    );
};

QuantitySelector.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default QuantitySelector;
