import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'styles/util';
import { handleResponse } from 'actions/actionHelpers';
import { createRoundReportNote, updateRoundReportNote, deleteRoundReportNote } from 'actions/roundReports';

import EditRoundReportNoteDialog from './EditRoundReportNoteDialog';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import Lightbox from 'react-image-lightbox';

import 'react-image-lightbox/style.css';

const useStyles = makeStyles(({ theme }) => ({
    container: {
        display: 'flex',
        gap: theme.spacing(2)
    },
    content: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2)
    },
    actions: {
        flex: '0 0 auto',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        alignItems: 'center'
    },
    button: {
        padding: theme.spacing(1),
        minWidth: 'auto'
    }
}));

const RoundReportNote = ({ roundReport, roundReportNote, onChange, onRemove }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [dialogOpen, setDialogOpen] = useState(roundReportNote.new ?? false);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [updatedRoundReportNote, setUpdatedRoundReportNote] = useState({...roundReportNote});
    const [isSaving, setIsSaving] = useState(false);

    const handleEditButtonClick = () => {
        setDialogOpen(true);
    };

    const handleRemoveButtonClick = () => {
        setIsSaving(true);
        dispatch(deleteRoundReportNote(roundReport.id, roundReport.token, updatedRoundReportNote.id))
            .then(handleResponse(
                () => {
                    setIsSaving(false);
                    onRemove();
                },
                () => {
                    setIsSaving(false);
                }
            ));
    };

    const handleDialogOk = values => {
        setDialogOpen(false);
        setUpdatedRoundReportNote(values);
        setIsSaving(true);
        let action;
        if(updatedRoundReportNote.id) {
            action = updateRoundReportNote(roundReport.id, roundReport.token, updatedRoundReportNote.id, values);
        } else {
            action = createRoundReportNote(roundReport.id, roundReport.token, values);
        }
        dispatch(action)
            .then(handleResponse(
                response => {
                    setIsSaving(false);
                    setUpdatedRoundReportNote(response.payload);
                    onChange(response.payload);
                },
                () => {
                    setIsSaving(false);
                }
            ));
    };

    const handleDialogCancel = () => {
        setDialogOpen(false);
    };

    const url = updatedRoundReportNote.imageData ?? updatedRoundReportNote.imageUrl;

    const editRoundReportNoteDialog = (
        <EditRoundReportNoteDialog
            open={dialogOpen}
            roundReportNote={roundReportNote}
            onOk={handleDialogOk}
            onCancel={handleDialogCancel}
        />
    );


    if(updatedRoundReportNote.new) {
        return editRoundReportNoteDialog;
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.content}>
                {
                    url &&
                    (
                        <>
                            <img
                                className={classes.image}
                                src={url}
                                onClick={() => setLightboxOpen(true)}
                            />
                            {
                                lightboxOpen &&
                                (
                                    <Lightbox
                                        mainSrc={url}
                                        onCloseRequest={() => setLightboxOpen(false)}
                                    />
                                )
                            }
                        </>
                    )
                }
                {
                    updatedRoundReportNote.text &&
                    (
                        <Box className={classes.text}>
                            {updatedRoundReportNote.text}
                        </Box>
                    )
                }
            </Box>
            {
                !roundReport.completedTime &&
                (
                    <>
                        <Box className={classes.actions}>
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                disabled={isSaving}
                                onClick={handleEditButtonClick}
                            >
                                <EditIcon/>
                            </Button>
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                disabled={isSaving}
                                onClick={handleRemoveButtonClick}
                            >
                                <DeleteOutlineOutlinedIcon/>
                            </Button>
                            {
                                isSaving &&
                                (
                                    <CircularProgress
                                        size={16}
                                        color="primary"
                                        className={classes.circularProgress}
                                    />
                                )
                            }
                        </Box>
                        {editRoundReportNoteDialog}
                    </>
                )
            }
        </Box>
    );
};

RoundReportNote.propTypes = {
    roundReport: PropTypes.object.isRequired,
    roundReportNote: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired
};

export default RoundReportNote;
