import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTenantBookings } from 'actions/account/tenantBookingsSection';
import strings from 'localization/strings';
import queryString from 'query-string';
import { getTabs, isStale, parseFilterParams } from './BookingsSectionHelper';
import routes from 'routes';
import { handleResponse } from 'actions/actionHelpers';

import BookingsSection from './BookingsSection';
import TenantBookingListItem from './TenantBookingListItem';

const TenantBookingsSection = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const filterParams = parseFilterParams(location);
    const filterQueryString = queryString.stringify(filterParams);
    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor } = authenticationContext;

    const [bookingsResult, setBookingsResult] = useState({});
    const [bookingsFetchedTime, setBookingsFetchedTime] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(undefined);

    useEffect(() => {
        history.push(`${routes.account.tenantBookings}?${filterQueryString}`);
        fetchBookings(true);
    }, [filterQueryString, selectedActor.id]);

    useEffect(() => {
        if(isStale(bookingsFetchedTime)) {
            fetchBookings(false);
        }
    }, [isStale(bookingsFetchedTime)]);

    // reload every other minute, but without showing the loading indicator
    useEffect(() => {
        const timer = window.setInterval(() => fetchBookings(false), 120000);
        return () => window.clearInterval(timer);
    }, []);

    useEffect(() => {
        // if there is just one booking, redirect to that details page directly
        if(bookingsResult.count === 1) {
            history.push(routes.account.tenantBookingDetails.replace(':bookingId', bookingsResult.items[0].id));
        }
    }, [bookingsResult.count]);

    const fetchBookings = isLoadingValue => {
        if(isLoadingValue) {
            setBookingsResult({});
        }
        setIsLoading(isLoadingValue);
        setLoadingError(undefined);
        const request = {
            tenantActorId: selectedActor.id,
            adminBookingStatus: filterParams.adminBookingStatus,
            page: filterParams.page - 1,
            pageSize: filterParams.pageSize
        };
        dispatch(fetchTenantBookings(request))
            .then(handleResponse(
                response => {
                    setBookingsResult(response.payload);
                    const now = new Date().getTime();
                    setBookingsFetchedTime(now);
                    setIsLoading(false);
                },
                response => {
                    setLoadingError(response);
                    setIsLoading(false);
                }
            ));
    };

    const handleFilterChange = changedFilterParams => {
        const newFilterParams = { ...filterParams, ...changedFilterParams };
        const newFilterQueryString = queryString.stringify(newFilterParams);
        history.push(`${routes.account.tenantBookings}?${newFilterQueryString}`);
    };

    const title = selectedActor.tenantBookingCount > 0 && selectedActor.isOwner
        ? strings.accountTabs.tenantBookings
        : strings.accountTabs.bookings;

    const tabs = getTabs();

    return (
        <BookingsSection
            bookingsResult={bookingsResult}
            isLoading={isLoading}
            loadingError={!!loadingError}
            tabs={tabs}
            filterParams={filterParams}
            onFilterChange={handleFilterChange}
            title={title}
            renderBooking={booking => <TenantBookingListItem booking={booking}/>}
        />
    );
};

export default TenantBookingsSection;
