import React from 'react';
import PropTypes from 'prop-types';
import strings from 'localization/strings';
import { useAppContext } from 'context/AppContext';
import { makeStyles } from 'styles/util';
import { calculateSum } from 'helpers/ArrayHelper';
import { calculateVat } from 'helpers/MonetaryHelper';

import Box from '@material-ui/core/Box';
import Amount from 'common/Amount';

const useStyles = makeStyles(() => ({
    footer: {
        width: '100%',
        '& td': {
            fontSize: '12px',
            verticalAlign: 'top',
        }
    },
    itemTitle: {
        fontWeight: 'bold'
    },
    noMargin: {
        margin: 0
    },
    fieldTotalValue: {
        fontSize: '16px'
    },
    right: {
        textAlign: 'right'
    }
}));

const OwnerFooter = ({ purchase }) => {
    const classes = useStyles();
    const { appContext } = useAppContext();

    const fullCommissionPurchaseItems = purchase.purchaseItems.filter(o => o.commissionRate === 1);
    const fullCommissionPurchaseItemsFeeVat = calculateSum(fullCommissionPurchaseItems, o => calculateVat(o.amount, o.vatRate, appContext));
    const fullCommissionPurchaseItemsFeeAmountIncludingVat = calculateSum(fullCommissionPurchaseItems, o => o.amount) + fullCommissionPurchaseItemsFeeVat;

    return (
        <table className={classes.footer}>
            <tbody>
                <tr>
                    <td>
                        <Box className={classes.itemTitle + ' ' + classes.noMargin}>
                            {strings.paymentDocuments.specificationSum}
                        </Box>
                        <Box className={classes.noMargin}>
                            <Amount value={purchase.amount - fullCommissionPurchaseItemsFeeAmountIncludingVat} currency={purchase.currency} displayPriceRounding={false} /><br />&nbsp;
                        </Box>
                    </td>
                    <td>
                        <Box className={classes.itemTitle + ' ' + classes.noMargin}>
                            {strings.paymentDocuments.commissionFee}
                        </Box>
                        <Box className={classes.noMargin}>
                            <Amount value={purchase.commissionFeeIncludingVatAndRoundingCorrection - fullCommissionPurchaseItemsFeeAmountIncludingVat} currency={purchase.currency} displayPriceRounding={false} />
                            <br />
                            ({strings.paymentDocuments.ofWhichVat} <Amount value={purchase.commissionFeeVat - fullCommissionPurchaseItemsFeeVat} currency={purchase.currency} displayPriceRounding={false} />)
                            <br />
                            {strings.paymentDocuments.vatIdentificationNumber}: {appContext.company.vatIdentificationNumber}
                        </Box>
                    </td>
                    <td className={classes.right}>
                        <Box className={classes.itemTitle + ' ' + classes.fieldTotalValue + ' ' + classes.noMargin}>
                            {strings.paymentDocuments.totalPayout}
                        </Box>
                        <Box className={classes.fieldTotalValue + ' ' + classes.noMargin}>
                            <Amount value={purchase.amount - purchase.commissionFeeIncludingVatAndRoundingCorrection} currency={purchase.currency} displayPriceRounding={false} />
                        </Box>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

OwnerFooter.propTypes = {
    purchase: PropTypes.object.isRequired
};

export default OwnerFooter;
