import strings from 'localization/strings';
import { email } from 'form/validation';
import additionalServiceTypes from 'enums/additionalServiceTypes';
import { showErrorOnBlur } from 'mui-rff';

export const getAdditionalServiceConfiguration = additionalService => {
    if(!additionalService) {
        return undefined;
    }
    if(additionalService?.type === additionalServiceTypes.parakeyAdditionalUser.key) {
        return {
            showDataTextbox: true,
            dataTextboxProps: {
                label: strings.email,
                helperText: strings.parakeyAdditionalUserEmailHelperText,
                required: true,
                fieldProps: { validate: email },
                showError: showErrorOnBlur
            },
            textToObject: text => ({ email: text }),
            objectToText: object => object?.email,
            allowMultiple: true
        };
    }
    return {
        showDataTextbox: false,
        allowMultiple: false
    };
};

export const createBookingItemDataObject = (additionalService, values) => {
    const configuration = getAdditionalServiceConfiguration(additionalService);
    return configuration?.textToObject
        ? configuration.textToObject(values.data)
        : undefined;
};

export const createBookingItemDataText = (additionalService, bookingItem) => {
    const configuration = getAdditionalServiceConfiguration(additionalService);
    return configuration?.objectToText
        ? configuration.objectToText(bookingItem.data)
        : undefined;
};

export const getAdditionalServiceById = (additionalServices, id) => additionalServices?.find(o => o.id === id);
