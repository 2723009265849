import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { required } from 'form/validation';
import strings from 'localization/strings';
import { convertToNumber } from 'helpers/StringHelper';
import { getBusinessOrPrivateName } from 'helpers/ActorHelper';

import Box from '@material-ui/core/Box';
import Form from 'form/Form';
import Button from '@material-ui/core/Button';
import { Radios, Checkboxes, TextField, showErrorOnBlur } from 'mui-rff';
import Typography from '@material-ui/core/Typography';
import AdminFormWrapper from 'admin/presentational/AdminFormWrapper';
import ReadOnlyField from 'admin/presentational/ReadOnlyField';
import AdminFormButtons from 'admin/presentational/AdminFormButtons';
import AdminFormFields from 'admin/presentational/AdminFormFields';
import MessageList from './MessageList';
import PredefinedTextSelector, { insertPredefinedText } from 'common/PredefinedTextSelector';

const SendMessageForm = ({ conversation, predefinedTexts, isSubmitting = false, onSubmit }) => {
    const messageTextContainerRef = useRef(null);

    const initialValues = {
        senderActorId: conversation.lastSenderIsOwner
            ? '' + conversation.tenantActor.id
            : '' + conversation.ownerActor.id,
        writeAsAdmin: false,
        messageText: predefinedTexts.find(o => o.key === 'DefaultFooter')?.text ?? ''
    };

    // must use strings as values
    const senderItems = [
        { label: strings.formatString('Send message as tenant ({0})', getBusinessOrPrivateName(conversation.tenantActor)), value: '' + conversation.tenantActor.id },
        { label: strings.formatString('Send message as owner ({0})', getBusinessOrPrivateName(conversation.ownerActor)), value: '' + conversation.ownerActor.id }
    ];

    const getRecipientNamesString = formValues => {
        const recipientActors = [];
        if(conversation) {
            if(formValues.writeAsAdmin) {
                recipientActors.push(conversation.tenantActor);
                recipientActors.push(conversation.ownerActor);
            } else {
                recipientActors.push(formValues.senderActorId === conversation.ownerActor.id
                    ? conversation.tenantActor
                    : conversation.ownerActor
                );
            }
        }
        return recipientActors.map(o => o.name).join(' and ');
    };

    const handleFormSubmit = (formValues, form) => {
        onSubmit(
            {
                ...formValues,
                senderActorId: convertToNumber(formValues.senderActorId)
            },
            form
        );
    };

    const handlePredefinedTextSelect = (predefinedText, form) => {
        insertPredefinedText({
            containerRef: messageTextContainerRef,
            fieldName: 'messageText',
            form,
            predefinedText
        });
    };

    return (
        <AdminFormWrapper isSubmitting={isSubmitting}>
            <Form
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
            >
                {({ form, handleSubmit, values, invalid }) => (
                    <form onSubmit={handleSubmit}>
                        <AdminFormFields>

                            <ReadOnlyField label="">
                                <Typography variant="h5">
                                    Send message
                                </Typography>
                            </ReadOnlyField>

                            <Radios
                                name="senderActorId"
                                data={senderItems}
                            />

                            <Checkboxes
                                name="writeAsAdmin"
                                data={
                                    { label: 'Send message as admin', value: true }
                                }
                                helperText="Check the box to send the message with yourself as the sender to both the tenant and the owner."
                            />

                            <Box ref={messageTextContainerRef}>
                                <TextField
                                    name="messageText"
                                    label={strings.formatString('New message to {0}', getRecipientNamesString(values))}
                                    variant="outlined"
                                    showError={showErrorOnBlur}
                                    required
                                    fieldProps={{ validate: required }}
                                    multiline
                                    rows={4}
                                />
                            </Box>

                            <PredefinedTextSelector
                                predefinedTexts={predefinedTexts}
                                label="Insert predefined text"
                                onSelect={predefinedText => handlePredefinedTextSelect(predefinedText, form)}
                            />
                        </AdminFormFields>
                        <AdminFormButtons>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={invalid}
                            >
                                Send
                            </Button>
                        </AdminFormButtons>
                        <AdminFormFields>
                            <ReadOnlyField label="Messages">
                                <MessageList conversation={conversation} />
                            </ReadOnlyField>
                        </AdminFormFields>
                    </form>
                )}
            </Form>
        </AdminFormWrapper>
    );
};

SendMessageForm.propTypes = {
    conversation: PropTypes.object.isRequired,
    predefinedTexts: PropTypes.array.isRequired,
    isSubmitting: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
};

export default SendMessageForm;
