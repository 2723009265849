import React from 'react';
import routes from 'routes';

import { Switch, Route } from 'react-router-dom';
import LegacyRouteRedirects from 'components/app/LegacyRouteRedirects';
import App from 'components/app/App';
import AdminApp from 'admin/containers/adminApp/AdminApp';
import BookingLockInformationPage from 'pages/bookingLockCode/BookingLockInformationPage';
import BookingUnsubscribeFromMarketingPage from 'pages/bookingUnsubscribeFromMarketing/BookingUnsubscribeFromMarketingPage';
import TenantReceiptPage from 'pages/tenantReceipt/TenantReceiptPage';
import OwnerReceiptPage from 'pages/ownerReceipt/OwnerReceiptPage';
import AgreementCoverPage from 'pages/agreementCover/AgreementCoverPage';
import PostInvoiceOwnerPage from 'pages/postInvoice/PostInvoiceOwnerPage';
import PostInvoiceTenantPage from 'pages/postInvoice/PostInvoiceTenantPage';
import DefaultAgreementPage from 'pages/defaultAgreement/DefaultAgreementPage';
import BillectaInvoiceInformationPage from 'pages/billecta/BillectaInvoiceInformationPage';
import ReportPage from 'pages/report/ReportPage';
import EidentLoginCallbackPage from 'components/eid/EidentLoginCallbackPage';
import RoundReportPage from 'pages/roundReport/RoundReportPage';

// this is the root for all routes in the application
const Routes = () => (
    <>
        <LegacyRouteRedirects />
        <Switch>
            { /* free-standing pages */ }
            <Route path={routes.bookingLockInformation} component={BookingLockInformationPage} />
            <Route path={routes.bookingUnsubscribeFromMarketing} component={BookingUnsubscribeFromMarketingPage} />
            <Route path={routes.tenantReceipt} component={TenantReceiptPage} />
            <Route path={routes.ownerReceipt} component={OwnerReceiptPage} />
            <Route path={routes.agreementCoverPage} component={AgreementCoverPage} />
            <Route path={routes.postInvoicePreview} component={PostInvoiceOwnerPage} />
            <Route path={routes.postInvoiceOwner} component={PostInvoiceOwnerPage} />
            <Route path={routes.postInvoiceTenant} component={PostInvoiceTenantPage} />
            <Route path={routes.defaultAgreement} component={DefaultAgreementPage} />
            <Route path={routes.billectaInvoiceInformationPage} component={BillectaInvoiceInformationPage} />
            <Route path={routes.report} component={ReportPage} />
            <Route path={routes.eidentLogInCallback} component={EidentLoginCallbackPage} />
            <Route path={routes.roundReport} component={RoundReportPage} />

            { /* the backoffice section */ }
            <Route path={routes.admin.index} component={AdminApp} />

            { /* the public section */ }
            <Route path={routes.index} component={App} />
        </Switch>
    </>
);

export default Routes;
