import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { handleResponse } from 'actions/actionHelpers';
import { fetchTenantMessagesForOwner } from 'actions/account/ownerBookingsSection';
import strings from 'localization/strings';
import { makeStyles } from 'styles/util';
import { defaultCompare } from 'helpers/SortHelper';
import messageTypes from 'enums/messageTypes';

import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Text from 'common/Text';
import Typography from '@material-ui/core/Typography';
import ShortDateTime from 'common/ShortDateTime';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loader from 'common/Loader';

const useStyles = makeStyles(({ theme, colors }) => ({
    tenantMessage: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        marginBottom: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        borderBottom: `1px solid ${colors.darkGrey}`,
        '&:last-child': {
            marginBottom: 0,
            paddingBottom: 0,
            borderBottom: 'none'
        }
    }
}));

const TenantMessageLogDialog = ({ open, onClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [tenantMessages, setTenantMessages] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const authenticationContext = useSelector(state => state.authentication.context);
    const { selectedActor } = authenticationContext;

    useEffect(() => {
        if(open) {
            setIsLoading(true);
            dispatch(fetchTenantMessagesForOwner(selectedActor.id))
                .then(handleResponse(
                    response => {
                        setTenantMessages(response.payload);
                        setIsLoading(false);
                    },
                    () => setIsLoading(false)
                ));
        }
    }, [open]);

    const sortDescending = messages => {
        const sortedMessages = [...messages];
        sortedMessages.sort((a, b) => -defaultCompare(a.createdTime, b.createdTime));
        return sortedMessages;
    };

    return (
        <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
            <DialogTitle disableTypography>
                <Typography variant="h5">
                    {strings.tenantMessageLogDialogTitle}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {
                    isLoading && <Loader/>
                }
                {
                    !isLoading && tenantMessages &&
                    (
                        <>
                            {
                                tenantMessages.length === 0 &&
                                (
                                    <Typography variant="body1">
                                        {strings.noMessagesSent}
                                    </Typography>
                                )
                            }
                            {
                                sortDescending(tenantMessages).map((message, i) => (
                                    <Box key={i} className={classes.tenantMessage}>
                                        <Box>
                                            <Typography variant="h5" gutterBottom>
                                                {strings.timestamp}
                                            </Typography>
                                            <Typography variant="body1">
                                                <ShortDateTime value={message.createdTime}/>
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography variant="h5" gutterBottom>
                                                {strings.messageType}
                                            </Typography>
                                            <Typography variant="body1">
                                                {messageTypes[message.type].title}
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography variant="h5" gutterBottom>
                                                {strings.recipientCount}
                                            </Typography>
                                            <Typography variant="body1">
                                                {message.recipientCount}
                                            </Typography>
                                        </Box>

                                        {
                                            message.type !== messageTypes.sms.key &&
                                            (
                                                <Box>
                                                    <Typography variant="h5" gutterBottom>
                                                        {strings.messageSubject}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        {message.subject}
                                                    </Typography>
                                                </Box>
                                            )
                                        }

                                        <Box>
                                            <Typography variant="h5" gutterBottom>
                                                {strings.messageBody}
                                            </Typography>
                                            <Text text={message.body}/>
                                        </Box>
                                    </Box>
                                ))
                            }
                        </>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClose}
                >
                    {strings.close}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

TenantMessageLogDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};

export default TenantMessageLogDialog;
