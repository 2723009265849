import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from 'context/AppContext';
import { roundAmount } from 'helpers/MonetaryHelper';

import Typography from '@material-ui/core/Typography';
import MarginBox from 'common/MarginBox';
import Amount from 'common/Amount';

const PeriodBookingPriceChangeInfo = ({ booking, previousPrice, newPrice }) => {
    const { appContext } = useAppContext();
    const priceDifference = roundAmount(newPrice - previousPrice, appContext, true /* useDisplayPriceRounding */);
    const priceUnchanged = priceDifference === 0;
    const priceIncreased = priceDifference > 0;
    if(priceUnchanged) {
        return null;
    }

    return (
        <MarginBox top={2}>
            <Typography variant="body1">
                {priceIncreased ? 'Additional amount to pay' : 'Amount to refund'}<> (including VAT): </>
                <Amount value={Math.abs(priceDifference)} currency={booking.currency}/>
            </Typography>
        </MarginBox>
    );
};

PeriodBookingPriceChangeInfo.propTypes = {
    booking: PropTypes.object.isRequired,
    previousPrice: PropTypes.number.isRequired,
    newPrice: PropTypes.number.isRequired
};

export default PeriodBookingPriceChangeInfo;
