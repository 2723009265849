import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { handleResponse } from 'actions/actionHelpers';
import { getTakeover } from 'actions/admin/takeovers';
import routes from 'routes';
import takeoverTenantStatuses from 'enums/takeoverTenantStatuses';

import Loader from 'common/Loader';
import AdminPage from '../../presentational/AdminPage';
import AdminContentWrapper from '../../presentational/AdminContentWrapper';
import AdminFormWrapper from '../../presentational/AdminFormWrapper';
import AdminFormFields from '../../presentational/AdminFormFields';
import ReadOnlyField from '../../presentational/ReadOnlyField';
import AdminActionWrapper from '../../presentational/AdminActionWrapper';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ShortDate from 'common/ShortDate';
import Percentage from 'common/Percentage';

const Takeover = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const [takeover, setTakeover] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch(getTakeover(params.takeoverId))
        .then(handleResponse(
            response => {
                setTakeover(response.payload);
                setIsLoading(false);
            },
            () => setIsLoading(false)
        ));
    }, []);

    const title = 'Takeover: ' + (takeover ? takeover.storageSite.title : 'Loading...');

    const tenantStatusItems = {};
    Object.keys(takeoverTenantStatuses).filter(o => o !== takeoverTenantStatuses.unsaved.key).forEach(status => {
        tenantStatusItems[status] = { status, count: 0 };
    });

    if(takeover) {
        takeover.tenants.forEach(tenant => {
            tenantStatusItems[tenant.status].count++;
        });
    }

    // accumulate all counts except for canceled
    let last = 0;
    Array.prototype.reverse.apply(Object.values(tenantStatusItems)).forEach(statusItem => {
        if(statusItem.status !== takeoverTenantStatuses.canceled.key) {
            statusItem.count += last;
            last = statusItem.count;
        }
    });

    tenantStatusItems.paid = {
        status: 'paid',
        count: takeover?.tenants.filter(o => o.hasPaid).length
    };

    return (
        <AdminPage title={title}>
            {
                isLoading && <Loader />
            }
            {
                takeover &&
                (
                    <AdminContentWrapper type="detail">
                        <AdminFormWrapper>
                            <AdminFormFields>
                                <ReadOnlyField label="Takeover ID">
                                    {takeover.id}
                                </ReadOnlyField>

                                <ReadOnlyField label="Storage site">
                                    {takeover.storageSite.title}
                                </ReadOnlyField>

                                <ReadOnlyField label="Owner actor">
                                    <Link to={routes.admin.actor.replace(':actorId', takeover.ownerActorId)}>{takeover.ownerActorName}</Link>
                                </ReadOnlyField>

                                <ReadOnlyField label="Start date">
                                    <ShortDate value={takeover.startDate}/>
                                </ReadOnlyField>

                                {
                                    takeover.commissionRate !== undefined &&
                                    (
                                        <ReadOnlyField label="Commission rate">
                                            <Percentage value={takeover.commissionRate} />
                                        </ReadOnlyField>
                                    )
                                }

                                <ReadOnlyField label="Status">
                                    {takeover.status}
                                </ReadOnlyField>
                                {
                                    takeover.tenants.length === 0 &&
                                    (
                                        <ReadOnlyField label="Tenants">
                                            No tenants imported.
                                        </ReadOnlyField>
                                    )
                                }
                                {
                                    takeover.tenants.length > 0 &&
                                    (
                                        <>
                                            <ReadOnlyField label="Number of tenants">
                                                {takeover.tenants.length}
                                            </ReadOnlyField>
                                            <ReadOnlyField label="Tenant statuses">
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Status</TableCell>
                                                            <TableCell align="right">Number of tenants</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            Object.values(tenantStatusItems).map(item => (
                                                                <TableRow key={item.status}>
                                                                    <TableCell>{item.status}</TableCell>
                                                                    <TableCell align="right">{item.count}</TableCell>
                                                                </TableRow>
                                                            ))
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </ReadOnlyField>
                                        </>
                                    )
                                }
                            </AdminFormFields>
                        </AdminFormWrapper>

                        <AdminActionWrapper>
                            <Button
                                variant="contained"
                                color="primary"
                                component={Link}
                                to={routes.admin.editTakeover.replace(':takeoverId', takeover.id)}
                            >
                                Edit basic details
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                component={Link}
                                to={routes.admin.importTakeoverTenants.replace(':takeoverId', takeover.id)}
                            >
                                Import tenants
                            </Button>

                            {
                                takeover.tenants.length > 0 &&
                                (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            component={Link}
                                            to={routes.admin.takeoverTenants.replace(':takeoverId', takeover.id)}
                                        >
                                            Show tenants
                                        </Button>

                                        {
                                            takeover.tenants.some(o => o.status === takeoverTenantStatuses.draft.key) &&
                                            (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    component={Link}
                                                    to={routes.admin.createBookingsForTakeoverTenants.replace(':takeoverId', takeover.id)}
                                                >
                                                    Create bookings
                                                </Button>
                                            )
                                        }

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            component={Link}
                                            to={routes.admin.sendEmailToTakeoverTenants.replace(':takeoverId', takeover.id)}
                                        >
                                            Send email to tenants
                                        </Button>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            component={Link}
                                            to={routes.admin.takeoverMessages.replace(':takeoverId', takeover.id)}
                                        >
                                            Show sent emails
                                        </Button>
                                    </>
                                )
                            }
                        </AdminActionWrapper>
                    </AdminContentWrapper>
                )
            }
        </AdminPage>
    );
};

export default Takeover;
